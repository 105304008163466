import React, { useState } from 'react';
import { Logo, NavLink, useNavigate } from "../../imports/generalImport";
import { Box, Container, IconButton, Typography } from "../../imports/muiImport";
import MobileLoginForm from '../mobileComponents/mobileUserAccountLogin/MobileLoginForm';
import MobileOTPForm from '../mobileComponents/mobileUserAccountLogin/MobileOTPForm';
import { useAuth } from '../../hooks/contexts/AuthContext';
import { userTokenSave } from '../../apiCalls/functions';
import { actionNotifier } from '../../components/tostify';
import MobileSignUpForm from '../mobileComponents/mobileUserAccountLogin/MobileSignUpForm';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Helmet } from 'react-helmet-async';

function MobileSignup() {
    const [showOTPForm, setOTPForm] = useState(false);
    const [isResendAvailable, setIsResendAvailable] = useState(false);
    const [userCredentials, setUserCredentials] = useState({
        mobile: null,
        token: null,
        status: false,
        isLoginForm: true
    });
    const navigate = useNavigate();
    const {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    } = useAuth();

    const handleBack = () => {
        navigate('/', { replace: true });
    };

    return (
        <Container sx={{
            bgcolor: "#e6f9ff",
            minHeight: "100vh",
        }}>

            <Helmet>
                <title>Signup -99gift.in</title>
                <meta property="og:title" content="Signup -99gift.in" />
            </Helmet>

            <Box sx={{
                py: 2,
                display: "flex",

            }}>
                <IconButton
                    sx={{
                        backgroundColor: "white.main",
                        p: 1,
                        borderRadius: 2,
                        width: 40,
                        height: 40,
                    }}
                    onClick={handleBack}
                >
                    <ArrowBackIosIcon
                        fontSize="large" />
                </IconButton>
            </Box>


            {showOTPForm ?
                (
                    <Box sx={{
                        pt: 4,
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        {userCredentials?.status &&
                            (
                                <MobileOTPForm
                                    userCredentials={userCredentials}
                                    userLoginStatusSetup={userLoginStatusSetup}
                                    isResendAvailable={isResendAvailable}
                                    setIsResendAvailable={setIsResendAvailable}
                                />
                            )
                        }
                    </Box>
                )
                :
                (
                    <Box sx={{}}>
                        <Box sx={{
                            pt: 0,
                            display: "flex",
                            justifyContent: "center",
                        }}>
                            <NavLink to={'/'}>
                                <Box
                                    component={'img'}
                                    src={Logo}
                                    width={170}
                                    sx={{
                                        mixBlendMode: "multiply",
                                    }}
                                />
                            </NavLink>
                        </Box>

                        <Box sx={{
                            pt: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            <Typography variant='subtitle1'
                                sx={{
                                    color: "primaryDark.main",
                                    fontWeight: 400,
                                    mb: 1,
                                    fontSize: 15.5,
                                }}
                            >
                                Create your Account
                            </Typography>

                            <MobileSignUpForm setOTPForm={setOTPForm} setUserCredentials={setUserCredentials} />
                        </Box>

                        <Box sx={{
                            py: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            <Typography variant='subtitle1'
                                component={'div'}
                                sx={{
                                    color: "textPrimary.main",
                                    fontWeight: 400,
                                    mb: 1,
                                }}
                            >
                                Already have an account?
                                <NavLink to={'/login'} style={{ textDecoration: "none", marginLeft: 10 }}>
                                    <Typography variant='subtitle1'
                                        component={'span'}
                                        sx={{
                                            color: "secondary.main",
                                            fontWeight: 500,
                                            mb: 1,
                                        }}
                                    >
                                        Sign In
                                    </Typography>
                                </NavLink>
                            </Typography>
                        </Box>
                    </Box>
                )
            }
        </Container>
    );

    function userLoginStatusSetup(token) {
        let loginToken = userTokenSave(token);
        // Set true User Login Status it will reflect every where
        setIsLoggedIn(true);

        if (loginToken) {
            // Notification remove 
            actionNotifier.clearAll();
            navigate("/", { replace: true });
        }
    }
}

export default React.memo(MobileSignup)