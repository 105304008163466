import React from "react";
import { Container, Box } from "@mui/material";
import giftCardWork from "../../assets/vactor-images/giftcardwork.svg";

const GiftCardWorking = () => {
  return (
    <Container
      maxWidth="false"
      disableGutters={true}
      sx={{
        mb: 2,
        backgroundColor: "primary.main",
        border: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box maxWidth="lg"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

        }}
      >
        <Box
          component="img"
          src={giftCardWork}
          alt="How Gift Card Works image"
          loading="lazy"
          sx={{
            width: "100%",
            height: {
              xs: "auto",
            },
            borderRadius: {
              xs: 2,
              lg: 3,
            },
          }}
        />
      </Box>
    </Container>
  );
};

export default GiftCardWorking;
