import React from 'react'
import MobileCategoryDrawer from './MobileCategoryDrawer';
import { defaultProductImage, NavLink, useSelector } from "../../../imports/generalImport";
import { Box, Button, Stack, Typography } from "../../../imports/muiImport";
import { categoryUrl } from '../../../shared/extra';
import MobileLoading from '../mobileLoading/MobileLoading';

export default function MobileHomeCategory() {
    const [open, setOpen] = React.useState(false);
    const categoryData = useSelector((state) => state.Category.allData)

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <>
            <MobileCategoryDrawer open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />
            <Box sx={{
                p: 2,
            }}>
                <Box sx={{
                    border: 1,
                    minWidth: "100%",
                    minHeight: 300,
                    maxHeight: 300,
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    overflow: "hidden",
                    backgroundColor: "#fefafe",
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "100%",
                        minHeight: 270,
                        maxHeight: 300,
                        pt: 1
                    }}>
                        <Typography variant='subtitle1' sx={{
                            width: "100%",
                            textAlign: "center",
                            color: "primary.main",
                            fontWeight: 500,
                            fontSize: "16.5px"
                        }}>
                            Categories
                        </Typography>

                        {!categoryData?.length ?
                            (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        minHeight: 240
                                    }}>
                                    <MobileLoading />
                                </Box>
                            )
                            :
                            (
                                <Box sx={{
                                    minHeight: 240,
                                    maxHeight: 240,
                                    display: "grid",
                                    gridTemplateRows: "repeat(2,1fr)",
                                    gridTemplateColumns: "repeat(3,1fr)",
                                    rowGap: 1,
                                    columnGap: 2,
                                    overflowY: 'auto',
                                }}>



                                    {categoryData?.map((item) => {
                                        if (item?.products?.length > 0) {
                                            return (
                                                <NavLink
                                                    to={`card-list?category=${categoryUrl(item?.title)}`}
                                                    key={`category-${item?.id * 1}`}
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}>
                                                        <Box
                                                            component="img"
                                                            src={item?.image || defaultProductImage}
                                                            width={64}
                                                            height={64}
                                                            sx={{
                                                                borderRadius: "50%",
                                                                objectFit: "cover",
                                                                p: "4px",
                                                                border: "1px solid #deebff",
                                                            }}
                                                        />

                                                        <Typography variant='subtitle1' sx={{
                                                            width: "100%",
                                                            textAlign: "center",
                                                            color: "primary.main",
                                                            fontWeight: 400,
                                                            fontSize: "11.5px",
                                                            textDecoration: "none",
                                                        }}>
                                                            {item?.title}
                                                        </Typography>
                                                    </Box>
                                                </NavLink>
                                            );
                                        }
                                        else {
                                            return null;
                                        }
                                    })}

                                </Box>
                            )
                        }

                        {/* Category */}


                    </Box>

                    <Button variant='text'
                        onClick={toggleDrawer(true)}
                        sx={{
                            minWidth: "66vw",
                            height: 34,
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "secondary.main",
                            borderRadius: "24px 24px 0 0",
                            "&:hover": {
                                backgroundColor: "secondary.main",
                            },
                        }}>
                        <Typography variant='subtitle1' sx={{
                            color: "primary.main",
                            textTransform: "capitalize",
                            fontWeight: 500,
                            fontSize: "13.5px",
                        }}>
                            view more
                        </Typography>
                    </Button>
                </Box>

            </Box>

        </>
    )
}