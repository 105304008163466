import React from "react";
import { Breadcrumbs, Skeleton, Typography, Box, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import { titleToUrl } from "../../shared/extra";

const PageBreadcrumb = ({ home, category, other, currentProduct }) => {
    const Mobile = useMediaQuery("(max-width:600px)");

    const otherLinkProps = {
        pathname: `/${titleToUrl(other)}`
    }

    // titleToUrl(other)
    const linkProps = Mobile
        ? {
            pathname: `category/${category}`
        }
        : { pathname: '/card-list', search: `?category=${titleToUrl(category)}` };

    const normalBreadCrumbStyle = {
        color: { xs: "white.main", sm: "#938f8ff0" },
        fontSize: { xs: ".7rem", sm: "13px" },
        fontWeight: 500,
        textTransform: { xs: "capitalize", sm: "capitalize" },
    };

    const breadCrumbStyle = {
        home: normalBreadCrumbStyle,
        category: normalBreadCrumbStyle,
        currentProduct: {
            fontWeight: 400,
            fontSize: { xs: ".8rem", sm: "14px" },
            textTransform: { xs: "capitalize", sm: "capitalize" },
            color: "secondary.main",
        }
    };

    return (
        <Box
            component="div"
            sx={{
                py: { xs: "0", sm: "15px" },
                px: { xs: "10px", sm: "5px" },
                backgroundColor: { xs: "#08306f", sm: "inherit" },
                minHeight: { xs: "30px", sm: "50px" },
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
        >
            <Breadcrumbs aria-label="breadcrumb">
                {home && <BreadCrumb title={home} style={breadCrumbStyle.home} link="/" />}
                {other && <BreadCrumb title={other} style={breadCrumbStyle.category} link={otherLinkProps} />}
                {category && <BreadCrumb title={category} style={breadCrumbStyle.category} link={linkProps} />}
                {currentProduct && <BreadCrumb title={currentProduct} style={breadCrumbStyle.currentProduct} />}
            </Breadcrumbs>
        </Box>
    );

    function BreadCrumb({ title, style, link }) {
        return link ? (
            <NavLink
                to={link}
                style={{
                    textDecoration: "none",
                }}
            >
                <Typography
                    variant="subtitle2"
                    sx={style}
                >
                    {title}
                </Typography>
            </NavLink>
        ) : (
            <Typography
                variant="subtitle2"
                sx={style}
            >
                {title}
            </Typography>
        );
    }
};

export default PageBreadcrumb;
