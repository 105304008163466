import { getAllProducts } from "../../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";

export function onFetchProducts() {
  return async (dispatch) => {
    const res = await getAllProducts({
      search: "",
      filterBy: "title",
      selectedCategories: [],
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 10000,
        rowsNumber: 0,
      },
    });
    if (res?.status) {
      dispatch(updateAllProductsReducer(res?.data));
    } else {
      console.error(res?.error);
    }
  };
}

export const updateAllProductsReducer = (payload) => {
  return {
    type: actionTypes.UPDATE_ALL_PRODUCTS_REDUCER,
    payload
  };
};
