import { getTopFiveProducts } from "../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";

export function onFetchTopFiveProducts() {
  return async (dispatch) => {
    const res = await getTopFiveProducts();
    if (res?.status) {
      dispatch(updateAllProductsReducer(res?.data));
    } else {
      console.error(res?.error);
    }
  };
}

export const updateAllProductsReducer = (payload) => {
  return {
    type: actionTypes.TOP_FIVE_PRODUCTS,
    payload
  };
};
