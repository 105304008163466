import { fetchUserStatement } from '../../apiCalls/urls/urlFunction';
import * as actionTypes from './actionTypes';

const initialData = {
    search: null,
    filterBy: null,
    date: { from: '2024/04/05', to: '2024/06/26' },
    pagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0,
    },
    status: 0,
};

export function onFetchUserStatement(data = initialData) {
    return async (dispatch) => {
        let statementResponse = await fetchUserStatement(data);
        if (statementResponse.status) {
            dispatch(updateStatementReducer(statementResponse.data))
        }
        else {
            console.error(statementResponse.message)
        }
    }
}

export const updateStatementReducer = (payload) => {
    return {
        type: actionTypes.UPDATE_USER_STATEMENT_REDUCER,
        payload: payload
    };
}