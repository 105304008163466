import React from 'react';
import PropTypes from 'prop-types';
import { Box, CssBaseline, Button, Typography, Skeleton, } from "../../../imports/muiImport";
import { defaultProductImage, NavLink, useSelector } from "../../../imports/generalImport";

import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Global } from '@emotion/react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { categoryUrl } from '../../../shared/extra';



const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'primary' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));


function MobileCategoryDrawer(props) {
    const { window, open, setOpen, toggleDrawer } = props;
    const categoryData = useSelector((state) => state.Category.allData)

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(100vh - 170px)`,
                        // height: `calc(100vh - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={0}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >

                <StyledBox
                    sx={{
                        px: 2,
                        pt: 4,
                        pb: 3,
                        height: '100%',
                        overflow: 'auto',
                        backgroundColor: "#fffbff"
                    }}
                >
                    <Puller />

                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateRows: "repeat(2,1fr)",
                            gridTemplateColumns: "repeat(3,1fr)",
                            alignItems: "flex-start",
                            justifyItems: "center",
                            justifyContent: "center",
                            rowGap: 2,
                            columnGap: 2,
                        }}
                    >
                        {categoryData?.map((item) => {
                            if (item?.products?.length > 0) {
                                return (
                                    <NavLink
                                        to={`card-list?category=${categoryUrl(item?.title)}`}
                                        key={`drawer-category-${item?.id * 1}`}
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}>
                                            <Box
                                                component="img"
                                                src={item?.image || defaultProductImage}
                                                width={72}
                                                height={72}
                                                sx={{
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                    p: "4px",
                                                    border: "1px solid #deebff",
                                                }}
                                            />

                                            <Typography variant='subtitle1' sx={{
                                                width: "100%",
                                                textAlign: "center",
                                                color: "primary.main",
                                                fontWeight: 700,
                                                fontSize: "12.5px"
                                            }}>
                                                {item?.title}
                                            </Typography>
                                        </Box>
                                    </NavLink>
                                )

                            } else {
                                return null;
                            }
                        })}
                    </Box>
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
}


MobileCategoryDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


export default MobileCategoryDrawer;
