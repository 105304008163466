import { React, useState, forgotmobile, NavLink, useNavigate, useCallback } from "../../imports/generalImport";
import { Box, Container } from "../../imports/muiImport";
import MobilePhoneForm from '../mobileComponents/mobileUserAccountLogin/MobilePhoneForm';
import MobileOTPForm from '../mobileComponents/mobileUserAccountLogin/MobileOTPForm';
import { useAuth } from '../../hooks/contexts/AuthContext';
import { userTokenSave } from '../../apiCalls/functions';
import { actionNotifier } from '../../components/tostify';
import MobilePasswordForgotInfo from '../mobileComponents/mobileUserAccountLogin/MobilePasswordForgotInfo';
import MobileNewPassword from "../mobileComponents/mobileUserAccountLogin/MobileNewPassword";
import { Helmet } from "react-helmet-async";


function MobileForgotPassword() {
    const [showOTPForm, setOTPForm] = useState(false);
    const [isResendAvailable, setIsResendAvailable] = useState(false);
    const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
    const [forgotToken, setForgotToken] = useState({
        status: false,
        forgotToken: null
    });
    const [userCredentials, setUserCredentials] = useState({
        mobile: null,
        token: null,
        status: false
    });
    const navigate = useNavigate();



    const NewPasswordComponent = () => {
        return (
            <Box sx={{
                pt: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "calc(100% - 40px)",
                maxHeight: 400,
            }}>

                <Helmet>
                    <title>Forgot Password -99gift.in</title>
                    <meta property="og:title" content="Forgot Password -99gift.in" />
                </Helmet>

                <MobileNewPassword forgotToken={forgotToken} />
            </Box>
        );
    }

    const OtpForm = () => {
        return (
            <Box sx={{
                pt: 4,
                display: "flex",
                justifyContent: "center",
            }} >
                {userCredentials?.status &&
                    (<MobileOTPForm
                        userCredentials={userCredentials}
                        userLoginStatusSetup={userLoginStatusSetup}
                        isResendAvailable={isResendAvailable}
                        setIsResendAvailable={setIsResendAvailable}
                        forgotUserPassword={true}
                        setForgotToken={setForgotToken}
                    />)
                }
            </Box >
        )
    }

    const PasswordForgot = () => {
        return (
            <>
                <MobilePasswordForgotInfo />
                <Box
                    sx={{
                        mt: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: "calc(100% - 40px)",
                        maxHeight: 400,
                    }}
                >
                    <MobilePhoneForm setOTPForm={setOTPForm} setUserCredentials={setUserCredentials} />
                </Box>
            </>
        )
    }

    return (
        <Container sx={{
            bgcolor: "#ebf8fe",
            height: 800,
            maxHeight: '100%'
        }}>

            {showOTPForm ?
                (
                    forgotToken?.status ?
                        <NewPasswordComponent />
                        :
                        <OtpForm />
                )
                :
                <PasswordForgot />
            }

        </Container >

    );

    function userLoginStatusSetup(token) {
        // let loginToken = userTokenSave(token);
        // Set true User Login Status it will reflect every where
        // setIsLoggedIn(true);

        // if (loginToken) {
        // Notification remove 
        // actionNotifier.clearAll();
        // navigate("/", { replace: true });
        // }
    }
}




export default MobileForgotPassword;



