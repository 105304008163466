import { useEffect } from 'react'
import { onFetchProducts } from "../../innerComponent/AllProducts/action"
import { useDispatch, useSelector } from 'react-redux';

export default function Index() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.AllProducts.allData)

  useEffect(() => {
    if (!products.length > 0) {
      dispatch(onFetchProducts());
    }
  }, [dispatch, products.length]);
}