import { React, forgotmobile, useNavigate } from "../../../imports/generalImport";
import { Box, IconButton, Typography } from "../../../imports/muiImport";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


function MobilePasswordForgotInfo() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/login', { replace: true });
    };

    return (
        <>
            <Box sx={{
                pt: 2,
                display: "flex",

            }}>
                <IconButton
                    sx={{
                        backgroundColor: "white.main",
                        p: 1,
                        borderRadius: 2,
                        width: 40,
                        height: 40,
                    }}
                    onClick={handleBack}
                >
                    <ArrowBackIosIcon
                        fontSize="large" />
                </IconButton>
            </Box>

            <Box sx={{}}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Typography variant='h5'
                        sx={{
                            color: "primary.main",
                            fontWeight: 500,
                            mb: 1,
                        }}
                    >
                        Forgot Password?
                    </Typography>

                    <Typography variant='subtitle2'
                        sx={{
                            color: "textPrimary.main",
                            fontWeight: 500,
                            textAlign: "center",
                        }}
                    >
                        Enter your Registered mobile number below <br /> to receive password reset instruction
                    </Typography>

                    <Box
                        component={'img'}
                        src={forgotmobile}
                        width={110}
                    />
                </Box>
            </Box>
        </>
    )
}

export default MobilePasswordForgotInfo