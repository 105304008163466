import { getFeaturedProducts } from "../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";

export function onFetchFeaturedProducts() {
  return async (dispatch) => {
    const featureResponse = await getFeaturedProducts();
    if (featureResponse?.status) {
      dispatch(updateAllProductsReducer(featureResponse?.data));
    } else {
      console.error(featureResponse?.error);
    }
  };
}

export const updateAllProductsReducer = (payload) => {
  return {
    type: actionTypes.UPDATE_FEATURED_PRODUCTS,
    payload
  };
};
