import { Divider } from '@mui/material';
import { Fragment } from '../../imports/generalImport';
import { Button, useMediaQuery, useTheme, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Box } from '../../imports/muiImport';

export default function WalletPaymentConfirm(props) {
    const { walletPayConfirmTitle, open, walletPayConfirmContent, handleWalletPayConfirmClose, setWalletPayment } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleWalletPaymentAgree = () => {
        setWalletPayment(true);
        handleWalletPayConfirmClose();
    }

    const handleWalletPaymentDisagree = () => {
        setWalletPayment(false);
        handleWalletPayConfirmClose();
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleWalletPayConfirmClose}
            aria-labelledby="responsive-dialog-title"
        >

            <DialogContent sx={{
                width: 350,
                minHeight: 130,
                maxHeight: 170,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <DialogContentText >
                    {walletPayConfirmContent}
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: 350,
                height: 70,
            }}>
                <Button autoFocus onClick={handleWalletPaymentDisagree} variant='outlined'>
                    No
                </Button>
                <Divider orientation='vertical' />
                <Button onClick={handleWalletPaymentAgree} autoFocus variant='contained'>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}