import React from 'react'

function MobileBuyButton() {

    const scrollToTop = () => {
        window.scrollTo({
            bottom: 0,
            behavior: 'smooth', // Smooth scrolling effect
        });
        // Here you can also add additional functionality, like navigating to a checkout page.
    };

    return (
        <button onClick={scrollToTop} style={styles.button}>
            Buy Now
        </button>
    )
}


const styles = {
    button: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        padding: '10px 20px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
    },
};

export default MobileBuyButton