import { apiFunction } from '../../../apiCalls/functions';
import * as actionTypes from './actionTypes';
import { LoadingEnd, LoadingStart } from "../../../components/loading"
import toast from 'react-hot-toast';

export const fetchAllNotification = async (data) => {
    return await apiFunction(`user/notifications`, 'post', data, true, false)
}

export const onLoad = (data) => {
    return async (dispatch) => {
        dispatch(onFetchNotification(data))
    }
}

export function onFetchNotification(data) {
    return async (dispatch) => {
        LoadingStart()
        let result = await fetchAllNotification(data);
        if (result.status) {
            dispatch(updateNotificationReducer(result.data))
        }
        else {
            toast.error(result.message)
        }
        LoadingEnd()
    }
}

export const updateNotificationReducer = (data) => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_REDUCER,
        payload: { data }
    };
}