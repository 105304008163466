import { useEffect, useState } from "react";

export function useDebounce(value='', delay=800) {
    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebounceValue(value);
        }, delay);

        // Cleaning function
        return () => clearTimeout(timeout);
    }, [value, delay]);

    return debounceValue;
}