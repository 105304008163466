import { Box, List, ListItem, ListItemButton, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
const usefulLinks = [
    { title: "Blogs", link: "blog" },
    { title: "Corporate", link: "corporates" },
    { title: "About Us", link: "aboutus" },
    { title: "Contact Us", link: "contactus" },
    { title: "How Gift Voucher Works", link: "how-gift-card-work" },
    { title: "Corp Policy", link: "corppolicy" },
    { title: "Privacy Policy", link: "privacypolicy" },
    { title: "Terms and Conditions", link: "termsandcondition" },
];

function UseFulLinks() {
    return (
        <Box>

            <Typography
                variant="subtitle1"
                color="white.main"
                sx={{
                    textTransform: {
                        xs: "capitalize",
                    },
                    mb: { xs: "4px", md: 2, lg: 1 },
                    fontSize: { xs: "16px", sm: "16px", lg: "16px" },
                }}
            >
                useful link
            </Typography>


            <List component="ul" dense={true} sx={{
                maxWidth: {
                    xs: "100%",
                },
                padding: 0,
                display: "grid",
                gridTemplateRows: {
                    xs: "auto repeat(2,1fr)",
                    sm: "auto repeat(1fr)"
                },
                gridTemplateColumns: {
                    xs: "repeat(2,1fr)",
                    sm: "1fr"
                },
                gap: "0px"
            }}>
                {usefulLinks.map((item, index) => (
                    <ListItem key={`useful-link-${index * 1}`} sx={{ pl: { xs: 0, md: 0 }, width: "100%", pr: { xs: 0 } }}>
                        <NavLink to={`/${item.link}`} style={{ textDecoration: "none", width: "100%", }}>
                            <ListItemButton sx={{
                                color: "white.main",
                                fontSize: "13.5px",
                                fontWeight: {
                                    xs: 300,
                                    sm: 300
                                },
                                width: "100%",
                                display: "block",
                                py:0,
                            }}>
                                {item.title}
                            </ListItemButton>
                        </NavLink>
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export default UseFulLinks