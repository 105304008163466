import * as actionTypes from "./actionTypes";

export const addNewDenomination = (denominations) => {
    return {
        type: actionTypes.ADD_NEW_DENOMINATION,
        payload: denominations,
    };
}

export const removeAnDenomination = (AMOUNT) => {
    return {
        type: actionTypes.REMOVE_AN_DENOMINATION,
        payload: { AMOUNT },
    };
}

export const quantityIncrementDenomination = (AMOUNT, QUANTITY) => {
    return {
        type: actionTypes.UPDATE_QUANTITY_DENOMINATION,
        payload: { AMOUNT, QUANTITY },
    };
}

export const quantityDecrementDenomination = (AMOUNT, QUANTITY) => {
    return {
        type: actionTypes.UPDATE_QUANTITY_DENOMINATION,
        payload: { AMOUNT, QUANTITY },
    };
}

export const setNewDenomination = (denominations) => {
    return {
        type: actionTypes.SET_NEW_DENOMINATION,
        payload: denominations,
    };
}

export const wipeOutDenomination = () => {
    return {
        type: actionTypes.WIPE_OUT_DENOMINATION,
    };
}