import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from "react-redux";

// Components
import AllBlogs from "./AllBlogs";
import LatestBlog from "./LatestBlog";
import PageLoading from "../../PageLoading";
import PageBreadcrumb from "../../components/other/PageBreadcrumb";
import { onFetchBlogs } from "../../store/blogs/action";

const Blogpage = ({ isMobile = false }) => {
  const [loading, setLoading] = useState(true);
  const searchItem = useLocation();
  const canonicalUrl = useMemo(() => `https://www.99gift.in${searchItem.pathname}`, [searchItem.pathname]);
  const dispatch = useDispatch();
  const blogsData = useSelector((state) => state.blogs?.allData);

  useEffect(() => {
    if (!blogsData) {
      setLoading(true);
      dispatch(onFetchBlogs());
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [blogsData, dispatch]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Blogs Regarding Gift Cards - 99gift</title>
        <meta name="description" content="Blogs Regarding Gift Cards - 99gift" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content="Blogs Regarding Gift Cards - 99gift" />
        <meta property="og:description" content="Blogs Regarding Gift Cards - 99gift" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <Container maxWidth="xl" sx={{ backgroundColor: "white.main" }}>
        {!isMobile && <PageBreadcrumb home="Home" currentProduct="Blogs" />}
      </Container>

      <Container maxWidth="xl" sx={{ backgroundColor: "#fff", mt: { xs: 2, sm: 0 } }} disableGutters>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <LatestBlog blogData={blogsData?.[blogsData.length - 1]} />
            <AllBlogs blogData={blogsData?.slice(0, -1)} />
          </>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Blogpage;