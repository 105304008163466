import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "primaryDark.main",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

function MobileNotificationItem({ data }) {
    return (
        <List sx={{ width: '100%', bgcolor: '#EEF7FF', borderRadius: 2, }}>

            {data?.map((item, index) => (
                <React.Fragment key={`${item?.id}-notification`}>

                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar {...stringAvatar(item?.title)} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${item?.title}`}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {`${item?.description}`}
                                    </Typography>
                                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    {index < data?.length - 1 && (
                        <Divider variant="inset" component="li" />
                    )}
                </React.Fragment>
            ))}



        </List>
    )
}

export default MobileNotificationItem;
