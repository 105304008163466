import React from 'react'
import { useState, useSelector, useDispatch } from '../../../imports/generalImport';
import { Container, Typography, Box, TextField, Button, FormHelperText, InputAdornment, IconButton } from '../../../imports/muiImport';
import { handleAmount } from '../../../shared/extra';
import { addNewDenomination } from '../../../store/productDetails/action';


function MobileRangeInput({ rangeDenomination }) {
    const productDetail = useSelector((state) => state.ProductDetail?.allData);
    const [inputError, setInputError] = useState(false);
    const [inputErrorMessage, SetInputErrorMessage] = useState('')
    const [userAmt, setUserAmt] = useState(null);
    const dispatch = useDispatch();
    const DENOMINATION_STORE_DATA = useSelector((state) => state.Denominations?.allData);

    const handleRangeDenomination = () => {
        if (userAmt >= productDetail?.min_price && userAmt <= productDetail?.max_price) {
            const amtStatus = checkDuplicateDenomination();

            if (amtStatus) {
                handleRangeDenominationData(dispatch);
            } else {
                setInputError(true);
                SetInputErrorMessage("Duplicate Denomination Amount!")
            }
        } else {
            setInputError(true);
            SetInputErrorMessage("Invalid Denomination Amount!")
        }
    }
    // Set Range Amount
    const handleRangeInput = (e) => {
        setUserAmt(e?.target?.value.replace(/\D/g, ''));
        if (inputError) {
            setInputError(false);
            SetInputErrorMessage('');
        }
    }


    if (rangeDenomination) {
        return (
            <Container>
                <Typography
                    variant='subtitle1'
                    sx={{
                        color: 'primary.main',
                        fontSize: "15.5px",
                        fontWeight: 600,
                        mb: 1
                    }}
                >
                    Enter Denomination
                </Typography>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 1,
                }}>
                    {/* Input field with specified width and border styling */}
                    <TextField
                        variant="outlined"
                        placeholder={`₹${handleAmount(productDetail?.min_price)} to ₹${handleAmount(productDetail?.max_price)} Rs`}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#B0B0B0', // Adjust border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#B0B0B0', // Border color on hover
                                },
                            },
                        }}
                        pattern="\d{6}"
                        value={userAmt ? handleAmount(userAmt) : ''}
                        onChange={(e) => handleRangeInput(e)}
                        error={inputError}
                        inputProps={{
                            maxLength: 6,
                        }}
                    />

                    {/* Button styled to match the design */}
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#002D72', // Dark blue color
                            borderRadius: 2, // Rounded corners
                            textTransform: 'none', // Prevents all uppercase text
                            '&:hover': {
                                backgroundColor: '#001B50', // Slightly darker on hover
                            },
                        }}
                        onClick={handleRangeDenomination}
                    >
                        Add Amount
                    </Button>
                </Box>

                <FormHelperText sx={{
                    mt: 1,
                    fontSize: "11.5px",
                    color: inputErrorMessage ? "error.main" : "textPrimary.main",
                    fontWeight: inputErrorMessage ? 400 : 500,
                    textTransform: "capitalize"
                }}>
                    {inputErrorMessage || `Denominations must range from ₹${handleAmount(productDetail?.min_price)} to ₹${handleAmount(productDetail?.max_price)} Rs`}
                </FormHelperText>


            </Container>
        );
    } else {
        return (null);
    }

    function handleRangeDenominationData(dispatch) {
        const productId = productDetail?.['id'];
        if (productId) {
            const discount = productDetail['discount'];
            const denominationObject = {
                "AMOUNT": userAmt,
                "DISCOUNT": discount,
                "QUANTITY": 1,
                "TOTAL": parseInt(userAmt * 1),
                "PRODUCT_CODE": null,
                "PRODUCT_GUID": null,
                "PRODUCT_ID": null,
                "SUB_PRODUCT_ID": null,
                "SKU_ID": null
            };
            dispatch(addNewDenomination(denominationObject));
            setUserAmt('');
        }
    }

    function checkDuplicateDenomination() {
        if (DENOMINATION_STORE_DATA?.length > 0) {
            const amounts = DENOMINATION_STORE_DATA?.map(item => item.AMOUNT);
            const occurrences = amounts.filter(itemAmount => itemAmount === userAmt).length;
            return !occurrences >= 1;
        }
        return true;
    }
}

export default MobileRangeInput