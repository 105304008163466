import { React, defaultProductImage, useState } from "../../../imports/generalImport";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Box,
    IconButton,
    Stack,
    Button,
} from "../../../imports/muiImport";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventIcon from '@mui/icons-material/Event';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { dateChanger, titleToUrl } from "../../../shared/extra";
import { actionNotifier } from "../../../components/tostify";
import CopyToClipboard from "react-copy-to-clipboard";


function MobileGiftVoucher(props) {
    const { handleClose, orderData, voucherData } = props;
    const [copyText, setCopyText] = useState('');
    const [copied, setCopied] = useState(false);

    const handleCopy = (title) => {
        if (title === "voucher-no") {
            actionNotifier.info("Voucher No. Copied!", {
                position: "top-right",
                autoClose: 600,
                theme: "dark",
                hideProgressBar: "true"
            });
        } else {
            actionNotifier.info("Voucher Pin Copied!", {
                position: "top-right",
                autoClose: 600,
                theme: "dark",
                hideProgressBar: "true"
            });
        }
        setCopied(true);
    };



    return (
        <Card
            sx={{
                bgcolor: '#f0f8ff',
                borderRadius: 4,
                borderRadius: 2,
                boxShadow: 24,
                p: 1,
                justifyContent: 'center',
            }}
        >
            {/* Voucher Image */}
            <CardMedia
                component="img"
                width={191}
                height={181}
                image={orderData?.product?.image}  // Replace with your image URL
                alt={orderData?.product?.title}
                loading="lazy"
                sx={{
                    my: 2,
                    borderRadius: 4,
                    boxShadow: 4,
                }}
            />

            {/* Voucher Details */}
            <CardContent>
                <Grid container spacing={2} >
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Amount
                        </Typography>
                        <Typography variant="subtitle1" color={"primary"}>₹{orderData?.amount}</Typography>
                    </Grid>

                    {/* Expiry Date */}
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Expiry
                        </Typography>
                        <Typography variant="subtitle1" color={"primary"} display={"flex"} alignItems={'center'}>
                            <EventIcon fontSize="medium" /> {dateChanger(voucherData?.EndDate)}
                        </Typography>
                    </Grid>
                </Grid>

                {/* Gift Card Number */}
                {voucherData?.VoucherNo &&
                    (
                        <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', mt: 2 }}>
                            <Typography
                                variant="subtitle2"
                                color={"primary"}
                                sx={{
                                    flexGrow: 1,
                                    fontSize: 15.5
                                }}
                            >
                                Gift Card Number:
                            </Typography>

                            <Stack
                                sx={{
                                    flexGrow: 1,
                                    flexDirection: "row",
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: "100%",
                                }}
                            >
                                <Stack
                                    sx={{
                                        flexDirection: "row",
                                        alignItems: 'center',
                                    }}
                                >
                                    <CreditCardIcon color="action" sx={{ mr: 1 }} />
                                    <Typography variant="subtitle1" color={"primary"} >
                                        {voucherData?.VoucherNo}
                                    </Typography>
                                </Stack>

                                <Stack
                                    sx={{
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        alignItems: 'center',
                                        width: "100%",
                                    }}
                                >
                                    <CopyToClipboard
                                        text={voucherData?.VoucherNo}
                                        onCopy={() => handleCopy(titleToUrl('Voucher No.'))}
                                    >
                                        <IconButton size="small">
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    </CopyToClipboard>
                                </Stack>
                            </Stack>
                        </Box>
                    )
                }

                {/* Gift Card Pin */}

                {voucherData?.Voucherpin &&
                    (
                        <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', mt: 2 }}>
                            <Typography
                                variant="subtitle2"
                                color={"primary"}
                                sx={{
                                    flexGrow: 1,
                                    fontSize: 15.5
                                }}
                            >
                                Gift Card Pin:
                            </Typography>

                            <Stack
                                sx={{
                                    flexGrow: 1,
                                    flexDirection: "row",
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: "100%",
                                }}
                            >
                                <Stack
                                    sx={{
                                        flexDirection: "row",
                                        alignItems: 'center',
                                    }}
                                >
                                    <CreditCardIcon color="action" sx={{ mr: 1 }} />
                                    <Typography variant="subtitle1" color={"primary"} >
                                        {voucherData?.Voucherpin}
                                    </Typography>
                                </Stack>

                                <Stack
                                    sx={{
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        alignItems: 'center',
                                        width: "100%",
                                    }}
                                >
                                    <CopyToClipboard
                                        text={voucherData?.Voucherpin}
                                        onCopy={() => handleCopy(titleToUrl('Voucher Pin'))}
                                    >
                                        <IconButton size="small">
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    </CopyToClipboard>


                                </Stack>
                            </Stack>
                        </Box>
                    )
                }

            </CardContent>

            <Button
                variant="contained"
                size="large"
                sx={{
                    my: 3,
                    bgcolor: "error.main",
                    mx: "auto",
                    display: "flex",
                    justifyContent: "center,"
                }}
                onClick={handleClose}
            >
                close
            </Button>
        </Card>
    )
}

export default MobileGiftVoucher