import { useState, } from "../../../imports/generalImport";
import { Typography, Box, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useMediaQuery } from "../../../imports/muiImport";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import MobilePasswordChangeForm from "./MobilePasswordChangeForm";


function MobilePasswordChange() {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box sx={{
                mt: 2,
                p: 2,
                borderRadius: 2,
                bgcolor: "white.main",
            }}>
                <Stack
                    onClick={handleClickOpen}
                    sx={{
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <Stack sx={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <LockIcon sx={{
                            color: "primary.main",
                            fontSize: "18px"
                        }} />

                        <Typography variant='subtitle1' sx={{
                            fontSize: "13.5px",
                            fontWeight: 500,
                            color: "primary.main",
                            ml: 1,
                        }}>
                            Change Password
                        </Typography>
                    </Stack>

                    <EditIcon sx={{
                        color: "primary.main",
                        fontSize: "20px"
                    }} />
                </Stack>
            </Box>

            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="change-password-title"
                aria-describedby="change-password-description"
            >

                <MobilePasswordChangeForm handleClose={handleClose} />
                {/* <DialogTitle id="responsive-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Disagree
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
}

export default MobilePasswordChange