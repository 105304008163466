import React, { Fragment, Suspense, useEffect } from "react";
import { AuthProvider } from "./hooks/contexts/AuthContext";
import { RouterProvider } from "react-router-dom";
import PageLoading from "./PageLoading";
import { useMediaQuery } from "@mui/system";
import ApiDispatch from "./apiCalls/ApiDispatch";

// mobile Device Router
import { mobileRouter } from "./routes/mobileRoutes";

// large Device Router
import { router } from "./routes";

function App() {
  const device = useMediaQuery('(max-width:600px)');
 
  return (
    <React.Fragment>
      <AuthProvider>
        <ApiDispatch />
        <Suspense fallback={<PageLoading />}>
          {device ? (
            <RouterProvider router={mobileRouter} />
          ) : (
            <RouterProvider router={router} />
          )}
        </Suspense>
      </AuthProvider>
    </React.Fragment>
  )

}

export default App;



// if (device) {
//   return (<MobileRedirect />);
// } else {
//   return (
//     <React.Fragment>
//       {/* <AllProductsFetch />
//       <BestSeller />
//       <FeaturedProducts />
//       <TopFiveProducts />
//       <EntertainmentProducts />
//       <SliderData />
//       <FetchCategory /> */}

//       <AuthProvider>
//         <Suspense fallback={<PageLoading />}>
//           <RouterProvider router={router} />
//         </Suspense>
//       </AuthProvider>

//     </React.Fragment >
//   );
// }
