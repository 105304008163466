import React from "react";
import { Grid, Chip, Button, Typography, Box } from "@mui/material";
import DefaultBlogImage from "../../assets/others/blog_image_default1.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { cleanContent, titleToUrl } from "../../shared/extra";
import { NavLink } from "react-router-dom";
import { dateSlashFormant } from "../../shared/dateFunction";
import PageLoading from "../../PageLoading";
const LatestBlog = ({ blogData }) => {

  if (!blogData) {
    return <PageLoading />;
  }

  return (
    <Grid
      container
      maxWidth="false"
      sx={{
        p: { xs: 1, md: 2 },
        backgroundColor: 'white.main',
      }}
    >
      <Grid item xs={12} md={5}>
        <NavLink to={`/blog-details/${titleToUrl(blogData?.title)}`} style={{ textDecoration: "none" }}>
          <Box
            component="img"
            src={blogData ? blogData?.posterImage : DefaultBlogImage}
            alt="Blog Image"
            loading="lazy"
            sx={{
              width: "100%",
              height: "auto",
              maxHeight: { xs: 200, md: 300 },
              objectFit: "contain",
              borderRadius: { xs: 0, md: 3 },
              boxShadow: 4,
            }}
          />
        </NavLink>
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={7}
        sx={{
          px: { xs: 0, md: 4 },
          my: { xs: 3, md: 0 },
        }}
      >
        {/* Blog details */}
        <Grid item xs={12}>
          <Chip
            label="New"
            sx={{
              backgroundColor: "primary.main",
              color: "white.main",
              fontSize: { xs: "12px", md: "12px" },
              px: { xs: 1, md: 1 },
              borderRadius: { xs: 1, md: 1 },
              mx: { xs: "3px", md: "3px" },
            }}
          />
          <Chip
            label={dateSlashFormant(blogData?.created_at)}
            variant="outlined"
            icon={<CalendarMonthIcon />}
            sx={{
              color: "primary.main",
              fontSize: { xs: "12px", md: "12px" },
              px: { xs: 1, md: 2 },
              borderRadius: { xs: 1, md: 1 },
              mx: { xs: "3px", md: "3px" },
            }}
          />

          <Chip
            label="3 Min."
            variant="outlined"
            icon={<ScheduleIcon />}
            sx={{
              color: "primary.main",
              fontSize: { xs: "12px", md: "12px" },
              px: { xs: 1, md: 2 },
              py: 0,
              borderRadius: { xs: 1, md: 1 },
              mx: { xs: "3px", md: "3px" },
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ my: { xs: 1, md: 0 } }}>
          <NavLink to={`/blog-details/${titleToUrl(blogData?.title)}`} style={{ textDecoration: "none" }}>
            <Typography
              variant="h1"
              color="primary.main"
              sx={{
                fontSize: { xs: "22px", md: "24px" },
                fontWeight: 500,
                mt: 1
              }}
            >
              {blogData?.title ? blogData.title : null}
            </Typography>


            {/* <Box
              component="div"
              dangerouslySetInnerHTML={{ __html: cleanContent(blogData?.content).slice(0, 300) || null }}
              style={{
                whiteSpace: "initial",
                wordWrap: "break-word",
                overflowX: "auto",
                maxWidth: "100%",
                boxSizing: "border-box",
                wordSpacing: "0px",
                textIndent: "revert-layer",
                fontFamily: "Celias",
              }}
              sx={{
                mt: 0,
                fontSize: { xs: "13.5px", lg: "15.5px" },
                lineHeight: { xs: "16px", lg: "24.5px" },
                color: "dark.main",
                fontWeight: { xs: 400, lg: 400 },
              }}
            /> */}

            <Box
              component="div"
              dangerouslySetInnerHTML={{ __html: cleanContent(blogData?.content) || null }}
              style={{
                whiteSpace: "initial",
                wordWrap: "break-word",
                maxWidth: "100%",
                boxSizing: "border-box",
                wordSpacing: "0px",
                textIndent: "revert-layer",
                fontFamily: "Celias",
                display: "-webkit-box", // Necessary for line clamping
                WebkitBoxOrient: "vertical", // Necessary for line clamping
                overflow: "hidden",
                WebkitLineClamp: 3, // Limit to 3 lines
              }}
              sx={{
                mt: 0,
                fontSize: { xs: "13.5px", lg: "15.5px" },
                lineHeight: { xs: "16px", lg: "24.5px" },
                color: "dark.main",
                fontWeight: { xs: 400, lg: 400 },
              }}
            >
            </Box>

            

          </NavLink>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: { xs: "flex", md: "initial" },
            justifyContent: { xs: "flex-end" },
          }}
        >
          <NavLink to={`/blog-details/${titleToUrl(blogData?.title)}`} style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                fontSize: { xs: "12px", md: "12px" },
                color: "white.main",
                textTransform: "uppercase",
                px: { xs: 2, md: 3 },
              }}
            >
              Read More
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

LatestBlog.propTypes = {};

export default LatestBlog;
