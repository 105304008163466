import React, { useState } from 'react'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { dateBasicFormant, dateSlashFormant, getLastMonthDate } from '../../shared/dateFunction';
import { useDispatch } from 'react-redux';


const momentDate = moment();
const accountOpeningDate = moment('2015-01-01');
const previousDay = momentDate.clone().subtract(1, 'days');


export default function Index(props) {
    const { initialData, reducer } = props;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [getDataStatus, setGetDataStatus] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const handleDateChange = (newDate, name) => {
        setError('');
        if (name === 'from') {
            if (dateBasicFormant(newDate) !== dateBasicFormant(startDate)) {
                setStartDate(newDate);
                setGetDataStatus(false);
            } else {
                setGetDataStatus(true);
            }
        } else if (name === 'to') {
            if (dateBasicFormant(newDate) !== dateBasicFormant(endDate)) {
                setEndDate(newDate);
                setGetDataStatus(false);
            }
            if (moment(newDate).isBefore(startDate)) {
                setEndDate(startDate); // Ensure "To Date" is not earlier than "From Date"
            }
        }
    };

    const fetchOrderData = (start, end) => {
        if (start && end) {
            const requestData = {
                search: null,
                filterBy: null,
                date: { from: dateSlashFormant(start), to: dateSlashFormant(end) },
                pagination: {
                    sortBy: 'id',
                    descending: true,
                    page: 1,
                    rowsPerPage: 99999999,
                    rowsNumber: 0,
                },
                status: 0,
            };

            dispatch(reducer(requestData));
            setGetDataStatus(true);
        } else {
            setError('Please Select Valid Date.');
        }
    };


    const fetchDefault = () => {
        if (startDate || endDate) {
            setError('')
            dispatch(reducer(initialData));
            setStartDate(null);
            setEndDate(null);
            setGetDataStatus(false);
        }
    };

    return (
        <Box
            sx={{
                maxWidth: {
                    xs: 600,
                    sm: 750,
                },
                display: 'flex',
                flexDirection: {
                    xs: "column",
                    md: "row",
                },
                alignItems: {
                    xs: "center",
                    sm: "flex-start"
                },
                justifyContent: { xs: "center", sm: "flex-start" },
                gap: {
                    xs: 2,
                    sm: 3,
                },
            }}>
            <Box sx={{
                width: {
                    xs: "90vw",
                    sm: "auto",
                },
                display: 'flex',
                flexDirection: "column",
                alignItems: {
                    xs: "center",
                    sm: "flex-start"
                },
                mb: {
                    xs: 1,
                    sm: 0
                }
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="From Date"
                            value={startDate}
                            onChange={(newDate) => handleDateChange(newDate, 'from')}
                            minDate={accountOpeningDate}
                            maxDate={endDate || previousDay}
                        />

                        <Box sx={{
                            mx: 2,
                        }}>
                            <Typography
                                component="span"
                                variant='subtitle1'
                                sx={{
                                    border: 1,
                                    my: {
                                        xs: 1,
                                        sm: 1
                                    },

                                    width: "100%",
                                    textIndent: {
                                        xs: "150px",
                                        sm: 0,
                                        lg: 0
                                    },
                                    fontSize: {
                                        xs: ".8rem",
                                        lg: ".7rem"
                                    },
                                    textTransform: { xs: "uppercase" },
                                    color: { xs: "primary.main" },
                                    fontWeight: 500,
                                    display: "contents"
                                }}>
                                To
                            </Typography>
                        </Box>

                        <DatePicker
                            label="To Date"
                            value={endDate}
                            onChange={(newDate) => handleDateChange(newDate, 'to')}
                            minDate={startDate}
                            maxDate={momentDate}
                            disabled={!startDate}
                        />

                    </LocalizationProvider>
                </Box>

                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={{
                        color: "error.main",
                        mt: 1,
                        fontSize: {
                            xs: "11px",
                        },
                        ml: {
                            xs: 2,
                            sm: 0
                        },
                        display: error ? "contents" : "none",
                    }}>
                    {error}
                </Typography>

            </Box>

            {/* Buttons */}
            <Box sx={{
                width: {
                    xs: "90vw",
                    sm: "auto",
                },
                display: "flex",
                justifyContent: {
                    xs: "center",
                    sm: "space-between",
                    lg: "flex-end"
                },
                flexDirection: {
                    lg: "row-reverse"
                }
            }}>
                <Button variant='outlined'
                    sx={{
                        mx: 1,
                        textTransform: "capitalize",
                        width: {
                            xs: "100%",
                            sm: "auto"
                        },
                        mr: {
                            xs: 3,
                            sm: 1
                        },
                        ml: 0,
                        py: { xs: 1.5, sm: 1 }
                    }}
                    size='large'
                    startIcon={<FilterAltOffIcon />}
                    onClick={fetchDefault}>
                    Clear Filter
                </Button>

                <Button variant='contained'
                    sx={{
                        textTransform: "capitalize",
                        width: {
                            xs: "100%",
                            sm: "auto"
                        },
                        ml: {
                            xs: 3,
                            sm: 0
                        },
                        mr: {
                            xs: 0,
                            sm: 1
                        },
                        py: { xs: 1.5, sm: 1 }
                    }}
                    size='large'
                    startIcon={<FilterAltIcon />}
                    disabled={getDataStatus}
                    onClick={() => fetchOrderData(startDate, endDate)}>
                    Filter
                </Button>
            </Box>

        </Box>
    )
}