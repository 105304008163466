import * as actionTypes from "./actionTypes";
const initialState = {
  allData: [],
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CATEGORY_REDUCER:
      state = {
        ...state,
        allData: action.payload.data.data,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default reducer;
