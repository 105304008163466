import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile } from './action';
import { dateSlashFormant, getLastMonthDate } from '../../shared/dateFunction';
import moment from 'moment';
import { onFetchOrder } from '../userOrders/action';

const momentDate = moment();
const initialData = {
    search: null,
    filterBy: null,
    date: { from: getLastMonthDate(), to: dateSlashFormant(momentDate) },
    pagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 999999,
        rowsNumber: 0,
    },
    status: 0,
};



export default function Index() {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.UserProfile?.allData);
    const userOrdersData = useSelector((state) => state?.UserOrder);



    useEffect(() => {
        if (!products?.length) {
            dispatch(fetchUserProfile());
        }
    }, [dispatch, products?.length]);


    useEffect(() => {
        if (!userOrdersData?.length) {
            dispatch(onFetchOrder(initialData));
        }
    }, [dispatch, userOrdersData?.length]);

    return null;
}