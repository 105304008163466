import React from 'react'
import { Stack, Chip } from "../../../imports/muiImport";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function RedeemableProduct({ store, website }) {

    const isStoreRedeem = (redeem) => {
        if (redeem) {
            return < Chip key={'storeTrue'} icon={<CheckCircleIcon sx={{
                color: "#2ab638 !important",
            }}
            />} label="Store" variant="filled" sx={{
                color: "#0fab22",
                backgroundColor: "#caf6be",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: 4,
            }} />
        } else {
            return < Chip icon={<CancelIcon sx={{
                color: "#ea0c0c !important",
            }} />}
                label="Store" variant="filled" sx={{
                    color: "#ff2121",
                    backgroundColor: "#f6bebe",
                    fontSize: "1rem",
                    fontWeight: 600,
                    boxShadow: 4,
                }} />
        }
    }

    const isOnlineRedeem = (redeem) => {

        if (redeem) {
            return < Chip icon={<CheckCircleIcon sx={{
                color: "#2ab638 !important",
            }}
            />} label="Online" variant="filled" sx={{
                color: "#0fab22",
                backgroundColor: "#caf6be",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: 4,
            }} />
        }
        else {
            return < Chip icon={<CancelIcon sx={{
                color: "#ea0c0c !important",
            }} />} label="Online" variant="filled" sx={{
                color: "#ff2121",
                backgroundColor: "#f6bebe",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: 4,
            }} />
        }
    }

    return (
        <React.Fragment>
            <Stack justifyContent={'space-around'} flexDirection={'row'} sx={{
                minWidth: 170,
            }}>
                {isStoreRedeem(store)}
                {isOnlineRedeem(website)}
            </Stack>

        </React.Fragment>
    )
}

export default RedeemableProduct