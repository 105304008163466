// import { getProductDetails } from "../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";

export const setCategoriesProducts = (payload) => {
    return {
        type: actionTypes.SET_CATEGORIZED_PRODUCTS,
        payload
    };
}

export const wipeOutCategoriesProducts = () => {
    return {
        type: actionTypes.REMOVE_CATEGORIZED_PRODUCTS,
    };
}