import React, { useEffect, useState } from 'react';
import AllProductsFetch from "../layouts/innerComponent/AllProducts/index";
import FetchCategory from "../layouts/innerComponent/NavarCategories/fetchCategory";
import BestSeller from "../store/bestSeller";
import FeaturedProducts from "../store/featuredProducts";
import TopFiveProducts from "../store/topFive";
import EntertainmentProducts from "../store/EntertainmentProducts";
import SliderData from "../store/fetchSlider";
import ProductFilterByCategory from '../store/productsByCategory/ProductFilterByCategory';
import UserProfile from "../store/userProfile";
import Blog from "../store/blogs";
import { useAuth } from '../hooks/contexts/AuthContext';

function ApiDispatch() {

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const {
        isLoggedIn,
    } = useAuth();

    useEffect(() => {
        setTimeout(() => {
            if (isLoggedIn) {
                setIsUserLoggedIn(true)
            } else {
                setIsUserLoggedIn(false)
            }
        }, 800);
    }, [isLoggedIn]);


    return (
        <>
            <AllProductsFetch />
            <BestSeller />
            <FeaturedProducts />
            <TopFiveProducts />
            <EntertainmentProducts />
            <SliderData />
            <FetchCategory />
            <ProductFilterByCategory />
            <Blog />

            {isUserLoggedIn &&
                <UserProfile />
            }
        </>
    )
}

export default ApiDispatch