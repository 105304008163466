import { React, useSelector, Slider, NavLink } from "../../imports/generalImport";
import { Container, Skeleton, Box } from "../../imports/muiImport";
import './homeslider.css'

export default function Index({ height = 181,dots = true }) {   
    const sliderData = useSelector((state) => state.SlidersData.allData);
    const settings = {
        dots: dots,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
    };

    return (
        <Container
            maxWidth="false"
            disableGutters
            className="slider-container"
            sx={{
                position: 'relative',
            }}
        >
            {sliderData?.length ?
                (<Slider {...settings} arrows={false}>
                    {sliderData?.map((item) => (
                        item?.url ?
                            (<NavLink to={item?.url} key={`hero-slider-${item.url}`}>
                                <SliderImage item={item} height={height} />
                            </NavLink>)
                            :
                            (<SliderImage item={item} height={height} key={`hero-slider-${item.url}`} />)
                    ))}
                </Slider>)
                :
                (<Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    sx={{
                        minHeight: {
                            xs: height,
                            sm: '260px',
                            md: '320px',
                            lg: '390px',
                            xl: "380px"
                        },
                        maxHeight: {
                            xs: height,
                            sm: '260px',
                            md: '320px',
                            lg: '390px',
                            // xl: "53vh"
                            xl: "60vh"
                        },
                    }}
                />)
            }
        </Container>
    );
}


function SliderImage({ item, height }) {
    if (!item?.image) {
        return null
    }

    return (
        <Box
            component="img"
            src={item?.image}
            alt={`${item?.title}-99gift-hero-sliders`}
            loading="lazy"
            sx={{
                backgroundColor: { xs: "white.main", sm: 'backgroundPrimary.main' },
                overflow: 'hidden',
                width: '100%',
                minHeight: {
                    xs: height,
                    sm: '260px',
                    md: '320px',
                    lg: '390px',
                    xl: "380px"
                },
                maxHeight: {
                    xs: height,
                    sm: '260px',
                    md: '320px',
                    lg: '390px',
                    xl: "65vh"
                },
                borderRadius: { xs: 4, sm: 0 },
            }}
        />
    )
}