import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/contexts/AuthContext";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import PageLoading from "../PageLoading";

const accessGrantedUser = [70098];

const BlogAuthUser = () => {
    const { isLoggedIn, isLoading } = useAuth();
    const profile = useSelector((state) => state.UserProfile?.allData);
    const [isAuthorized, setIsAuthorized] = useState(null); // null = loading, true = authorized, false = unauthorized

    useEffect(() => {
        if (profile && profile?.data?.id) {
            const userAuthenticated = accessGrantedUser.includes(profile?.data?.id);
            setIsAuthorized(userAuthenticated);
        }

        setTimeout(() => {
            setIsAuthorized(false);
        }, 10000);
    }, [profile]);

    if (isLoading || isAuthorized === null) {
        return <PageLoading />; // Show loading while determining authorization
    }

    if (!isLoggedIn || !isAuthorized) {
        return <Navigate to="/" replace />;
    }

    return <Outlet />; // Render child routes if authorized
};

export default BlogAuthUser;