
import MobileNotificationItem from '../mobileComponents/mobileNotifications/MobileNotificationItem';
import { onFetchNotification } from '../../pages/profileDashboard/notification/action';
import { Container } from "../../imports/muiImport";
import { React, useEffect, useSelector, useDispatch } from "../../imports/generalImport";
import { Helmet } from 'react-helmet-async';


const initialData = {
    search: null,
    filterBy: null,
    date: { from: null, to: null },
    pagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 10000000000,
        rowsNumber: 0,
    },
    status: 0,
};

function MobileNotifications() {
    const dispatch = useDispatch();
    const notificationData = useSelector((state) => state.Notification?.allData);

    useEffect(() => {
        if (!notificationData?.length) {
            dispatch(onFetchNotification(initialData));
        }
    }, [dispatch]);


    return (
        <Container sx={{ pt: 1 }}>
            <Helmet>
                <title>Profile Notification -99gift.in</title>
                <meta property="og:title" content="Profile Notification -99gift.in" />
            </Helmet>

            <MobileNotificationItem data={notificationData} />
        </Container>
    );
}

export default MobileNotifications;
