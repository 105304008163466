import { React, useNavigate } from "../../../imports/generalImport";
import { Box, Container, TextField, InputAdornment, IconButton, SearchIcon } from "../../../imports/muiImport";

function MobileHomeSearch() {
    const navigate = useNavigate();
    return (
        <Container sx={{
            pt: '2px',
        }}>
            <Box sx={{
            }}>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    placeholder='Search brand coupons and etc.'
                    onClick={() => navigate('/search')}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 8,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: 8,
                        },
                        '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                            textIndent: 15,
                            fontSize: "17.5px",
                            fontWeight: 400,
                            color: "textPrimary.main"
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="medium"
                                    style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>

                        ),
                    }}
                />
            </Box>
        </Container >
    )
}

export default MobileHomeSearch