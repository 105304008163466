import React, { useState } from "react";
import {
    Box,
    Button,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { validateEmail } from "../../shared/validation";
import EmailIcon from "@mui/icons-material/Email";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { sendSubscribeUs } from "../../apiCalls/urls/urlFunction";

function NewsLetter() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState({ status: false, message: '' });

    return (
        <Box sx={{ width: { xs: "100%", sm: "75%" } }}>

            <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                    color: "white.main",
                    fontSize: { xs: "12px", sm: "14px" },
                    mb: { xs: 1, sm: 2 },
                    fontWeight: {
                        xs: 400,
                        sm: 400
                    }
                }}
            >
                Your Email:
            </Typography>
            <TextField
                type="email"
                fullWidth
                autoComplete="false"
                value={email}
                size="md"
                onChange={(e) => setEmail(e.target.value)}
                disabled
                sx={{
                    borderRadius: "4px",
                    overflow: "hidden",
                    backgroundColor: "#1a3b6e",
                    mb: "10px",
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon style={{ color: "#fff" }} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {email ?
                                emailError?.status ? ( // Optional: Check error state
                                    <CancelIcon color="error" />
                                ) : (
                                    <CheckCircleIcon color="success" />
                                )
                                :
                                null
                            }
                        </InputAdornment>
                    ),
                    style: {
                        color: "#ffffff",
                        height: "40px",
                        fontSize: "12px",
                    },
                }}
                error={emailError.status === true}
                helperText={emailError.message ? emailError.message : null}
                focused={emailError.status === true}
                color="error"
            />
            <Button
                type="submit"
                variant="contained"
                size="large"
                color="secondary"
                sx={{ flexShrink: 0, textTransform: "capitalize", height: "38px", width: "100%" }}
            >
                Subscribe
            </Button>
        </Box>
    );

    function handleSubscribe(event, email) {
        event.preventDefault();
        const emailError = { status: '', message: '' }

        let isValid = true;
        const isValidEmail = validateEmail(email);
        if (!isValidEmail.status) {
            emailError.status = true;
            emailError.message = isValidEmail.message;
            isValid = false;
            setEmailError(emailError);
        }

        if (isValid) {
            const promise = sendSubscribeUs({ "email": email });
            promise
                .then((response) => {
                    if (response?.data?.status) {
                        alert(response?.data?.message);
                        setEmail('');
                    }
                })
                .catch((error) => {
                    console.error("Subscription failed:", error);
                });
        }

    }
}

NewsLetter.propTypes = {};

export default NewsLetter;
