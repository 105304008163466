import React, { useEffect } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Typography, Box } from "@mui/material";
import { handleAmount } from "../../shared/extra";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserBalance } from "../../store/userBalance/action"
import { rewardPointCoin } from "../../imports/generalImport";

const UserBalance = () => {
  const balance = useSelector((state) => state.UserBalance.allData);
  const userProfile = useSelector((state) => state.UserProfile.allData.data);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchUserBalance())
  }, []);


  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          userSelect: "none",
          minWidth: "50px",
        }}
      >
        <AccountBalanceWalletIcon size="large" sx={{ mr: "3px", color: "textPrimary.main1" }} />

        <Box
          component={'img'}
          src={rewardPointCoin}
          sx={{
            width: "20px",
            height: "20px",
          }}
        />
        <Typography
          component="span"
          variant="subtitle1"
          gutterBottom
          sx={{
            mb: 0, mx: "4px", color: "textPrimary.main1", fontWeight: 400, fontSize: {
              xs: "15px",
              lg: "15.5px",
              xl: "16.5px"
            }

          }}
        >
          &#8377;{userProfile?.points > 1 ? handleAmount(userProfile?.points) : "0.00"}
        </Typography>
      </Box> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          userSelect: "none",
          minWidth: "50px",
        }}
      >
        <AccountBalanceWalletIcon size="large" sx={{ mr: "3px", color: "textPrimary.main1" }} />
        <Typography
          component="span"
          variant="subtitle1"
          gutterBottom
          sx={{
            mb: 0, mx: "4px", color: "textPrimary.main1", fontWeight: 400, fontSize: {
              xs: "15px",
              lg: "15.5px",
              xl: "16.5px"
            }

          }}
        >
          &#8377;{balance > 1 ? handleAmount(balance) : "0.00"}
        </Typography>
      </Box>
    </>
  );

};

export default UserBalance;
