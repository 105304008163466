import { getProductDetails } from "../../apiCalls/urls/urlFunction";


export const validateOutOfStock = (apiId, front, denom) => {
    let response = {
        "outOfStockStatus": false,
        "rangeDenominationStatus": false
    }

    if (apiId == 5 && front !== 0) {
        response.outOfStockStatus = true;
        response.rangeDenominationStatus = true;
    } else if (apiId == 5 && front == 0 && denom?.length < 1) {
        response.rangeDenominationStatus = true;
        response.outOfStockStatus = false;
    } else if (apiId !== 5 && front == 0 && denom?.length > 0) {
        response.outOfStockStatus = false;
        response.rangeDenominationStatus = false;
    }
    else if (apiId !== 5 && front !== 0) {
        response.outOfStockStatus = true;
        response.rangeDenominationStatus = false;
    }
    else if (apiId !== 5 && front == 0 && denom?.length < 1) {
        response.outOfStockStatus = true;
        response.rangeDenominationStatus = false;
    }

    return response;



    // let response = {
    //     "outOfStockStatus": false,
    //     "rangeDenominationStatus": false
    // }

    // if (apiId == 5 && front !== 0) {
    //     response.outOfStockStatus = true;
    //     response.rangeDenominationStatus = true;
    // } else if (apiId == 5 && front == 0 && denom?.length < 1) {
    //     response.rangeDenominationStatus = true;
    //     response.outOfStockStatus = false;
    // } else if (apiId !== 5 && front == 0 && denom?.length > 0) {
    //     response.outOfStockStatus = false;
    //     response.rangeDenominationStatus = false;
    // }
    // else if (apiId !== 5 && front !== 0) {
    //     response.outOfStockStatus = true;
    //     response.rangeDenominationStatus = false;
    // }
    // else if (apiId !== 5 && front == 0 && denom?.length < 1) {
    //     response.outOfStockStatus = true;
    //     response.rangeDenominationStatus = false;
    // }

    // return response;
    /**
     * denomination.api_id == 5 && denomination.front == 0 => Card Range
     * denomination.api_id == 5 &&  denomination.front !== 0 => Currently Out Of Stock
     * denomination.api_id !== 5 && denomination.front !== 0 => Currently Out Of Stock
     * denomination.api_id !== 5 && denomination.front === 0 => Currently Out Of Stock
     **/
}

export const isProductLive = (flag) => {
    if (parseInt(flag) !== 0) {
        return true;
    }
    return false;
}

export const fetchProductById = async (urlId) => {
    try {
        const productApiResponse = await getProductDetails(urlId);
        if (productApiResponse?.['status'] && productApiResponse?.['statusCode']) {
            return productApiResponse.data;
        }
    } catch (error) {

    }
}

export const isDenominationAvailable = (denoData) => {
    let response = {
        "isDenominationAvailableStatus": true
    }
    if (!denoData?.length) {
        response.isDenominationAvailableStatus = false
    }
    return response;
}

export const createDenominationData = (data) => {
    const denominationData = data?.denomination;
    const discount = data.discount;
    const newDenoData = denominationData.map((item) => {
        return {
            AMOUNT: item?.amount,
            DISCOUNT: discount,
            QUANTITY: 0,
            TOTAL: 0,
            PRODUCT_CODE: item?.PRODUCTCODE,
            PRODUCT_GUID: item?.ProductGuid,
            PRODUCT_ID: item?.product_id,
            SUB_PRODUCT_ID: item?.subproduct_id,
            SKU_ID: item?.skuID
        }
    });
    return newDenoData;
}