import React from 'react'
import { Backdrop, Box } from '../../../imports/muiImport';
import CircularProgress from '@mui/material/CircularProgress';


function MobileLoading() {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                backgroundColor: "#fff",
            }}
        >
            <CircularProgress color="inherit" />
        </Box>
    )
}

export default MobileLoading