import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onFetchProducts } from '../AllProducts/action';
import { onFetchCategory } from './action';

export default function FetchCategory() {
    const dispatch = useDispatch();
    const AllCategory = useSelector((state) => state.Category.allData)

    useEffect(() => {
        if (!AllCategory.length > 0) {
            dispatch(onFetchCategory());
        }
    }, [dispatch, AllCategory.length]);
}