import React from 'react';
import { Chip } from '@mui/material';

const OrderStatusChip = ({ value }) => {
    const label = value === 'Debit' ? 'Debit' : value;
    const color = value === 'Debit' ? 'error' : 'success';

    return (
        <Chip label={label} variant="filled" size="medium" color={color} sx={{ px: "6px" }} />
    );
};

export default OrderStatusChip;
