import React, { useState } from 'react';
import {
    Typography,
    Box,
    Button,
    TextField,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    InputAdornment,
    Backdrop,
} from "../../../imports/muiImport";
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { handleChangePassword } from '../../../pages/profileDashboard/changePassword/api';
import { actionNotifier } from '../../../components/tostify';
import CircularProgress from '@mui/material/CircularProgress';

function MobilePasswordChangeForm({ handleClose }) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);

    const handleToggleShowPassword = () => setShowPassword(!showPassword);

    const handleUpdate = (e) => {
        e.preventDefault();
        setPasswordChangeLoading(true);
        const validationErrors = {};

        if (!currentPassword) {
            validationErrors.currentPassword = "Current password is required.";
        }

        if (!newPassword) {
            validationErrors.newPassword = "New password is required.";
        } else if (newPassword.length < 8) {
            validationErrors.newPassword = "Password must be at least 8 characters.";
        }

        if (!confirmPassword) {
            validationErrors.confirmPassword = "Please confirm your new password.";
        } else if (newPassword !== confirmPassword) {
            validationErrors.confirmPassword = "Passwords do not match.";
        }

        setErrors(validationErrors);
        setPasswordChangeLoading(false);

        if (Object.keys(validationErrors).length === 0) {
            // Proceed with the password update logic
            // handleClose();
            const data = {
                new_password: newPassword,
                old_password: currentPassword
            };
            handelPasswordUpdate(data);
        }
    };

    return (
        <>
            <Box
                component={'form'}
                method='POST'
                onSubmit={handleUpdate}
                sx={{
                    bgcolor: '#eeebf4',
                    borderRadius: 2,
                    boxShadow: 24,
                    pb: 4,
                    minHeight: 400,
                }}
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    sx={{
                        mb: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <LockIcon
                            sx={{
                                fontSize: 32,
                                color: '#7357b2',
                                bgcolor: 'white.main',
                                borderRadius: '50%',
                                p: 1,
                                mb: 1,
                                boxShadow: 4,
                            }}
                        />
                        <Typography
                            id="change-password-title"
                            variant="subtitle1"
                            textAlign="center"
                            gutterBottom
                            sx={{
                                color: "primary.main"
                            }}
                        >
                            Change Password
                        </Typography>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    {/* Current Password Field */}
                    <TextField
                        fullWidth
                        label="Current Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        error={!!errors.currentPassword}
                        helperText={errors.currentPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleToggleShowPassword} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/* New Password Field */}
                    <TextField
                        fullWidth
                        label="New Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword}
                        sx={{ my: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleToggleShowPassword} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/* Confirm New Password Field */}
                    <TextField
                        fullWidth
                        label="Confirm New Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleToggleShowPassword} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>

                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        px: 3
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        size='large'
                        fullWidth
                        sx={{
                            bgcolor: 'white.main',
                            color: '#401a96',
                            textTransform: "capitalize",
                            ':hover': { bgcolor: '#e0e0e0' },
                            borderRadius: 2
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type='submit'
                        size='large'
                        fullWidth
                        sx={{
                            bgcolor: 'primary.main',
                            color: 'white.main',
                            textTransform: "capitalize",
                            borderRadius: 2
                        }}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: 999 }}
                open={passwordChangeLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>


    );



    async function handelPasswordUpdate(data) {
        try {
            const passwordChangedResponse = await handleChangePassword(data);
            if (passwordChangedResponse?.status) {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                actionNotifier.success(passwordChangedResponse?.data?.message, { position: "top-left", autoClose: 3500 });
            } else {
                actionNotifier.error(passwordChangedResponse?.message, { position: "top-left", autoClose: 3500 });
            }
            setPasswordChangeLoading(false);
            handleClose();
        } catch (error) {
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            actionNotifier.error("Something went wrong! Please Try Later", { position: "top-left", autoClose: 3500 });
            setPasswordChangeLoading(false);
            handleClose();
        }
    }
}

export default MobilePasswordChangeForm;