import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

const Timer = ({ initialTime, timerStyle }) => {
    const [time, setTime] = useState(initialTime);

    useEffect(() => {
        setTime(initialTime);

        const interval = setInterval(() => {
            setTime(prevTime => {
                if (prevTime <= 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [initialTime]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <Typography
            variant="subtitle2"
            sx={{ fontSize: "10px", color: "error.main", ...timerStyle }}
        >
            Remaining Time: {formatTime(time)}
        </Typography>
    );
};

export default Timer;
