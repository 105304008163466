import { React, useEffect, useState, useSelector, Fragment, memo } from "../../../imports/generalImport";
import { Card, CardMedia, CardContent, Typography, Box, Divider, Chip } from "../../../imports/muiImport";
import { dateChanger } from '../../../shared/extra';
import MobileVouchersViewButton from './MobileVouchersViewButton';

function MobileOrderCard({ ordersData, showVoucher = false }) {
    const allProducts = useSelector((state) => state.AllProducts.allData);

    const getProductImage = (id) => {
        if (allProducts?.length > 0) {
            const data = allProducts?.find(item => {
                if (item?.id == id) {
                    return item;
                }
            });
            return data;
        }
    }

    const getStatusColorCode = (status) => {
        switch (status) {
            case 'Failure':
                return {
                    color: "#fb0000cf",
                    backgroundColor: "#ff000045",
                    boxShadow: "#ff000045"
                };
            case 'Success':
                return {
                    color: "#034818",
                    backgroundColor: "#a6f5be",
                    boxShadow: "#0d5523",
                };
            default:
                return {
                    color: "#fff",
                    // backgroundColor: "#0080a6",
                    // boxShadow: "#c7f2ff"
                    backgroundColor: "#FF9843",
                    boxShadow: "#FDFFAB"
                };
        }
    };

    if (ordersData?.length >= 1) {
        return (
            <Fragment>
                {ordersData?.map((item) => {
                    const statusColorCode = getStatusColorCode(item?.status?.status);
                    return (
                        <Card
                            key={`orderId-${item?.id}`}
                            sx={{
                                display: 'flex', flexDirection: "column", py: 1,
                                boxShadow: 4,
                                borderRadius: 3,
                                mb: 3,
                            }}>
                            <Box sx={{ display: 'flex', flexDirection: "row", px: 1 }}>
                                <CardMedia component="img"
                                    loading='lazy'
                                    sx={{
                                        width: 90,
                                        height: 60,
                                        borderRadius: 2,
                                        boxShadow: 6
                                    }}
                                    image={item?.product?.image}
                                    alt={`${item?.product?.title}-image`}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto', py: 0 }}>
                                        <Typography component="div" variant="subtitle1" sx={{
                                            color: "textprimary.main",
                                            fontWeight: 400,
                                        }}>
                                            Order Id : <Typography component="span" variant="subtitle1" sx={{
                                                color: "secondary.main",
                                                fontWeight: 500,
                                            }}>{item?.id}</Typography>
                                        </Typography>
                                        <Typography variant="subtitle1" component="div" sx={{
                                            fontWeight: 500,
                                            color: "primary.main",
                                        }}>
                                            {item?.product?.title}
                                        </Typography>
                                        <Typography variant="subtitle1" color="primary.main" component="div">
                                            Quantity : <Typography component="span" variant="subtitle1" sx={{
                                                color: "secondary.main",
                                                fontWeight: 500,
                                            }}>{item?.qty}</Typography>
                                        </Typography>
                                    </CardContent>
                                </Box>
                                {showVoucher && <MobileVouchersViewButton voucherData={item} />}
                            </Box>

                            <Divider sx={{
                                borderStyle: "dashed",
                                borderColor: "primary.main",
                            }} />

                            <Box sx={{
                                display: 'flex',
                                flexDirection: "row",
                                justifyContent: "space-between",
                                px: "4px",
                                mt: 1,
                            }}>
                                <Chip label={`${dateChanger(item?.created_at)}`}
                                    sx={{
                                        color: "primary.main",
                                        backgroundColor: "#d3eff7",
                                        fontWeight: 500,
                                        fontSize: "13.5px",
                                        borderRadius: 2,
                                        boxShadow: "inset 0 0 10px #def8ff, 0 0 10px #def8ff",
                                    }} />

                                <Divider orientation='vertical' sx={{
                                    height: 32
                                }} />

                                <Chip label={`₹${item?.amount}`}
                                    sx={{
                                        color: "primary.main",
                                        backgroundColor: "#deebff",
                                        fontWeight: 600,
                                        fontSize: "13.5px",
                                        borderRadius: 2,
                                        boxShadow: "inset 0 0 10px #deebff, 0 0 10px #deebff",
                                        px: "4px",
                                    }} />

                                <Divider orientation='vertical' sx={{
                                    height: 32
                                }} />

                                <Chip label={item?.status?.status}
                                    sx={{
                                        color: statusColorCode.color,
                                        backgroundColor: statusColorCode.backgroundColor,
                                        fontWeight: 600,
                                        fontSize: "11.5px",
                                        borderRadius: 2,
                                        boxShadow: `inset 0 0 10px ${statusColorCode.boxShadow}, 0 0 10px #F8EDED`,
                                        px: "4px",
                                    }} />
                            </Box>
                        </Card>
                    );
                })}
            </Fragment>
        );
    }
    else {
        return (
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                backgroundColor: "#fff",
                color: "primary.main",
                fontWeight: 600,
                fontSize: "1.2rem",
            }}>
                No Orders Data Found
            </Box>
        );
    }
}

export default memo(MobileOrderCard);