import React from 'react';
import { Box } from "../../../imports/muiImport";
import { christmasProductSantaIcon } from "../../../imports/generalImport";

function MobileProductDetailImage({ image }) {
    return (
        <Box
            component={'img'}
            src={image}
            width={312}
            height={160}
            sx={{
                maxWidth: "75vw",
                boxShadow: 4,
                borderRadius: 4,
                objectFit: 'cover',
            }} />
    )
}

export default MobileProductDetailImage;