export const isValidName = (name, min = 4, max = 20) => {
    if (typeof name !== 'string') {
        return { status: false, message: 'Name must be a string.' };
    }

    const trimmedName = name.trim();
    const nameLength = trimmedName.length;

    if (nameLength < min || nameLength > max) {
        return { status: false, message: `Name must be between ${min} and ${max} characters long.` };
    }

    // Check if name contains digits
    if (/\d/.test(trimmedName)) {
        return { status: false, message: 'Name cannot contain digits.' };
    }

    return { status: true };
}

export const validateEmail = (email) => {
    if (typeof email !== 'string') {
        return { status: false, message: 'Email must be a string.' };
    }

    const trimmedEmail = email.trim();
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!pattern.test(trimmedEmail)) {
        return { status: false, message: 'Please enter a valid email address.' };
    }
    return { status: true }
}

export const validatePhone = (phone) => {
    if (typeof phone !== 'string') {
        return { status: false, message: 'Phone must be a string.' };
    }

    const trimmedPhone = phone.trim();
    const pattern = /^\d{10}$/;
    if (!pattern.test(trimmedPhone)) {
        return { status: false, message: 'Please enter a valid 10-digit phone number.' };
    }
    return { status: true }
}

export const validateMessage = (message, minLength = 15, maxLength = 1000) => {
    if (typeof message !== 'string') {
        return { status: false, message: 'Message must be a string.' };
    }

    const trimmedMessage = message.trim();
    const messageLength = trimmedMessage.length;

    if (messageLength < minLength || messageLength > maxLength) {
        return { status: false, message: `Message must be between ${minLength} and ${maxLength} characters long.` };
    }
    return { status: true }
}

export function validateCompanyName(company, minLength = 3, maxLength = 100) {
    const trimmedCompany = company.trim();
    const companyLength = trimmedCompany.length;
    if (companyLength < minLength || companyLength > maxLength) {
        return { status: false, message: `Company name must be between ${minLength} and ${maxLength} characters long.` };
    }
    return { status: true };
}

export const isNumericInput = (inputValue) => {
    // Remove leading and trailing whitespace
    const trimmedValue = inputValue.trim();

    // Check if the trimmed value is empty
    if (trimmedValue === '') {
        return false;
    }

    // Check each character of the input value
    for (let i = 0; i < trimmedValue.length; i++) {
        const charCode = trimmedValue.charCodeAt(i);
        // Check if the character is not a digit (0-9)
        if (!(charCode >= 48 && charCode <= 57)) {
            return false;
        }
    }

    // All characters are digits, so return true
    return true;
}