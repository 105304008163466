import React from 'react'
import { useEffect, useNavigate, useDispatch, useSelector } from "../../imports/generalImport";
import { Container, Card, CardHeader, CardContent, Typography, Box, Divider, Button, Chip } from "../../imports/muiImport";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OrderStatusChip from '../../pages/profileDashboard/statement/OrderStatusChip';
import { dateChanger } from '../../shared/extra';
import moment from 'moment';
import { dateSlashFormant, getLastMonthDate } from '../../shared/dateFunction';
import { onFetchUserStatement } from '../../store/userStatement/action';
import TableFilter from "../../components/tableFilter";
import { Helmet } from 'react-helmet-async';


const momentDate = moment();

const initialData = {
    search: null,
    filterBy: null,
    date: { from: getLastMonthDate(), to: dateSlashFormant(momentDate) },
    pagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 999999,
        rowsNumber: 0,
    },
    status: 0,
};

function MobileUserTransactions() {
    const UserStatementData = useSelector((state) => state?.UserStatement?.allData);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(onFetchUserStatement(initialData));
    }, [dispatch]);

    return (
        <Container sx={{ pt: 2, bgcolor: "#fefafe" }}>
            <Helmet>
                <title>Profile Statement -99gift.in</title>
                <meta property="og:title" content="Profile Statement -99gift.in" />
            </Helmet>

            <TableFilter initialData={initialData} reducer={onFetchUserStatement} />

            {UserStatementData?.map((item) => (
                <Card
                    key={`statement-${item?.id}`}
                    sx={{ maxWidth: "100%", borderRadius: 2, boxShadow: 0, mt: 3, mb: 4, border: 1, borderColor: "GrayText" }}
                >
                    {/* Card Header for the Date */}
                    <CardHeader
                        sx={{
                            padding: 1,
                            backgroundColor: "lightSecondary.main", // Change as per your theme
                            textAlign: 'center',
                            // borderBottom: '1px solid #e0e0e0'
                        }}
                        title={
                            <Typography variant="h6" color="primary" fontWeight={400}>
                                {dateChanger(item?.created_at)}
                            </Typography>
                        }
                    />
                    {/* Card Content for the Order Details */}
                    <CardContent sx={{ padding: 2 }}>
                        {/* Order Details */}
                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Typography variant="subtitle1" color="textPrimary.main">Order</Typography>
                            <Typography variant="subtitle2" color="secondary.main" fontSize={11.5}>{item?.id}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Typography variant="subtitle1" color="textPrimary.main">Remark</Typography>
                            <Typography variant="subtitle2" color="primary.main" fontSize={11.5}>{item?.remark}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Typography variant="subtitle1" color="textPrimary.main">Description</Typography>
                            <Typography variant="subtitle2" color="primary.main" fontSize={11.5}>{item?.description}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={1}>
                            <Typography variant="subtitle1" color="textPrimary.main">Amount</Typography>
                            <Typography variant="subtitle2" color="success.main" fontSize={11.5}>₹{item?.amount}</Typography>
                        </Box>
                        <Divider sx={{ borderStyle: 'dashed', borderWidth: 1, mb: 1, borderColor: "dark.main" }} />
                        {/* Status Button */}
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            alignItems: "center"
                        }} >
                            <Typography
                                variant="subtitle1"
                                color="primary.main"
                                sx={{
                                    fontWeight: 600
                                }}
                            >
                                Status
                            </Typography>

                            <OrderStatusChip value={item?.status?.status} />
                        </Box>
                    </CardContent>
                </Card>
            ))}

        </Container>
    )
}

export default MobileUserTransactions