import React, { useState } from 'react';
import { Box, Typography, Button } from "../../../imports/muiImport";

function ProductDetailPara({ productDescription }) {
    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(prevShowMore => !prevShowMore);
    };

    return (
        <Box sx={{ maxWidth: "90%" }}>
            <Typography
                component={'p'}
                sx={{
                    color: "textPrimary.main",
                    fontWeight: 500,
                    wordSpacing: "1px",
                    wordWrap: "break-word",
                    fontSize: "1.2rem",
                    lineHeight: 1.5,
                    margin: "0 0 1em 0",
                    letterSpacing: "0.5px",
                    overflowWrap: "anywhere",
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: showMore ? 'none' : 4, // Show more lines when button is clicked
                    overflow: 'hidden',
                }}
            >
                {productDescription}
            </Typography>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "primaryDark.main",
                boxShadow: 6,
                borderRadius: 1,
            }}>
                {productDescription?.split(' ').length > 25 && ( // Assuming approx. 15 words fit into 2 lines
                    <Button variant='text' onClick={handleToggle} sx={{
                        color: "white.main",
                    }}>
                        {showMore ? 'Show Less' : 'Show More'}
                    </Button>
                )}
            </Box>
        </Box>
    );
}

export default ProductDetailPara;
