import React from 'react'
import { Box, Chip, ListItem, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { titleToUrl } from '../../../shared/extra';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function SingleSearchResult(props) {
    const { id, title, image, discount} = props.item;
    return (
        <NavLink
            to={`card-details/${id}/${titleToUrl(title)}`}
            style={{
                display: "flex",
                justifyContent: "center",
                textDecoration: "none",
            }}
        >
            <ListItem
                sx={{
                    "&:hover": {
                        backgroundColor: "#ebeef5"
                    },
                    display: "flex",
                    justifyContent: {
                        sm: "flex-start",
                        md: "space-between",
                        lg: "space-between"
                    },
                    flexDirection: {
                        sm: "column",
                        md: "row",
                        lg: "row"
                    },
                    alignItems: {
                        sm: "flex-start",
                        md: "center",
                        lg: "center",
                    },
                }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: {
                        sm: "center",
                        lg: "center"
                    },
                    mb: {
                        sm: 1,
                        lg: 0
                    }
                }}>


                    <Box
                        component="img"
                        src={image}
                        sx={{
                            width: {
                                sm: "80px",
                                xl: "70px"
                            },
                            height: {
                                sm: "45px",
                                xl: "45px"
                            },
                            borderRadius: 1
                        }}
                    />

                    <Typography
                        variant="subtitle1"
                        sx={{
                            ml: {
                                sm: 2,
                                xl: "15px"
                            },
                            fontSize: {
                                sm: "12px",
                                md: "14px",
                                xl: "13px"
                            },
                            lineHeight: 1,
                            fontWeight: {
                                sm: 400,
                                xl: 500
                            },
                            py: {
                                xl: 1
                            },
                            color: {
                                sm: "dark.main",
                                xl: "primary.main"
                            },
                            textTransform: {
                                xl: "capitalize !important"
                            }


                        }}
                    >
                        {title}
                    </Typography>
                </Box>

                <Box sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: {
                        xl: "205px"
                    }
                }}>
                    <Chip label={`${discount} %`}
                        size="small"
                        variant="filled"
                        sx={{
                            backgroundColor: "primary.main",
                            fontWeight: 500,
                            color: "white.main",
                            minWidth: "45px",
                            fontSize: {
                                xl: "13px"
                            },
                            textAlign: "left"
                        }}
                    />
                </Box>
            </ListItem>
        </NavLink>
    );



    function isStoreRedeem(redeem) {
        if (redeem) {
            return <Chip key={'storeTrue'} icon={<CheckCircleIcon sx={{
                color: "#2ab638 !important"
            }}
            />} size="small" label="Store" variant="filled" sx={{
                mx: { sm: 1, lg: "5px" },
                color: "#0fab22",
                backgroundColor: "#caf6be",
                fontSize: { lg: "1rem" },
                fontWeight: { lg: 500 },
            }} />
        } else {
            return <Chip icon={<CancelIcon sx={{
                color: "#ea0c0c !important"
            }} />}
                size="small"
                label="Store" variant="filled" sx={{
                    mx: { sm: 1, lg: "5px" },
                    color: "#ff2121",
                    backgroundColor: "#f6bebe",
                    fontSize: { lg: "1rem" },
                    fontWeight: { lg: 500 },
                }} />
        }
    }

    function isOnlineRedeem(redeem) {

        if (redeem) {
            return <Chip icon={<CheckCircleIcon sx={{
                color: "#2ab638 !important"
            }}
            />} size="small" label="Online" variant="filled" sx={{
                mx: { sm: 1, lg: "5px" },
                color: "#0fab22",
                backgroundColor: "#caf6be",
                fontSize: { lg: "1rem" },
                fontWeight: { lg: 500 },
            }} />
        }
        else {
            return <Chip icon={<CancelIcon sx={{
                color: "#ea0c0c !important"
            }} />} size="small" label="Online" variant="filled" sx={{
                mx: { sm: 1, lg: "5px" },
                color: "#ff2121",
                backgroundColor: "#f6bebe",
                fontSize: { lg: "1rem" },
                fontWeight: { lg: 500 },
            }} />
        }
    }

}