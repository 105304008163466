import { React, Fragment, useState, useSelector, useDispatch } from '../../../imports/generalImport';
import { Container, IconButton, TableContainer, Table, TableRow, TableCell, TableBody, TableHead, Snackbar } from '../../../imports/muiImport';
import MobileTableDenominationBody from './MobileTableDenominationBody';
import CloseIcon from '@mui/icons-material/Close';
import '../../../pages/PRODUCTDETAILS/styles/productDenomination.css';


const MobileDenominationTable = (props) => {
    const { rangeDenomination } = props;
    const DENOMINATION_STORE_DATA = useSelector((state) => state.Denominations?.allData);
    const [snackbarData, setSnackbarData] = useState({
        open: false,
        message: ''
    });
    const handleClose = () => {
        setSnackbarData({ open: false, message: '' });
    };

    return (
        <Container disableGutters>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                open={snackbarData?.open}
                onClose={handleClose}
                message={snackbarData?.message}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                sx={{
                    '.MuiSnackbarContent-root': {
                        backgroundColor: 'error.main',
                        fontSize: '13.5px',
                        fontWeight: 400,
                    },
                }}
            />

            <TableContainer sx={{
                maxWidth: "100%",
                minHeight: '170px',
                px: 0,
                py: 0,
                maxHeight: '340px',
                overflowY: 'auto',

            }}>
                <Table
                    sx={{
                        minHeight: 30,
                        minWidth: "100%",
                        maxWidth: '100%',
                        px: 0,
                        py: 0,
                    }}>
                    <TableHead px={0}>
                        <TableRow className='header'>
                            <TableCell align="center"
                                sx={{
                                    fontSize: { xs: "11.5px", sm: "1rem", lg: "1rem" },
                                    textTransform: { xs: "capitalize", sm: "capitalize", lg: "capitalize", },
                                    color: { xs: "textPrimary.main", sm: "textPrimary.main" },
                                    px: { xs: 0, },
                                    fontWeight: { xs: 500, sm: 500, lg: 500 }
                                }}
                            >Amount</TableCell>

                            <TableCell align="center"
                                sx={{
                                    fontSize: { xs: "11.5px", sm: "1rem", lg: "1rem" },
                                    textTransform: { xs: "capitalize", sm: "capitalize", lg: "capitalize", },
                                    color: { xs: "textPrimary.main", sm: "textPrimary.main" },
                                    px: { xs: 0, },
                                    fontWeight: { xs: 500, sm: 500, lg: 500 }
                                }}
                            >Discount</TableCell>

                            <TableCell align="center"
                                sx={{
                                    fontSize: { xs: "11.5px", sm: "1rem", lg: "1rem" },
                                    textTransform: { xs: "capitalize", sm: "capitalize", lg: "capitalize", },
                                    color: { xs: "textPrimary.main", sm: "textPrimary.main" },
                                    px: { xs: 0, },
                                    fontWeight: { xs: 500, sm: 500, lg: 500 }
                                }}
                            >Quantity</TableCell>

                            <TableCell align="center"
                                sx={{
                                    fontSize: { xs: "11.5px", sm: "1rem", lg: "1rem" },
                                    textTransform: { xs: "capitalize", sm: "capitalize", lg: "capitalize", },
                                    color: { xs: "textPrimary.main", sm: "textPrimary.main" },
                                    px: { xs: 0, },
                                    fontWeight: { xs: 500, sm: 500, lg: 500 }
                                }}
                            >Total</TableCell>

                            {rangeDenomination &&
                                <TableCell align="center"
                                    sx={{
                                        fontSize: { xs: "11.5px", sm: "1rem", lg: "1rem" },
                                        textTransform: { xs: "capitalize", sm: "capitalize", lg: "capitalize", },
                                        color: { xs: "textPrimary.main", sm: "textPrimary.main" },
                                        px: { xs: 0, },
                                        fontWeight: { xs: 500, sm: 500, lg: 500 }
                                    }}
                                >Action</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {DENOMINATION_STORE_DATA?.map((data) => (
                            <Fragment key={`mobile-denomination-list${data?.AMOUNT}`}>
                                <TableRow sx={{ overflow: "hidden" }}>
                                    <MobileTableDenominationBody
                                        data={data}
                                        snackbar={snackbarData}
                                        setSnackbar={setSnackbarData}
                                        handleClose={handleClose}
                                        rangeDenomination={rangeDenomination}
                                    />
                                </TableRow>
                            </Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container >
    );
};
export default MobileDenominationTable;


