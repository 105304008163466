import React, { useEffect, useState, useMemo } from "react";
import Navbar from "./Navbar";
import NavbarMenu from "./innerComponent/NavbarMenu";
import { useLocation } from "react-router-dom";
import { useAuth } from "../hooks/contexts/AuthContext";

const Header = () => {
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = useState();
  const [textColor, setTextColor] = useState();
  const [homeBackgroundColor, setHomeBackgroundColor] = useState();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    // Determine background colors based on the location
    if (location.pathname === "/") {
      setBackgroundColor("dark.main2");
      setTextColor("white.main");
      setHomeBackgroundColor("secondary.main");
    } else {
      setBackgroundColor("secondary.main");
      setTextColor("primary.main");
      setHomeBackgroundColor("primary.main");
    }
  }, [isLoggedIn, location]);


  const memoizedNavbarMenu = useMemo(() => (
    <NavbarMenu
      backgroundColor={backgroundColor}
      textColor={textColor}
      homeColor={homeBackgroundColor}
    />
  ), [backgroundColor, textColor, homeBackgroundColor]);

  return (
    <React.Fragment>
      <Navbar />
      {memoizedNavbarMenu}
    </React.Fragment>
  );
};

export default Header;
