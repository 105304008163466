import { userComplainQuery } from "../../../apiCalls/urls/urlFunction";
import { actionNotifier } from "../../../components/tostify";
import { useAuth } from "../../../hooks/contexts/AuthContext";
import { Fragment, React, useSelector, useState } from "../../../imports/generalImport";
import { Box, TextField, Button, Backdrop } from "../../../imports/muiImport";
import CircularProgress from '@mui/material/CircularProgress';

function MobileContactForm() {
    const userProfile = useSelector((state) => state.UserProfile?.allData?.data);
    const {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    } = useAuth();
    // State for form input and validation
    const [query, setQuery] = useState('');
    const [error, setError] = useState('');
    const [queryLoading, setQueryLoading] = useState(false);

    // Handle text field input change
    const handleInputChange = (input) => {
        setQuery(input);
        if (input.trim() === '') {
            setError('First input your Query!');
        } else {
            setError('');
        }
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        setQueryLoading(true);
        if (query.trim() === '') {
            setError('First input your Query!');
        } else {
            setError('');
            setQuery('');
            userQuerySubmit(query, userProfile);
        }
    };

    if (isLoggedIn) {
        return (
            <Fragment>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    method="POST"
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}
                >
                    {/* Buttons */}
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: "repeat(2, 1fr)",
                            rowGap: 2,
                            columnGap: 2,
                            mb: 4
                        }}
                    >
                        {[
                            'How to redeem our coupon',
                            'Claiming Process',
                            'Where can I see my gift card balance',
                            'Where is my referral code',
                            'GV showing already redeemed',
                            'Payment done but GV not showing'
                        ].map((label, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                sx={{
                                    px: 2,
                                    fontSize: "10.5px",
                                    color: "textPrimary.main",
                                    textTransform: "capitalize",
                                    fontWeight: 400,
                                    height: 40,
                                }}
                                onClick={() => handleInputChange(label)}
                            >
                                {label}
                            </Button>
                        ))}
                    </Box>

                    {/* Text Field */}
                    <TextField
                        multiline
                        rows={9}
                        placeholder="Type your query here..."
                        variant="outlined"
                        value={query}
                        onChange={(e) => handleInputChange(e.target.value)}
                        error={Boolean(error)}
                        helperText={error}
                        sx={{
                            width: '100%',
                            mb: 3,
                            border: 1,
                            borderColor: "secondary.main",
                            borderRadius: 2,
                        }}
                    />

                    {/* Submit Button */}
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="secondary"
                        fullWidth
                        sx={{
                            py: 2,
                            color: "white.main",
                            textTransform: "capitalize",
                        }}
                    >
                        Submit
                    </Button>
                </Box>

                <Backdrop
                    sx={{ color: '#fff', zIndex: 999 }}
                    open={queryLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Fragment>
        );
    } else {
        return null;
    }

    async function userQuerySubmit(userQuery, userInfo) {
        const payload = {
            "name": userInfo?.name,
            "email": userInfo?.email,
            "token": userInfo?.token,
            "yourquery": userQuery
        }
        const userQueryResponse = await userComplainQuery(payload);

        if (userQueryResponse?.status) {
            setQueryLoading(false);
            actionNotifier.success(userQueryResponse?.data?.message, { position: "top-left", autoClose: 3500 });

        } else {
            setQueryLoading(false);
            actionNotifier.success(userQueryResponse?.message, { position: "top-left", autoClose: 3500 });
        }
    }
}

export default MobileContactForm;