import React from 'react';
import { Container, Typography, Box, IconButton, divider, Divider } from '../../../imports/muiImport';
import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { handleAmount } from '../../../shared/extra';
import { useDispatch, useSelector } from 'react-redux';
import { removeAnDenomination } from '../../../store/productDetails/action';
import QuantityHandle from '../../../pages/PRODUCTDETAILS/productUtils/QuantityHandle';


function MobileTableDenominationBody(props) {
    const { data, rangeDenomination = true, snackbar, setSnackbar, handleClose } = props;
    const dispatch = useDispatch();

    return (
        <>
            <TableCell component="th" scope="row" align="center" sx={{
                maxWidth: "40px",
                fontSize: { xs: "11.5px", sm: ".9rem", lg: "1rem" },
                color: "textPrimary.main1",
                fontWeight: { xs: 500, }
            }}> {handleAmount(data.AMOUNT)}</TableCell>
            <TableCell align="center" sx={{
                maxWidth: "40px",
                fontSize: { xs: "11.5px", sm: ".9rem", lg: "1rem" },
                color: "textPrimary.main1",
                fontWeight: { xs: 500, }
            }}>{data.DISCOUNT}%</TableCell>

            <TableCell align="center" sx={{ maxWidth: "100px" }}>
                <Box className='product__denomination_table_quantity'>
                    <QuantityHandle
                        denominationData={data}
                        rangeDenomination={rangeDenomination}
                    />
                </Box>
            </TableCell>

            <TableCell align="center" sx={{
                maxWidth: "40px",
                fontSize: { xs: "11.5px", sm: ".9rem", lg: "1rem" },
                color: "textPrimary.main1",
                fontWeight: { xs: 500, }
            }}>{handleAmount(data.TOTAL)}</TableCell>

            {rangeDenomination &&
                <TableCell align="center" sx={{
                    maxWidth: "40px",
                }}>
                    <IconButton
                        onClick={() => removeDenoRow(data.AMOUNT)}
                        aria-label="delete-denomination-row"
                        color="error"
                        sx={{
                            "&: hover": {
                                backgroundColor: "rgb(246 146 146 / 30%)"
                            }
                        }}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            }
        </>
    );

    function removeDenoRow(amt) {
        setSnackbar({
            open: true,
            message: `₹${amt} Amount Denomination is Deleted!`
        })

        setTimeout(() => {
            handleClose();
        }, 800);
        dispatch(removeAnDenomination(amt))
    }

}

export default MobileTableDenominationBody