import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import { custom99GiftTheme } from "./shared/theme";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// import reportWebVitals from './reportWebVitals';
// register Swiper custom elements

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}



register();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <ThemeProvider theme={custom99GiftTheme}>
        <App />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  </HelmetProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);