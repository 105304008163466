import { ToastContainer, toast } from "react-toastify";

const Index = ({ position, autoClose, hideProgressBar, draggable, transition, newestOnTop, closeOnClick, rtl, pauseOnVisibilityChange, pauseOnHover, theme }) => {
    return (
        <ToastContainer
            position={position || "top-left"}
            autoClose={autoClose || 2000}
            hideProgressBar={hideProgressBar || false}
            newestOnTop={newestOnTop || true}
            closeOnClick={closeOnClick || true}
            rtl={rtl || false}
            pauseOnVisibilityChange={pauseOnVisibilityChange || false}
            draggable={draggable || false}
            pauseOnHover={pauseOnHover || true}
            theme={theme || "light"}
            transition={transition || "Flip"}
        />
    );
};
export default Index;

export const actionNotifier = {
    success: (message, options) => toast.success(message, options),
    error: (message, options) => toast.error(message, options),
    warning: (message, options) => toast.warn(message, options),
    info: (message, options) => toast.info(message, options),
    clearAll: () => toast.dismiss(),
};
