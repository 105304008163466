import React, { useEffect, useState } from 'react'
import { Button, Container, Typography, Skeleton, SwipeableDrawer, Divider, Box } from '../../../imports/muiImport';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import MobileRangeInput from './MobileRangeInput';
import MobileDenominationTable from './MobileDenominationTable';
import MobileLoading from '../mobileLoading/MobileLoading';
import Payment from '../../../pages/PRODUCTDETAILS/payment/Payment';
import { useSelector } from 'react-redux';
import MobileBuyButton from './MobileBuyButton';

const drawerBleeding = 56;

// Styled components
const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.applyStyles('dark', {
        backgroundColor: grey[800],
    }),
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
    ...theme.applyStyles('dark', {
        backgroundColor: grey[900],
    }),
}));

function MobileGiftCardPurchase(props) {
    const { window, showRangeDenomination } = props;
    const ProductDetail = useSelector((state) => state.ProductDetail?.allData);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(true);


    // Function to toggle drawer open or close
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        setTimeout(() => {
            setLoading(false);
        }, 700);
    };

    // Define container if window prop is provided
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Container sx={{ ".MuiDrawer-root>.MuiPaper-root": { height: "100vh" } }}>
            <Button
                variant="contained"
                fullWidth
                sx={{ textTransform: "capitalize", py: 1 }}
                onClick={toggleDrawer(true)}
            >
                Buy Now
            </Button>

            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={true}
                ModalProps={{ keepMounted: true }}
                sx={{
                    ".MuiDrawer-paper": {
                        borderTopLeftRadius: 24,
                        borderTopRightRadius: 24,
                        height: `calc(75% + ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            >
                <StyledBox sx={{
                    pt: 3,
                    px: 2,
                    pb: 2,
                    height: '100%',
                    overflow: 'auto',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                }}>
                    <Puller />

                    {loading ?
                        (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <MobileLoading />
                            </Box>
                        )
                        :
                        (
                            <>
                                {/* <Typography sx={{ p: 2, color: 'text.secondary', textAlign: "center" }}>Please Select Cards </Typography> */}
                                <MobileRangeInput rangeDenomination={showRangeDenomination} />
                                <Divider sx={{ my: 1 }} />
                                <MobileDenominationTable rangeDenomination={showRangeDenomination} />
                                <Divider sx={{ my: 1 }} />
                                <Payment ProductDiscount={ProductDetail?.discount} productDetail={ProductDetail} />
                            </>
                        )
                    }

                </StyledBox>
            </SwipeableDrawer >
            {/* <Skeleton variant="rectangular" height="100%" /> */}
        </Container >
    );
}

// Define default props
MobileGiftCardPurchase.defaultProps = {
    window: undefined,
};

// Define prop types
MobileGiftCardPurchase.propTypes = {
    window: PropTypes.func,
};

export default MobileGiftCardPurchase;
