import React, { useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    FormHelperText,
    Backdrop,
    FormControl
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginUser, userLoginValidation } from './userAccoountHook';
import { actionNotifier } from '../../../components/tostify';
import CircularProgress from '@mui/material/CircularProgress';
import { validateOtp } from '../../../apiCalls/urls/urlFunction';


export default function MobileLoginForm({ setOTPForm, setUserCredentials }) {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [loginLoading, setLoginLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoginLoading(true);
        const loginResponse = userLoginValidation(phone, password);

        if (!loginResponse?.success) {
            actionNotifier.error(loginResponse?.message, { position: "top-left", autoClose: 3500 });

            setErrors({
                phone: loginResponse?.phone ? loginResponse.message : '',
                password: loginResponse?.password ? loginResponse.message : '',
            });

        } else {
            setErrors({});
            const userLogin = async () => {
                // User Login 
                const userLoginResponse = await loginUser(loginResponse?.data?.mobile, loginResponse?.data?.password);

                if (userLoginResponse?.status) {
                    // User Phone no and Password is correct so verify the otp process
                    const otpSendToUserPhoneResponse = await validateOtp(userLoginResponse?.mobile);
                    if (otpSendToUserPhoneResponse?.status) {
                        if (howManyTimeUserOTPTry() === 1) {
                            actionNotifier.success("we sent OTP to your Phone!", { position: "top-left", autoClose: 3500 });
                        } else {
                            actionNotifier.success(otpSendToUserPhoneResponse?.data?.message, { position: "top-left", autoClose: 3500 });
                        }
                        setOTPForm(true);
                        setLoginLoading(false);
                        setUserCredentials({
                            mobile: userLoginResponse?.mobile,
                            token: userLoginResponse?.token,
                            status: userLoginResponse?.status,
                            isLoginForm: true
                        });
                    } else {
                        actionNotifier.error(otpSendToUserPhoneResponse?.message, { position: "top-left", autoClose: 3500 });
                    }
                } else {
                    // User Phone no and Password is not correct
                    setPassword('');
                    setPhone('');
                    setLoginLoading(false);
                    setUserCredentials({
                        mobile: null,
                        token: null,
                        status: false
                    });
                }
            }

            userLogin();
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        const cleanedValue = value.replace(/[^\d]/g, ''); // Remove any non-digit characters

        // Update phone state
        setPhone(cleanedValue);

        // Validate the cleaned value
        if (cleanedValue.length !== 10) {
            setErrors({ phone: 'Phone number must be exactly 10 digits!' });
        } else {
            setErrors({ phone: '' }); // Clear error if valid
        }
    };

    return (
        <Box sx={{
            minWidth: '100%'
        }}
        >
            <FormControl
                component="form"
                method='post'
                onSubmit={handleSubmit}
                fullWidth
            >
                <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    value={phone}
                    type='tel'
                    required
                    // onChange={(e) => setPhone(e.target.value)}
                    onChange={handlePhoneChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    margin="normal"
                    InputProps={{
                        inputProps: { maxLength: 10 },
                    }}
                    sx={{
                        backgroundColor: "white.main",
                        border: 0,
                    }}
                />

                <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                    required
                    margin="normal"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        backgroundColor: "white.main",
                        border: 0,
                    }}
                />

                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                        mt: 1,
                        py: "12px",
                        color: "white.main",
                        backgroundColor: "secondary.main",
                        textTransform: "capitalize",
                        textAlign: "center",
                        fontSize: "13.5px",
                        boxShadow: 4,
                        "&: hover": {
                            backgroundColor: "secondary.main",
                        }
                    }}
                >
                    Sign in
                </Button>

                {Object.keys(errors).length > 0 && (
                    <FormHelperText error sx={{ mt: "4px", textAlign: 'left' }}>
                        Please correct the errors above.
                    </FormHelperText>
                )}
            </FormControl>

            <Backdrop
                sx={{ color: '#fff', zIndex: 999 }}
                open={loginLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );

    function howManyTimeUserOTPTry() {
        return 1;
    }
}