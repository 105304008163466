// src/reducer.js
import * as actionTypes from './actionTypes';

const initialState = {
  allData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIZED_PRODUCTS:
      state = {
        ...state,
        allData: action.payload,
      };
      break;
    case actionTypes.REMOVE_CATEGORIZED_PRODUCTS:
      state = { allData: [] }
      break;
    default:
      state = { ...state };
  }
  return state;
};

export default reducer;
