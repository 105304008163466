
import Layout from '../../layouts';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Thankyou = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="false" sx={{
            backgroundColor: "backgroundPrimary.main"
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: {
                    xs: "180px",
                    sm: "200px",
                    lg: "300px",
                }
            }}>
                <Typography sx={{
                    fontSize: {
                        xs: "1.9rem",
                        sm: "2.5rem",
                        lg: "3.5rem",
                    },
                    color: {
                        xs: "textPrimary.main"
                    }
                }}>
                    Thank You for purchasing Gift Card
                </Typography>


                <Button variant="contained" onClick={() => navigate('/')} sx={{ mt: { xs: 3, sm: 4, lg: 2 } }}>Back To Home</Button>

            </Box>
        </Container>
    )
}

export default Thankyou