import { getBestSellers } from "../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";

export function onFetchBestSellerProducts() {
  return async (dispatch) => {
    const bestSellerResponse = await getBestSellers();
    if (bestSellerResponse?.status) {
      dispatch(updateAllProductsReducer(bestSellerResponse?.data));
    } else {
      console.error(bestSellerResponse?.error);
    }
  };
}

export const updateAllProductsReducer = (payload) => {
  return {
    type: actionTypes.UPDATE_BEST_SELLER_PRODUCTS,
    payload
  };
};
