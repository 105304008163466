import { validateToken } from "../../apiCalls/urls/urlFunction";
import { UPDATE_USER_PROFILE } from "./actionTypes";

export function fetchUserProfile() {
    return async (dispatch) => {
        const userResponse = await validateToken();
        if (userResponse?.status) {
            dispatch(updateUserProfile(userResponse?.data));
        } else {
            console.error(userResponse?.error);
        }
    };
}

export function updateUserProfile(payload) {
    return {
        type: UPDATE_USER_PROFILE,
        payload: payload,
    };
}