import React from 'react';
import { useState } from "../../../imports/generalImport";
import { Box, Button, Dialog, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "../../../imports/muiImport";
import ReceiptIcon from '@mui/icons-material/Receipt'; // Import necessary icons
import { dateChanger } from '../../../shared/extra';
import MobileGiftVoucher from './MobileGiftVoucher';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import back arrow icon


function MobileMultipleVoucherData(props) {
    const { voucherData, handleClose, orderData } = props;
    const [open, setOpen] = useState(false);
    const [voucherLoading, setVoucherLoading] = useState(false);
    const [userVoucherData, setUserVoucherData] = useState([]);

    const handleMultipleClickOpen = (voucherData) => {
        setOpen(true);
        setUserVoucherData(voucherData)
    };

    const handleMultipleClose = () => {
        setOpen(false);
    };


    return (
        <Box >
            <Box
                sx={{
                    my: 2,
                    px: 2,
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <IconButton onClick={handleClose} aria-label="back"
                    sx={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                        mr: 2
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
            </Box>
            <Divider />
            <Box sx={{
                maxHeight: "100vh",
                overflowY: "scroll",
            }}>
                <List>
                    {voucherData?.map((voucher) => (
                        <ListItemButton
                            key={voucher.id}
                            onClick={() => handleMultipleClickOpen(voucher)}
                            sx={{
                            }}
                        >
                            <ListItemIcon>
                                <ReceiptIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={`Order ID: ${voucher?.id} - ${orderData?.product?.title}`}
                                secondary={`Date: ${dateChanger(voucher?.EndDate)} | Amount: ₹${voucher?.Value}`}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Box>



            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="change-password-title"
                aria-describedby="change-password-description"
                sx={{
                    "& .MuiDialog-paper": {
                        width: "100%",
                        maxWidth: "none",
                    },
                }}
            >

                <MobileGiftVoucher
                    handleClose={handleMultipleClose}
                    orderData={orderData}
                    voucherData={userVoucherData}
                />

            </Dialog>
        </Box>
    )
}

export default MobileMultipleVoucherData



//     < Dialog
// // fullScreen={false}
// open = { open }
// onClose = { handleClose }
// aria - labelledby="change-password-title"
// aria - describedby="change-password-description"
// sx = {{
//     "& .MuiDialog-paper": {
//         width: "100%",
//             maxWidth: "none",
//                     },
// }}
//             >
//     <MobileGiftVoucher
//         handleClose={handleClose}
//         orderData={orderData}
//         voucherData={userVoucherData[0]}
//     />
//             </Dialog >