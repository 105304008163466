import { Box, Button } from '@mui/material';
import React from 'react';
import { useAuth } from '../hooks/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../apiCalls/functions';
import UserBalance from './innerComponent/UserBalance';
import ProfileNavbar from './innerComponent/ProfileNavbar';

function HandleUserLogin() {
    const navigate = useNavigate();
    const {
        isLoggedIn,
    } = useAuth();

    const handleUserLogin = (e) => {
        e.preventDefault();
        navigate("/login", { replace: true });
    };

    if (isLoggedIn) {
        return (
            <Box
                sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                    alignItems: "center",
                    minWidth: "fit-content",
                    backgroundColor: "white.main",
                    border: 1,
                    borderColor: "lightSecondary.main",
                    py: "2px",
                    borderRadius: 1,
                    mr: { sm: 0, xl: "20px" },
                    maxWidth: 240,
                    px: {
                        sm: 1,
                        lg: 2,  
                    },
                    minHeight: 49,
                    "&:hover": {
                        borderRadius: 1,
                        boxShadow: 1,
                    }
                }}
            >
                <UserBalance />
                <ProfileNavbar />
            </Box>
        );
    } else {
        return (
            <Button
                variant="text"
                sx={{
                    textTransform: "capitalize",
                    px: "10px",
                    display: "inline-block",
                }}
                onClick={handleUserLogin}
            >
                Log in/ Sign up
            </Button>
        );
    }
}

export default HandleUserLogin;
