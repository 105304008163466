import React, { useEffect } from 'react'
import { Box, Typography, FormControl, Button, Backdrop, IconButton } from "../../../imports/muiImport";
import { securityOtp, useLocation, useNavigate, useState } from "../../../imports/generalImport";
import OtpInput from 'react18-input-otp';
import "./MobileOTPStyle.css"
import { handleOtpSubmit, verifyForgotUserOTP, verifyUserOTPForLogin } from './userAccoountHook';
import { actionNotifier } from '../../../components/tostify';
import CircularProgress from '@mui/material/CircularProgress';
import Timer from '../../../pages/LOGIN_SIGNUP/OTP/Timer';
import { validateOtp, verifyForgotUserOtp } from '../../../apiCalls/urls/urlFunction';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function MobileOTPForm({ userCredentials, userLoginStatusSetup, isResendAvailable, setIsResendAvailable, forgotUserPassword, setForgotToken }) {
    const [otp, setOtp] = useState('');
    const [OTPLoading, seOTPLoading] = useState(false);
    const [timer, setTimer] = useState(60);
    const [wrongOTP, setWrongOTP] = useState(3)
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            setIsResendAvailable(true);
        }

        return () => clearInterval(interval);
    }, [timer]);

    const handleChange = (enteredOtp) => {
        // setOtpError(false);
        setOtp(enteredOtp.replace(/\D/g, ''));
    };

    const handleBack = () => {
        seOTPLoading(true);
        setTimeout(() => {
            window.location.reload();
            seOTPLoading(false);
        }, 300);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{
                    mb: 2,
                    display: "flex",
                }}>
                    <IconButton
                        sx={{
                            backgroundColor: "white.main",
                            p: 1,
                            borderRadius: 2,
                            width: 40,
                            height: 40,
                        }}
                        onClick={handleBack}
                    >
                        <ArrowBackIosIcon
                            fontSize="large" />
                    </IconButton>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <Typography variant='subtitle1'
                            sx={{
                                color: "primary.main",
                                fontWeight: 500,
                                fontSize: "18.5px",
                                mb: 1,
                                textAlign: "center",
                            }}
                        >
                            Phone Verification
                        </Typography>

                        <Typography variant='subtitle1'
                            sx={{
                                color: "textPrimary.main",
                                fontWeight: 400,
                                fontSize: "13.5px",
                                mb: 1,
                                textAlign: "center",
                            }}
                        >
                            We have sent code to you Phone :
                            <br />
                            {userCredentials?.mobile}
                        </Typography>

                        <Box component={'img'}
                            src={securityOtp}
                            width={110}
                            sx={{
                                mixBlendMode: "multiply",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>

                    <Box sx={{
                        mt: 4,
                        mb: 3,
                        display: "flex",
                    }}>
                        <FormControl
                            onSubmit={otpVerification}
                            component="form"
                        >
                            <OtpInput
                                id="myInput"
                                className="digitInput"
                                placeholder=""
                                required
                                value={otp}
                                onChange={handleChange}
                                numInputs={6}
                                isSuccessed={false}
                                inputMode="numeric"
                                separateAfter={3}
                                shouldAutoFocus
                                hasErrored={false}
                                errorStyle="error"
                                successStyle="success"
                            />

                            <Box sx={{
                                mt: 2,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <Typography variant='subtitle1'
                                    component={'div'}
                                    sx={{
                                        color: "textPrimary.main",
                                        fontWeight: 400,
                                        fontSize: "13.5px",
                                        mb: 1,
                                        textAlign: "center",
                                    }}
                                >
                                    Don't  receive the code ?

                                    <Button variant='text'
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "13.5px",
                                            color: "secondary.main",
                                            textTransform: "capitalize",
                                        }}
                                        onClick={() => otpResendHandle(userCredentials?.mobile)}
                                        disabled={!isResendAvailable}
                                    >
                                        Resend
                                    </Button>
                                </Typography>
                            </Box>

                            <Box sx={{
                                width: "100%",
                            }}>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    fullWidth={true}
                                    disabled={otp.length !== 6}
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: "13.5px",
                                        backgroundColor: "secondary.main",
                                        color: "white.main",
                                        textTransform: "capitalize",
                                        py: "20px",
                                        borderRadius: 4,
                                        "&:hover": {
                                            backgroundColor: "secondary.main",
                                            color: "white.main",
                                        }
                                    }}>
                                    Verify OTP
                                </Button>
                            </Box>

                        </FormControl>
                    </Box>

                    <Box sx={{
                        mt: 1,
                        mb: 3,
                        display: "flex",
                    }}>
                        <Timer initialTime={timer} timerStyle={{ fontSize: "13.5px", fontWeight: 400 }} />
                    </Box>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: 999 }}
                        open={OTPLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>

            </Box>
        </React.Fragment>
    );

    function otpVerification(e) {
        e.preventDefault();
        seOTPLoading(true);

        // if (forgotUserPassword) {
        //     const forgotUserPassword = verifyForgotUserOtp({
        //         mobile: userCredentials?.mobile,
        //         otp
        //     });

        // } else {

        // }

        const isOTPValidLength = handleOtpSubmit(otp);

        if (isOTPValidLength?.status) {
            async function userLogin() {
                const loginResponse = await verifyUserOTPForLogin(userCredentials?.mobile, isOTPValidLength?.userOTP);
                if (loginResponse?.status) {
                    actionNotifier.success(loginResponse?.data?.message, { position: "top-left", autoClose: 2000 });

                    if (userCredentials?.isLoginForm) {
                        userLoginStatusSetup(userCredentials?.token);
                        setTimeout(() => {
                            navigate('/', { replace: true });
                            seOTPLoading(false);
                        }, 300);
                    } else {
                        setTimeout(() => {
                            navigate('/login', { replace: true });
                            seOTPLoading(false);
                        }, 300);
                    }
                } else {
                    seOTPLoading(false);
                    setOtp('');
                }
            }


            if (forgotUserPassword) {
                userforgotPassword({
                    mobile: userCredentials?.mobile,
                    otp
                });
            }
            else {
                userLogin();
            }
        } else {
            actionNotifier.error("Invalid OTP length!", { position: "top-left", autoClose: 3500 });
            seOTPLoading(false);
            setOtp('');
        }
    }

    async function otpResendHandle(mobile) {
        if (isResendAvailable) {
            const response = await validateOtp(mobile);
            if (response?.status) {
                setIsResendAvailable(false);
                setTimer(60);
                actionNotifier.info("OTP has been resent", { position: "top-left", autoClose: 1000 });
            }
        } else {
            actionNotifier.warning(`Please wait for the timer to resend OTP`, { position: "top-left", autoClose: 1000 });
        }
    }

    async function userforgotPassword(payload) {
        const forgotPasswordResponse = await verifyForgotUserOTP(payload?.mobile, payload?.otp);

        if (forgotPasswordResponse?.status) {
            actionNotifier.success(forgotPasswordResponse?.data?.message, { position: "top-left", autoClose: 800 });
            setForgotToken({
                status: true,
                token: forgotPasswordResponse?.data?.data?.otpToken
            });

            seOTPLoading(false);
        } else {
            seOTPLoading(false);
            setOtp('');
        }
    }
}

export default MobileOTPForm