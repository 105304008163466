import { getLogin, signUp, validateOtp, verifyForgotUserOtp, verifyUserOtp } from "../../../apiCalls/urls/urlFunction";
import { isValidName, validateEmail, validatePhone } from "../../../shared/validation";


export function userLoginValidation(mobile, upassword) {
    const password = String(upassword).trim();

    // Object to store the response details
    let response = {
        success: false,
        message: '',
        data: null,
        phone: false,
        password: false
    };

    // Validate the mobile number
    const cleanedMobile = String(mobile).replace(/\D/g, '');
    const phoneValidation = validatePhone(cleanedMobile);

    if (!phoneValidation.status) {
        response.message = phoneValidation.message;
        response.phone = true;
        return response;
    }

    // Check if the password is provided
    if (!password) {
        response.message = "Password is required!";
        response.password = true;
        return response;
    }

    // Check if the password length meets the minimum requirement
    if (password.length < 3) {
        response.message = "Password must be at least 3 characters long!";
        response.password = true;
        return response;
    }

    // If all validations pass, set success to true and include any relevant data
    response.success = true;
    response.message = "Login successful!";
    response.data = { mobile: cleanedMobile, password };

    return response;
}

export async function loginUser(mobile, password) {
    const payload = { mobile, password };

    let loginResponseStatus = {
        status: true,
        token: null,
        mobile: null,
        error: false,
        message: null
    }

    const loginResponse = await getLogin(payload);

    if (loginResponse?.data?.status) {
        loginResponseStatus.token = loginResponse?.data?.data?.token;
        loginResponseStatus.mobile = mobile;
        loginResponseStatus.message = loginResponse?.data?.message;
    } else {
        loginResponseStatus.status = false;
        loginResponseStatus.error = true;
        loginResponseStatus.mobile = mobile;
    }

    return loginResponseStatus;
}

export const handleOtpSubmit = (otp) => {
    const trimOtp = otp.trim();
    let otpValidationResponse = {
        status: true,
        userOTP: null,
    }

    if (trimOtp.length === 6) {
        otpValidationResponse.userOTP = trimOtp;
    } else {
        otpValidationResponse.status = false;
    }

    return otpValidationResponse;
};

export const verifyUserOTPForLogin = async (mobile, otp) => {
    return await verifyUserOtp({
        mobile,
        otp
    });
}

export const verifyForgotUserOTP = async (mobile, otp) => {
    return await verifyForgotUserOtp({
        mobile,
        otp
    });
}

export const newUserInputValidate = (formData) => {
    const { name, email, mobile, referral, gender, city, password } = formData;
    const errorObject = {
        nameError: { title: name, status: false, message: '' },
        emailError: { title: email, status: false, message: '' },
        mobileError: { title: mobile, status: false, message: '' },
        referralError: { title: referral, status: false, message: '' },
        genderError: { title: gender, status: false, message: '' },
        cityError: { title: city, status: false, message: '' },
        passwordError: { title: password, status: false, message: '' },
        isAnyError: false,
    };

    // Validate Name
    const isValidUserName = isValidName(name);
    if (!isValidUserName.status) {
        errorObject.nameError.status = true;
        errorObject.nameError.message = isValidUserName.message;
        errorObject.isAnyError = true;
    }

    // Validate Email
    const isValidEmail = validateEmail(email);
    if (!isValidEmail.status) {
        errorObject.emailError.status = true;
        errorObject.emailError.message = isValidEmail.message;
        errorObject.isAnyError = true;
    }

    // Validate Mobile
    const isValidMobile = validatePhone(mobile);
    if (!isValidMobile.status) {
        errorObject.mobileError.status = true;
        errorObject.mobileError.message = isValidMobile.message;
        errorObject.isAnyError = true;
    }

    // Validate Gender
    if (!gender || gender.trim().length === 0) {
        errorObject.genderError.status = true;
        errorObject.genderError.message = "Gender is required.";
        errorObject.isAnyError = true;
    }

    // Validate City
    if (!city || city.trim().length < 4) {
        errorObject.cityError.status = true;
        errorObject.cityError.message = "City name must be at least 4 characters long.";
        errorObject.isAnyError = true;
    }

    // Validate Password
    if (!password || password.length < 6) {
        errorObject.passwordError.status = true;
        errorObject.passwordError.message = "Password must be at least 6 characters long.";
        errorObject.isAnyError = true;
    }

    return errorObject;
};


export const userForgotPassword = async (mobile) => {
    const response = await validateOtp(mobile);
    return response;
}
