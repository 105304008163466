import { getAllCategories } from "../../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";

export const onLoad = () => {
  return async (dispatch) => {
    dispatch(onFetchCategory());
  };
};

export function onFetchCategory() {
  return async (dispatch) => {
    let res = await getAllCategories();
    if (res?.status) {
      dispatch(updateCategoryReducer(res?.data));
    } else {
      console.error(res);
    }
  };
}

export const updateCategoryReducer = (data) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_REDUCER,
    payload: { data },
  };
};
