import React from "react";
import { Container,Grid, Typography } from "@mui/material";
import AddressComponent from "./AddressComponent";
import UseFulLinks from "./UseFulLinks";
import NewsLaterComponent from "./NewsLaterComponent";
import BrandTag from "./BrandTag";

function LargeFooter() {
    return (
        <>
            <Container
                maxWidth="false"
                sx={{
                    backgroundColor: "primaryDark.main",
                    minHeight: {
                        xs: "auto",
                        md: "auto",
                    },
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Grid
                    container
                    maxWidth="xl"
                    sx={{
                        backgroundColor: "primaryDark.main",
                        pt: { xs: 2, md: 4 },
                        px: { xs: 0, lg: 4 },
                        // justifyContent: "center"
                    }}
                >
                    {/* News Letter form  */}
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3} sx={{
                        display: {
                            xs: "none",
                            sm: "block",
                        },
                        mb: {
                            xs: 2,
                            sm: 0
                        }
                    }}>
                        <NewsLaterComponent />
                    </Grid>

                    {/* Website Description */}
                    <Grid item xs={12} sm={4} md={3} lg={4} xl={3} sx={{
                        mb: {
                            xs: 2,
                            sm: 0
                        },
                        px: 3
                    }}>

                        <Typography
                            variant="variant"
                            sx={{
                                color: "white.main",
                                fontSize: { xs: "12px", lg: "14px" },
                                lineHeight: { xs: "24px", lg: "22px" },
                                wordSpacing: { xs: "1px", lg: "0.100rem" },
                                width: { xs: "80%", lg: "80%" },
                                fontWeight: { xs: 300, lg: 300 },
                                mt: { xs: 1, lg: 0 },
                                mb: { xs: 2, lg: 1 },
                                pb: { xs: 2, lg: 1 },
                            }}
                        >
                            99gift brings more than 250+ brand Gift Card & Vouchers with greatest
                            discounts available for Apparels, Tours & Travels, Food,
                            Entertainment, Grocery, Mobile and Health categories.
                        </Typography>
                    </Grid>

                    {/* Useful Link */}
                    <Grid item xs={12} sm={4} md={3} lg={2} xl={3} sx={{
                        mb: {
                            xs: 2,
                            sm: 0
                        }
                    }}>
                        <UseFulLinks />
                    </Grid>

                    {/* Address */}
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3} sx={{
                        mb: {
                            xs: 2,
                            sm: 0
                        }
                    }}>
                        <AddressComponent />
                    </Grid>

                    {/* News Letter form  show only in xs Devices  */}
                    <Grid item xs={12} sx={{
                        display: {
                            xs: "block",
                            sm: "none",
                        },
                        mb: {
                            xs: 2,
                            sm: 0
                        }
                    }}>
                        <NewsLaterComponent />
                    </Grid>


                    {/* Tags */}
                    <Grid item xs={12} sm={8} md={12}
                        sx={{
                            my: 2
                        }}
                    >
                        <BrandTag />
                    </Grid>

                </Grid>
            </Container>
        </>
    );
}

export default LargeFooter