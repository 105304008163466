import { axios } from "../../shared/axios";
import { actionNotifier } from "../../components/tostify";
const LOCAL_STORAGE_TOKEN = "99GIFT";

export const getToken = () => {
  let auth = localStorage.getItem(LOCAL_STORAGE_TOKEN);

  if (auth) {
    const data = JSON.parse(auth)
    return data?.token;
    // return myToken;
  } else {
    return null;
    // return myToken;
  }
};

export const apiFunction = async (
  url,
  method,
  postData,
  token,
  extraConfig
) => {
  // url = process.env.REACT_APP_ENDPOINT + url
  let config = {
    method: method,
    url: url,
    data: postData ? postData : {},
  };

  let data;
  if (token) {
    let token = getToken();
    config = {
      ...config,
      headers: {
        token: `${token}`,
      },
    };
  }

  if (extraConfig === "blob") {
    config = {
      ...config,
      responseType: "blob",
    };
  }

  if (extraConfig === "multipart") {
    config.headers = {
      ...config.headers,
      "content-type": "multipart/form-data",
    };
  }

  await axios({ ...config })
    .then((res) => {
      if (extraConfig === "blob") {
        data = res.data;
      } else {
        if (res.data) {
          data = {
            data: res.data,
            status: true,
            message: "Success",
            statusCode: res.status
          };
        } else {
          data = {
            status: true,
            message: "Success",
            statusCode: res.status
          };
        }
      }
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        userLogout();
        actionNotifier.error(err?.response?.data?.message, { position: "top-left", autoClose: 3500 });
        data = {
          ...err.response.data,
          status: false,
          statusCode: err.response.status
        };
      } else if (err?.response?.status === 503) {
        actionNotifier.error(err?.response?.data?.message, { position: "top-left", autoClose: 3500 });
        data = {
          ...err.response.data,
          status: false,
          statusCode: err.response.status
        };
      }
      else {
        data = {
          ...err?.response?.data,
          status: false,
          statusCode: err?.response?.status
        };
      }
    });
  return data;
};

export const postFormDataAPI = async (url, postData, section) => {
  let token = getToken(section);
  let data;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      token: `${token}`,
    },
  };
  await axios
    .post(url, postData, config)
    .then((res) => {
      data = res.data;
    })
    .catch((err) => {
      data = {
        ...err.response.data,
        status: false,
      };
    });
  return data;
};

export const userTokenSave = (token) => {

  if (token) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify({ token }));
    return true;
  }
  return false;
};

// export const userLogout = () => {
//   actionNotifier.clearAll();
//   const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
//   if (token) {
//     localStorage.removeItem(LOCAL_STORAGE_TOKEN);
//     // actionNotifier.success("Logout Successful", { position: "top-right", autoClose: 2000 })
//   }
//   return false;
// };

export const userLogout = (timer = 2000) => {
  actionNotifier.clearAll();
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  if (token) {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    // actionNotifier.error(msg, { position: "top-right", autoClose: 2000 });
    setTimeout(() => {
      window.location.reload()
    }, timer);
  }
  return false;
};
