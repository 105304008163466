import React from 'react'
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, IconButton, Stack, Typography } from '@mui/material'
import NewsLetter from '../../innerComponent/NewsLetter';
import { NavLink } from 'react-router-dom';


const SocialMediaIcon = React.memo(({ href, icon }) => (
    <NavLink to={href} target='_blank'>
        <IconButton color="secondary" aria-label="social media icons">
            {icon}
        </IconButton>
    </NavLink>
));

function NewsLaterComponent() {
    return (
        <Box>

            <Typography
                variant="subtitle1"
                color="white.main"
                sx={{
                    textTransform: {
                        xs: "capitalize",
                    },
                    mb: { xs: "4px", md: 2, lg: 1 },
                    fontSize: { xs: "16px", sm: "16px", lg: "16px" },
                }}
            >
                News Letter
            </Typography>

            <NewsLetter />
            <Stack
                sx={{
                    width: "200px",
                    mt: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                }}
            >
                <SocialMediaIcon
                    href="https://www.facebook.com/99gift.in"
                    icon={<FacebookIcon color="secondary.main" sx={{ width: "24px" }} />}
                />
                <SocialMediaIcon
                    href="https://www.instagram.com/99gift.in"
                    icon={<InstagramIcon color="secondary.main" sx={{ width: "24px" }} />}
                />
                <SocialMediaIcon
                    href="https://www.linkedin.com/company/99-gift"
                    icon={<LinkedInIcon color="secondary.main" sx={{ width: "24px" }} />}
                />
            </Stack>

        </Box>
    )
}

export default NewsLaterComponent