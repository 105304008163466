import { fetchUserOrders } from '../../apiCalls/urls/urlFunction';
import * as actionTypes from './actionTypes';

const initialData = {
    search: null,
    filterBy: null,
    date: { from: '2024/04/05', to: '2024/06/26' },
    pagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0,
    },
    status: 0,
};

export function onFetchOrder(data = initialData) {
    return async (dispatch) => {
        let orderResponse = await fetchUserOrders(data);
        if (orderResponse.status) {
            dispatch(updateOrderReducer(orderResponse.data))
        }
        else {
            console.error(orderResponse.message)
        }
    }
}

export const updateOrderReducer = (payload) => {
    return {
        type: actionTypes.UPDATE_USER_ORDER_REDUCER,
        payload: payload
    };
}