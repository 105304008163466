import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom';
const tagsList = [
    { title: "Allen Solly", url: '/card-details/808/allen-solly-e-gift-card' },
    { title: "Louis Philippe", url: '/card-details/706/louis-philippe-e-card-list' },
    { title: "Peter England", url: '/card-details/704/peter-england-e-gift-card' },
    { title: "max Fashion", url: '/card-details/463/max-fashion-e-gift-card' },
    { title: "Van Heusen", url: '/card-details/705/van-heusen-e-card-list' },
    { title: "Bata", url: '/card-details/483/batagyftr-e-card-list' },
    { title: "Jack & Jones", url: '/card-details/241/jack--jones-e-gift-card-brand-voucher' },
    { title: "Vero moda", url: '/card-details/212/vero-moda-e-gift-card-brand-voucher' },
    { title: "Only", url: '/card-details/257/only-e-gift-card-brand-voucher' },
    { title: "Pizza Hut", url: '/card-details/640/pizza-hut-e-gift-card' },
    { title: "Hidesign", url: '/card-details/739/hidesign' },
    { title: "Lifestyle", url: '/card-details/486/lifestyle-store-only-' },
    { title: "amazon prime", url: '/card-details/576/amazon-prime-voucher-12-months-membership' },
    { title: "croma", url: '/card-details/248/croma-e-gift-card-brand-voucher' },
    { title: "reliance jio mart", url: '/card-details/691/reliance-jio-mart-e-gift-voucher' },
    { title: "mamaearth", url: '/card-details/536/mamaearth-e-gift-card' },
    { title: "unipin", url: '/card-details/711/unipin-e-gift-card' },
    { title: "pvr cinemas", url: '/card-details/218/pvr-cinemas-e-gift-card-brand-voucher' },
    { title: "valorant", url: '/card-details/698/valorant-vp-points-1000-vp-gift-card' },
    { title: "tata cliq", url: '/card-details/222/tata-cliq-e-gift-card-brand-voucher' },
    { title: "woodland", url: '/card-details/492/woodland-e-gift-card' },
    { title: "tanishq", url: '/card-details/250/tanishq-jewellery-e-gift-card-brand-voucher' }
];

function BrandTag() {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",

                width: "100%",
                mb: 2,
                px: 2,
                py: 1,
                backgroundColor: "primaryaDark.main",
            }}
        >
            <Typography
                component={'span'}
                sx={{
                    color: "secondary.main",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    fontSize: "16px"
                }}
            >
                Brands&nbsp;:
            </Typography>

            <List
                sx={{
                    color: "white.main",
                    display: "inline-flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    py: 0,
                }}
            >
                {/* <ListItem sx={{ display: "inline-block", width: "auto", px: "3px", py: "2px", color: "secondary.main" }}>
                    <ListItemText
                        primary={`Brands :`}
                        sx={{ display: "inline-block", color: "secondary.main", fontWeight: "600", textTransform: "capitalize", fontSize: "16px" }}
                    />
                </ListItem> */}
                {tagsList.map((tag, index) => (
                    <React.Fragment key={`tag_${index * 1}`}>
                        <ListItem sx={{ display: "inline-block", width: "auto", px: "3px", py: "2px", fontSize: "0.8rem" }}>
                            <NavLink to={tag?.url}>
                                <ListItemText
                                    primary={`${tag.title} ${tagsList?.length - 1 !== index ? '|' : ''}`}
                                    sx={{ display: "inline-block", color: "white.main", fontWeight: "400", textTransform: "capitalize", fontSize: "12px" }}
                                />
                            </NavLink>
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>

        </Box>
    )
}

export default BrandTag