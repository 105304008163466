import { UPDATE_USER_PROFILE } from "./actionTypes";

const initialState = {
    allData: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_PROFILE:
            state = {
                ...state,
                allData:action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default reducer;