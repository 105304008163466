import React from 'react'
import { defaultProductImage, NavLink, titleToUrl } from "../../../imports/generalImport";
import { Card, CardMedia, CardContent, Typography } from "../../../imports/muiImport";

function MobileProductCard({ product }) {
    return (
        <NavLink
            to={`/card-details/${product?.id}/${titleToUrl(product?.title)}`}
            style={{
                textDecoration: "none",
            }}
        >
            <Card sx={{
                height: "100%",
                minHeight: 170,
                borderRadius: 6,
                mx:1
            }}>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    width={"100%"}
                    height={100}
                    image={product?.image ?? defaultProductImage}
                    sx={{

                    }}
                />
                <CardContent sx={{
                    py: 1
                }}>
                    <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                        sx={{
                            fontSize: "15.5px",
                            color: "primary.main",
                            fontWeight: 700,
                        }}>
                        {product?.discount ?? 0.00}% Off
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {product?.title ?? 'product title not available'}
                    </Typography>
                </CardContent>
            </Card>
        </NavLink>
    )
}

export default MobileProductCard