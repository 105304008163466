import * as actionTypes from "./actionTypes";
const initialState = {
  allData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_CATEGORY:
      return {
        ...state,
        allData: [...state.allData, action.payload],
      };
    case actionTypes.DESELECTED_CATEGORY:
      return {
        ...state,
        allData: state.selectedCategories.filter(
          (category) => category !== action.payload
        )
      };
    default:
      return state;
  }
};

export default reducer;

