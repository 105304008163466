import { React, Logo, NavLink, useNavigate, customerSupport } from "../../imports/generalImport";
import { Box, Container, Typography, TextField, Button, List, ListItem, ListItemIcon, ListItemText } from "../../imports/muiImport";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MobileContactForm from '../mobileComponents/mobileContactForm/MobileContactForm';
import { Helmet } from "react-helmet-async";


function MobileContactUs() {
    return (
        <Container sx={{ bgcolor: "#fffbff", px: 1 }}>

            <Helmet>
                <title>contact us -99gift.in</title>
                <meta property="og:title" content="contact us -99gift.in" />
            </Helmet>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                {/* Icon */}
                <Box sx={{}}>
                    <img src={customerSupport} alt="Support Icon" style={{ width: '100px' }} />
                </Box>

                {/* Title */}
                <Typography variant="h5" sx={{
                    color: "primary.main",
                    fontWeight: 500,
                    textTransform: "capitalize"
                }}
                >
                    Share Your Issue
                </Typography>
                <Typography variant="subtitle1"
                    sx={{
                        color: "textPrimary.main",
                        fontWeight: 400,
                        textTransform: "capitalize"
                    }}
                >
                    Get chat with our customer support team
                </Typography>
            </Box>

            <MobileContactForm />

            <Box sx={{ margin: '0 auto' }}>
                {/* Title */}
                <Typography variant="h6" sx={{ color: "primary.main", ml: 2 }}>
                    For Any Queries
                </Typography>

                {/* List */}
                <List>
                    {/* WhatsApp Contact */}
                    <ListItem
                        component="a"
                        href="https://wa.me/9251601501"
                        sx={{ py: 1, cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
                    >
                        <ListItemIcon>
                            <WhatsAppIcon
                                sx={{ fontSize: 40, color: '#25D366', backgroundColor: "#ddfbff", borderRadius: "50%", p: 1 }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary="WhatsApp"
                            secondary={
                                <Typography variant="h6" sx={{ fontWeight: 500, color: "primary.main", }}>
                                    9251601501
                                </Typography>
                            }
                            sx={{ ml: 2 }}
                        />
                        <ChevronRightIcon />
                    </ListItem>

                    {/* Phone Contact */}
                    <ListItem
                        component="a"
                        href="tel:8742998998"
                        sx={{ py: 1, cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
                    >
                        <ListItemIcon>
                            <PhoneIcon
                                sx={{
                                    fontSize: 37,
                                    color: '#007AFF',
                                    backgroundColor: "#ddfbff",
                                    borderRadius: "50%",
                                    p: 1,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary="Phone"
                            secondary={
                                <Typography variant="h6" sx={{ fontWeight: 500, color: "primary.main" }}>
                                    8742998998
                                </Typography>
                            }
                            sx={{ ml: 2 }}
                        />
                        <ChevronRightIcon />
                    </ListItem>

                    {/* Email Contact */}
                    <ListItem
                        component="a"
                        href="mailto:support@99gift.in"
                        sx={{ py: 1, cursor: 'pointer' }}
                    >
                        <ListItemIcon>
                            <EmailIcon
                                sx={{
                                    fontSize: 37,
                                    color: '#EA4335',
                                    backgroundColor: "#ddfbff",
                                    borderRadius: "50%",
                                    p: 1,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary="Email"
                            secondary={
                                <Typography variant="h6" sx={{ fontWeight: 500, color: "primary.main" }}>
                                    support@99gift.in
                                </Typography>
                            }
                            sx={{ ml: 2 }}
                        />
                        <ChevronRightIcon />
                    </ListItem>
                </List>
            </Box>

        </Container>
    )
}

export default MobileContactUs