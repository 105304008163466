import React from 'react'
import { useState } from "../../../imports/generalImport";
import { Box, Button, Dialog } from "../../../imports/muiImport";
import MobileGiftVoucher from './MobileGiftVoucher';
import { fetchVoucherDetail } from '../../../apiCalls/urls/urlFunction';
import MobileMultipleVoucherData from './MobileMultipleVoucherData';
import MobileLoading from '../mobileLoading/MobileLoading';

function MobileVouchersViewButton(props) {
    const { voucherData } = props;
    const [open, setOpen] = useState(false);
    const [voucherLoading, setVoucherLoading] = useState(false);
    const [userVoucherData, setUserVoucherData] = useState([]);
    const [orderData, setOrderData] = useState({});
    const [showSingleVoucher, setShowSingleVoucher] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
        setVoucherLoading(true);
        getVoucherDetail(voucherData?.id);
    };

    const handleClose = () => {
        setOpen(false);
        setVoucherLoading(false);
    };

    return (
        <>
            <Button variant="contained"
                onClick={handleClickOpen}
                sx={{
                    color: "white.main",
                    backgroundColor: "#5c69ea",
                    textTransform: "capitalize",
                    boxShadow: 4,
                    height: 30,
                    "&:hover": {
                        color: "white.main",
                        backgroundColor: "#2632a8",
                    }
                }}
            >
                view
            </Button>



            {voucherLoading &&
                (
                    <Dialog
                        fullScreen={true}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="change-password-title"
                        aria-describedby="change-password-description"
                        sx={{
                            "& .MuiDialog-paper": {
                                width: "100%",
                                maxWidth: "none",
                            },
                        }}
                    >
                        <Box>
                            <MobileLoading />
                        </Box>
                    </Dialog>
                )
            }


            {showSingleVoucher == true &&
                (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="change-password-title"
                        aria-describedby="change-password-description"
                        sx={{
                            "& .MuiDialog-paper": {
                                width: "100%",
                                maxWidth: "none",
                            },
                        }}
                    >
                        <MobileGiftVoucher
                            handleClose={handleClose}
                            orderData={orderData}
                            voucherData={userVoucherData[0]}
                        />
                    </Dialog>
                )
            }


            {showSingleVoucher == false &&
                (
                    <Dialog
                        fullScreen={true}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="change-password-title"
                        aria-describedby="change-password-description"
                        sx={{
                            "& .MuiDialog-paper": {
                                width: "100%",
                                maxWidth: "none",
                            },
                        }}
                    >
                        <MobileMultipleVoucherData
                            handleClose={handleClose}
                            orderData={orderData}
                            voucherData={userVoucherData}
                        />
                    </Dialog>
                )
            }
        </>
    );

    async function getVoucherDetail(orderId) {
        try {
            setVoucherLoading(true);
            const orderResponse = await fetchVoucherDetail(orderId, {
                "search": null,
                "filterBy": null,
                "pagination": {
                    "sortBy": "id",
                    "descending": false,
                    "page": 1,
                    "rowsPerPage": 100000000,
                    "rowsNumber": 0
                }
            });

            if (orderResponse.statusCode === 200) {
                setUserVoucherData(orderResponse?.data?.data?.data);
                setOrderData(orderResponse?.data?.data?.order);
                if (orderResponse?.data?.data?.data?.length == 1) {
                    setShowSingleVoucher(true);
                } else {
                    setShowSingleVoucher(false);
                }
                setVoucherLoading(false);
            }
        } catch (error) {
            setVoucherLoading(false);
            console.error(error);
        }
    }
}

export default MobileVouchersViewButton