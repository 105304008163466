import React from 'react';
import { Box, Typography, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, } from "../../../imports/muiImport";
import { useState, useNavigate, titleToUrl } from "../../../imports/generalImport";
import { actionNotifier } from '../../../components/tostify';
import CopyToClipboard from 'react-copy-to-clipboard';

function MobileReferralCode({ UserInfomation }) {
    const [copied, setCopied] = useState(false);

    const handleCopy = (title) => {
        if (title === "referral_code") {
            actionNotifier.info("Referral code Copied!", {
                position: "top-right",
                autoClose: 600,
                theme: "dark",
                hideProgressBar: "true"
            });
        }
        setCopied(true);
    };


    return (
        <Box sx={{
            mt: 2,
            p: 2,
            borderRadius: 2,
            bgcolor: "white.main",
        }}>
            <Typography variant='subtitle1' sx={{
                color: "primary.main",
                fontWeight: 500,
                fontSize: "13.5px",
                mb: "4px",
            }}>
                Referral Code
            </Typography>

            <Box sx={{
                width: "100%",
                border: "1px dashed #00B8EE",
                p: ".4px",
                borderRadius: 2,
                boxShadow: true ? 4 : 6,
            }}>

                <List sx={{
                    backgroundColor: 'lightSecondary.main',
                    borderRadius: 2,
                    display: "flex",
                    alignItems: "center",
                }}>
                    <ListItem disablePadding>
                        <ListItemButton
                            sx={{
                                py: "4px",
                            }}
                        >
                            <ListItemText primary={UserInfomation?.data?.id}
                                primaryTypographyProps={{
                                    sx: {
                                        color: 'primary.main',
                                        fontWeight: 500,
                                        fontSize: "18px",
                                        letterSpacing: "2px"
                                    }
                                }}
                            />
                        </ListItemButton>
                    </ListItem>


                    <CopyToClipboard
                        text={UserInfomation?.data?.id}
                        onCopy={() => handleCopy('referral_code')}
                    >
                        <Button variant='text' sx={{
                            textTransform: "capitalize",
                            color: "textPrimary.main",
                        }}>
                            copy
                        </Button>
                    </CopyToClipboard>
                </List>
            </Box>
        </Box>
    )
}

export default MobileReferralCode