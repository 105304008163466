import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Layout from "../../layouts";
import GiftCardWorking from "../DASHBOARD/GiftCardWorking";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import { v4 as uuidv4 } from "uuid";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import PageBreadcrumb from "../../components/other/PageBreadcrumb";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet-async";


const GiftCardWorks = (props) => {
  const { isMobile = false } = props;
  const Mobile = useMediaQuery("(max-width:480px)");
  const backgroundColor = Mobile ? "#fff" : "white.main";
  const location = useLocation();
  const canonicalUrl = `https://www.99gift.in${location.pathname}`;

  const giftCardContiner = {
    boxShadow: { xs: 0, sm: 1, md: 2 },
    borderRadius: { xs: 0, sm: 1, md: 3 },
    p: { xs: 0, sm: 2, md: 3 },
    my: { xs: 2, sm: 3, md: 3 },
    backgroundColor: backgroundColor,
  };

  const giftCardStyle = {
    my: { xs: 0, md: 0 },
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>How Gift Cards Works - 99Gift</title>
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content="How Gift Cards Works - 99Gift" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      {!isMobile &&
        (
          <Container maxWidth="xl">
            <PageBreadcrumb
              home="Home"
              currentProduct="How Gift Cards Works"
            />
          </Container>
        )
      }

      <Container
        maxWidth="false"
        disableGutters={true}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F1F5F6",
          "@media (max-width:480px)": {
            backgroundColor: "white.main",
          },
        }}
      >
        <GiftCardWorking style={giftCardStyle} />
        <Grid
          container
          alignItems="center"
          maxWidth="lg"
          sx={{
            px: { xs: 2, md: 0 },
            justifyContent: "center"
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mt: { xs: 2, sm: 2, md: 0 },
              mb: { xs: 1, sm: 1, md: 1 },
              background: { xs: "#c9f2ff", sm: "transparent" },
              borderRadius: { xs: 2, sm: 0 },
              boxShadow: { xs: 3, sm: 0 },
              p: { xs: 1, sm: 0 },
            }}
          >
            <GiftCardPageTitle />
          </Grid>
          <Grid item xs={12}>
            <GiftCardAdvantage giftCardContiner={giftCardContiner} />
            <GiftCardDiscount giftCardContiner={giftCardContiner} />
            <GiftCategoryDiscount giftCardContiner={giftCardContiner} />
            <GiftCategoryBuy giftCardContiner={giftCardContiner} />
            <GiftCardValidity giftCardContiner={giftCardContiner} />
            <GiftCardPhysical giftCardContiner={giftCardContiner} />
            <GiftCardShare giftCardContiner={giftCardContiner} />
            <GiftCardLose giftCardContiner={giftCardContiner} />
          </Grid>
        </Grid>
      </Container>

    </React.Fragment>
  );
};

GiftCardWorks.propTypes = {};

export default GiftCardWorks;

function GiftTitle({ param1, param2 }) {
  return (
    <React.Fragment>
      {param1 && (
        <Typography
          variant="h3"
          color="priimary.main"
          sx={{
            display: "inline-block",
            fontSize: { xs: "14.5px", md: "18px" },
          }}
        >
          {param1}
        </Typography>
      )}
      {param2 && (
        <Typography
          variant="h3"
          color="secondary.main"
          sx={{
            display: "inline-block",
            ml: { xs: "2px", md: "3px" },
            fontSize: { xs: "14.5px", md: "18px" },
          }}
        >
          {param2}
        </Typography>
      )}
    </React.Fragment>
  );
}

function GiftCardPageTitle() {
  return (
    <React.Fragment>
      <Typography
        variant="body2"
        color="primary.main"
        textAlign="center"
        sx={{
          fontSize: { xs: "14.5px", md: "18px" },
          textTransform: "capitalize",
        }}
      >
        Gifts are not only giving things but they also represent our profound
        love and bestow sincerity. For this purpose,
      </Typography>
      <Typography
        variant="body2"
        color="secondary.main"
        textAlign="center"
        sx={{
          fontSize: { xs: "14.5px", md: "18px" },
          textTransform: "capitalize",
        }}
      >
        we, 99gift brings you Gift Cards to convey your feelings to your loved
        ones.
      </Typography>
    </React.Fragment>
  );
}

function GiftCardAdvantage({ giftCardContiner }) {
  const orders = [
    {
      content:
        "Various options: You get to choose from over 160 options of Gift Cards. So, every shopper get almost everything according to their needs.",
    },
    {
      content:
        "Major discounts: Various types of gift cards come with discounts which reduces the load from your budget.",
    },
    {
      content:
        "Simple buying and redemption of Gift Cards: You can buy Gift cards easily in a small amount of time from our portal and also from 99gift application.",
    },
    {
      content:
        "Great substitution for Gifting: With Gift cards, you can give your loved ones something which is totally in use.Find a range of Gifting options for all occasions like Diwali, birthdays, New Year, anniversaries, etc. You can also send them instantly on Valentine's Day, Father’s Day, Mother’s Day, and other such special days.",
    },
    {
      content:
        "Great for the environment: We don’t use or issue plastic Cards that are harmful to the environment. Everything is online; which you contribute to the planet by buying a voucher from here.",
    },
    {
      content:
        "Environment friendly options: We don’t issue physical/plastic gift cards neither we encourage the use of the plastic, since it is harmful for the environment. Gift cards are online and you can contribute in Green Revolution by purchasing from here.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <GiftTitle param1="Advantages of buying" param2="99gift Cards" />
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={uuidv4()} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${index + 1}.`}
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function GiftCardDiscount({ giftCardContiner }) {
  const orders = [
    {
      content:
        "Get a 6% discount on a KFC Gift Card. Use it to meet all your taste buds needs with chicken varieties and other dishes.",
    },
    {
      content:
        "Get a 4% discount on Swiggy Gift Card. Find Delicious dishes from authentic places.",
    },
    {
      content:
        "Get a 12% discount on a Van Heusen Gift Card. Get latest apparels and follow the new trends with Van Heusen.",
    },
    {
      content:
        "Buy an Indian Terrain Gift Card with 10% GiftCard. The voucher is applicable at all leading stores of Indian Terrain. Note: The discounts may change depending on the product and when you are purchasing the cards.",
    },
    {
      content:
        "Buy a by paying 20 percent less from this portal. The voucher is applicable at all leading stores of PUMA.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <GiftTitle param1="99gift Discounts on" param2="Gift Cards" />
        </Grid>

        <Grid item xs={12} sx={{ mb: 0, mt: { xs: 1, md: 1 } }}>
          <Typography
            variant="body2"
            color="dark.main"
            sx={{ fontSize: { xs: "13.5px", md: "16px" } }}
          >
            Discounts are the most excitement elements for any brand/ non-brand
            related items.99gift offers amazing discounts and deals on Gift
            Cards to its precious customers.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={uuidv4()} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${index + 1}.`}
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} sx={{ mb: 0, mt: { xs: 0, md: 1 } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{ fontSize: { xs: "13.5px", md: "16px" } }}
          >
            Note: The discounts may change depending on when you are purchasing
            the cards.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function GiftCategoryDiscount({ giftCardContiner }) {
  const orders = [
    {
      title: "E-commerce and online",
      content:
        "Online shopping provides hassles free shopping options without going to the mall or store. It also provides us the option to choose from a range of product categories and also to compare within other product prices.  ",
    },
    {
      title: "Apparel & Accessories",
      content:
        "99Gift cards provides a wide range of apparel and accessories to enhance your styling quotient and add extra weightage to your wardrobe.",
    },
    {
      title: "Cabs & Travel",
      content:
        "Explore the borders with our Tours and Travel Gift cards. 99Gift cards provide you online hotel booking and online travel agencies and book your rides with OLA or UBER cabs.",
    },
    {
      title: "Food & Beverage",
      content:
        "Order your favorite food and start your day with delicious dishes from a wide range of popular restaurants and hotels. Give your loved ones a loving treat on the occasions and regularly.",
    },
    {
      title: "Gifting",
      content:
        "Share your feelings and strengthen your bond with your friendswith our gifting solutions via gift cards with great discounts.",
    },
    {
      title: "Entertainment and magazines",
      content:
        "Surf the latest web series with 99gift cards and enjoy the streaming videos. Binge watch your favorite series with your friends and relatives. If you are interested in reading, then there is a plenty of entertainment, business and science magazines. You will love our this vast collection of online gifts cards with discounts",
    },
    {
      title: "Grocery & Home Needs",
      content:
        "Purchase your daily routines grocery from grocery stores. Everyone has a day to day shopping needs which can be fulfilled with our Gift vouchers with best discounts.",
    },
    {
      title: "Mobile & Electronics",
      content:
        "Navigate the vouchers for electronics stores, mobile phones, latest gadgets and home appliances.",
    },
    {
      title: "Health & Wellness",
      content:
        " Navigate the vouchers for electronics stores, mobile phones, latest gadgets and home appliances.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <GiftTitle param1="Multiple categories of" param2="99 Gift Cards" />
        </Grid>

        <Grid item xs={12} sx={{ mb: 0, mt: { xs: 1, md: 1 } }}>
          <Typography
            variant="body2"
            color="dark.main"
            sx={{ fontSize: { xs: "13.5px", md: "16px" } }}
          >
            At 99gift, you will find Gift Cards of multiple categories. Let’s
            have a look at each of them.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={uuidv4()} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${order?.title} :`}
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "17px" },
                      color: "primary.main",
                      fontWeight: { xs: 600, md: 600 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function GiftCategoryBuy({ giftCardContiner }) {
  const orders = [
    {
      content: "Register and login to 99Gift app.",
    },

    {
      content:
        "Sign in to the app and Browse your favorite Gift card by clicking ‘Purchase Now’.",
    },
    {
      content:
        "Choose any listed brand and select Quantity, Amount etc. of your Gift card product and click ‘Pay’.",
    },
    {
      content:
        "Enter your Mobile number and pay with Credit/ Debit cards, Banks, UPI or Digital Wallets.",
    },
    {
      content:
        "Check your Dashboard, Gift cards, Wallet and statements, Orders with side menu bar.",
    },
    {
      content:
        "Redeem your Gift card code and pin in ‘Orders’ menu bar section and use it digitally in your selected application or redeem via email account.",
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <GiftTitle param1="How to buy" param2="99 Gift Cards" />
        </Grid>
        <Grid item xs={12}>
          <List>
            {orders.map((order, index) => (
              <ListItem key={uuidv4()} dense sx={{ pl: "0" }}>
                {order?.icon && (
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {order?.icon}
                  </ListItemIcon>
                )}
                {order?.content && (
                  <ListItemText
                    primary={`${index + 1} .`}
                    primaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",

                      mr: { xs: "3px", md: "3px" },
                    }}
                    secondary={order?.content}
                    secondaryTypographyProps={{
                      fontSize: { xs: "13.5px", md: "16px" },
                      color: "dark.main",
                      fontWeight: { xs: 400, md: 300 },
                      display: "inline",
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} sx={{ mb: 0, mt: { xs: 0, md: 1 } }}>
          <Typography
            variant="subtitle2"
            color="primary.main"
            sx={{ fontSize: { xs: "13.5px", md: "16px" } }}
          >
            In a few minutes, we will drop your voucher to your SMS and/or email
            inbox. Isn’t it so simple?
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function GiftCardValidity({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <GiftTitle
            param1="What is the validity of"
            param2="99 Gift Cards ?"
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 0, mt: { xs: 1, md: 1 } }}>
          <Typography
            variant="body2"
            color="dark.main"
            sx={{ fontSize: { xs: "13.5px", md: "16px" } }}
          >
            The validity of 99gift Cards varies by brand and product value. It’s
            usually between three to 12 months. The exact details will be
            provided to you with your gift card via e-mail.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function GiftCardPhysical({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <GiftTitle param1="Is there a physical" param2="99 Gift Cards ?" />
        </Grid>

        <Grid item xs={12} sx={{ mb: 0, mt: { xs: 1, md: 1 } }}>
          <Typography
            variant="body2"
            color="dark.main"
            sx={{ fontSize: { xs: "13.5px", md: "16px" } }}
          >
            At 99gift card, we provide our customers e-gift cards. These gift
            cards are delivered instantly to the customers. So, there is no need
            of any wallet or any physical safety.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function GiftCardShare({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <GiftTitle
            param1="How to share my"
            param2=" 99gift card with someone else ?"
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 0, mt: { xs: 1, md: 1 } }}>
          <Typography
            variant="body2"
            color="dark.main"
            sx={{ fontSize: { xs: "13.5px", md: "16px" } }}
          >
            The 99gift card sharing process is utmost simple.Open the Gift Card
            code and copy the message and send it to your friends, family or
            anyone else via email, SMS, Whatsapp or by any other means.These
            codes can be further used for redemption and as a payment options.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function GiftCardLose({ giftCardContiner }) {
  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          ...giftCardContiner,
        }}
      >
        <Grid item xs={12}>
          <GiftTitle
            param1="What happens if I lose my"
            param2=" 99gift cards ?"
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 0, mt: { xs: 1, md: 1 } }}>
          <Typography
            variant="body2"
            color="dark.main"
            sx={{ fontSize: { xs: "13.5px", md: "16px" } }}
          >
            In case of losing your 99gift card, You can send in your query on
            this page: Or, simply send an email with your mobile number to get
            the gift card instantly.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
