import React, { useState } from 'react';
import { useEffect, NavLink, useNavigate, useSelector, useDispatch } from "../../imports/generalImport";
import { Box, Container, Button, Typography, Avatar, Stack, Backdrop, FormControl } from "../../imports/muiImport";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import StarsIcon from '@mui/icons-material/Stars';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from "../../hooks/contexts/AuthContext";
import { userLogout } from '../../apiCalls/functions';
import MobileLogoutButton from '../mobileComponents/mobileLogoutButton/MobileLogoutButton';
import MobilePasswordChange from '../mobileComponents/mobilePasswordChange/MobilePasswordChange';
import MobileReferralCode from '../mobileComponents/mobileRefferalCode/MobileReferralCode';
import { fetchUserBalance } from "../../store/userBalance/action";
import { handleAmount } from '../../shared/extra';
import { Helmet } from 'react-helmet-async';

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: "primaryDark.main",
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function MobileProfile() {
    const UserInfomation = useSelector((state) => state.UserProfile?.allData);
    const { setAuthUser, setIsLoggedIn } = useAuth();
    const navigate = useNavigate();
    const balance = useSelector((state) => state.UserBalance.allData);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserBalance())
    }, []);

    if (!UserInfomation || UserInfomation?.length === 0) {
        return (
            <Backdrop
                sx={{
                    color: 'secondary.main',
                    zIndex: 999,
                    bgcolor: "#EEF7FF",
                    minHeight: "100vh",
                }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    } else {
        return (
            <Container sx={{
                pt: 12,
                bgcolor: "#EEF7FF",
                minHeight: "100vh",
                px: 2,
            }}>
                <Helmet>
                    <title>Profile Page -99gift.in</title>
                    <meta property="og:title" content="Profile Page -99gift.in" />
                </Helmet>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    bgcolor: "white.main",
                    minHeight: 310,
                    borderRadius: 2,
                    px: 2,
                }}>
                    <Box sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        top: -50,
                        maxHeight: 140,
                    }}>
                        <Avatar
                            {...(UserInfomation?.data?.name ?
                                stringAvatar(UserInfomation?.data?.name)
                                :
                                stringAvatar("9 9"))
                            }
                            sx={{
                                width: 90,
                                height: 90,
                                bgcolor: "primary.main",
                                fontSize: "21.5px",
                                textTransform: "uppercase",
                                mb: 1,
                                boxShadow: 6,
                            }}
                        />
                        <Typography variant='body1' sx={{
                            color: "primary.main",
                            fontWeight: 500,
                            fontSize: "15.5px",
                            textTransform: "capitalize"
                        }}>
                            {UserInfomation?.data?.name || "Guest User"}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}>

                        {UserInfomation?.data?.mobile &&
                            (<Stack sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                mb: 1,
                            }}>
                                <PhoneIcon sx={{
                                    color: "primary.main",
                                    fontSize: "18px"
                                }} />
                                <Typography variant='subtitle1' sx={{
                                    fontSize: "13.5px",
                                    fontWeight: 500,
                                    color: "primary.main",
                                    ml: 1,
                                }}>
                                    +91 {UserInfomation?.data?.mobile}
                                </Typography>
                            </Stack>)
                        }

                        {UserInfomation?.data?.email &&
                            (<Stack sx={{
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}>
                                <Stack sx={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                    <EmailIcon sx={{
                                        color: "primary.main",
                                        fontSize: "18px"
                                    }} />

                                    <Typography variant='subtitle1' sx={{
                                        fontSize: "13.5px",
                                        fontWeight: 500,
                                        color: "primary.main",
                                        ml: 1,
                                    }}>
                                        {UserInfomation?.data?.email}
                                    </Typography>
                                </Stack>

                                {/* <EditIcon sx={{
                                    color: "primary.main",
                                    fontSize: "20px"
                                }} /> */}
                            </Stack>)
                        }

                        <Stack sx={{
                            mt: 5,
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{
                                    fontSize: "13.5px",
                                    fontWeight: 400,
                                    textTransform: "capitalize",
                                    display: "flex",
                                    flexDirection: "column",
                                    borderRadius: 2,
                                    boxShadow: 4,
                                }}
                            >
                                Wallet Balance

                                <Typography variant='subtitle1' sx={{
                                    fontSize: "15.5px",
                                    fontWeight: 500,
                                    textTransform: "capitalize",
                                }}>
                                    ₹{balance > 1 ? handleAmount(balance) : "0.00"}
                                </Typography>
                            </Button>

                            <Button
                                variant="contained"
                                size="large"
                                sx={{
                                    fontSize: "13.5px",
                                    fontWeight: 400,
                                    textTransform: "capitalize",
                                    display: "flex",
                                    flexDirection: "column",
                                    borderRadius: 2,
                                    boxShadow: 4,
                                }}
                            >
                                Reward Points

                                <Stack sx={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                    <StarsIcon sx={{
                                        color: "#FFD700",
                                        fontSize: "22px"
                                    }} />
                                    <Typography variant='subtitle1' sx={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        ml: "4px",
                                        textTransform: "capitalize",
                                    }}>{UserInfomation?.data?.points}</Typography>
                                </Stack>

                            </Button>
                        </Stack>
                    </Box>
                </Box>

                <MobilePasswordChange />

                <Box sx={{
                    mt: 2,
                    p: 2,
                    borderRadius: 2,
                    bgcolor: "white.main",
                }}>
                    <Stack sx={{
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <Stack sx={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                            <ReceiptLongIcon sx={{
                                color: "primary.main",
                                fontSize: "18px"
                            }} />

                            <NavLink to={'/profile/statement'} style={{ textDecoration: "none" }}>
                                <Typography variant='subtitle1' sx={{
                                    fontSize: "13.5px",
                                    fontWeight: 500,
                                    color: "primary.main",
                                    ml: 1,
                                }}>
                                    My Transactions
                                </Typography>
                            </NavLink>
                        </Stack>

                        <KeyboardArrowRightIcon
                            sx={{
                                color: "primary.main",
                                fontSize: "20px"
                            }}
                            onClick={() => navigate('/profile/statement', { replace: true })}
                        />
                    </Stack>
                </Box>

                <MobileReferralCode UserInfomation={UserInfomation} />

                <Box sx={{ mt: 2 }}>
                    <MobileLogoutButton />
                </Box>

            </Container>
        );



    }

}