import { userBalance } from "../../apiCalls/urls/urlFunction";
import { UPDATE_USER_BALANCE } from "./actionTypes";


export function fetchUserBalance() {
    return async (dispatch) => {
        const balance = await userBalance();
        if (balance?.status) {
            dispatch(updateUserBalance(balance?.data?.data?.balance));
        }
    }
}

export function updateUserBalance(payload) {
    return {
        type: UPDATE_USER_BALANCE,
        payload: payload,
    };
}