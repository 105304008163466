import React, { useEffect } from 'react';
import { Box, BottomNavigation, BottomNavigationAction } from "../../../imports/muiImport";
import { useLocation, useNavigate } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import AppsIcon from '@mui/icons-material/Apps';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useAuth } from '../../../hooks/contexts/AuthContext';

export default function MobileFooterNavigation() {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    } = useAuth();

    const pageRoute = (route) => {
        switch (route) {
            case 'home':
                navigate('/');
                break;
            case 'support':
                navigate(`/contactus`);
                break;
            case 'card-list':
                navigate(`/card-list?category=apparel`);
                break;
            case 'order':
                navigate(`/profile/order`);
                break;
            case 'profile':
                navigate(`/profile`);
                break;
            case 'login':
                navigate(`/login`);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        // Set the active button based on the current route
        switch (location.pathname) {
            case '/':
                setValue(0);
                break;
            case '/contactus':
                setValue(1);
                break;
            case '/card-list':
                setValue(2);
                break;
            case '/profile/order':
                setValue(3);
                break;
            case '/profile':
                setValue(4);
                break;
            default:
                break;
        }
    }, [location.pathname]);

    return (
        <Box sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            px: 0,
            display: "flex",
            justifyContent: "center",
        }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    backgroundColor: "primaryDark.main",
                    // boxShadow: 8,
                    width: "100%",
                    maxWidth: "500px",
                    margin: "auto",
                }}
            >
                <BottomNavigationAction
                    label="Home"
                    icon={<HomeIcon sx={{ fontSize: 26, color: 'white.main' }} />}
                    onClick={() => pageRoute('home')}
                    sx={{
                        "& .MuiBottomNavigationAction-label": {
                            color: "white.main"
                        }
                    }}
                />

                <BottomNavigationAction
                    label="Support"
                    icon={<HeadsetMicIcon sx={{ fontSize: 26, color: 'white.main' }} />}
                    onClick={() => pageRoute('support')}
                    sx={{
                        "& .MuiBottomNavigationAction-label": {
                            color: "white.main"
                        }
                    }}
                />

                <BottomNavigationAction
                    label={!isLoggedIn && "Gift Cards"}
                    icon={<AppsIcon sx={{ fontSize: 36, color: 'white.main' }} />}
                    onClick={() => pageRoute('card-list')}
                    sx={{
                        "& .MuiBottomNavigationAction-label": {
                            color: "white.main"
                        }
                    }}
                />

                {isLoggedIn &&
                    (
                        <BottomNavigationAction
                            label="Order"
                            icon={<ReceiptIcon sx={{ fontSize: 26, color: 'white.main' }} />}
                            onClick={() => pageRoute('order')}
                            sx={{
                                "& .MuiBottomNavigationAction-label": {
                                    color: "white.main"
                                }
                            }}
                        />
                    )
                }


                {isLoggedIn ?
                    (<BottomNavigationAction
                        label="Profile"
                        icon={<AccountCircleIcon sx={{ fontSize: 26, color: 'white.main' }} />}
                        onClick={() => pageRoute('profile')}
                        sx={{
                            "& .MuiBottomNavigationAction-label": {
                                color: "white.main"
                            }
                        }}
                    />)
                    :
                    (<BottomNavigationAction
                        label="Login"
                        icon={<AccountCircleIcon sx={{ fontSize: 26, color: 'white.main' }} />}
                        onClick={() => pageRoute('login')}
                        sx={{
                            "& .MuiBottomNavigationAction-label": {
                                color: "white.main"
                            }
                        }}
                    />)
                }

            </BottomNavigation>
        </Box>
    );
}