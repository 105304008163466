import { useDispatch, useSelector } from 'react-redux';
import { setCategoriesProducts } from './action';
import { useEffect } from 'react';

function ProductFilterByCategory() {
    const categoryData = useSelector((state) => state.Category?.allData);
    const productData = useSelector((state) => state.AllProducts?.allData);
    const dispatch = useDispatch();

    useEffect(() => {

        if (categoryData && productData) {
            const data = categorizeProducts(productData, categoryData);

            if (data?.length > 1) {
                dispatch(setCategoriesProducts(data));
            }
        }

    }, [categoryData, productData, dispatch]);

    const categorizeProducts = (products, categoryData) => {
        // Create a map to store products categorized by category name
        const categorizedProducts = {};

        // Create a set of category names from the categoryData
        const categoryNames = new Set(categoryData.map(cat => cat.title));

        // Iterate through the products and categorize them
        products.forEach(product => {
            if (product.category && categoryNames.has(product.category.title)) {
                const categoryName = product.category.title;

                // Initialize the category key if it doesn't exist
                if (!categorizedProducts[categoryName]) {
                    categorizedProducts[categoryName] = [];
                }

                // Add the product to the appropriate category
                categorizedProducts[categoryName].push(product);
            }
        });

        // Convert the categorized products map into an array of objects
        const categorizedArray = Object.keys(categorizedProducts).map(categoryName => ({
            category: categoryName,
            products: categorizedProducts[categoryName]
        }));

        return categorizedArray;
    };



    return null;
}

export default ProductFilterByCategory