import axios from "axios";
import { getToken } from "../functions";

const SEARCH_URL = `https://gdzk7px6sc.execute-api.ap-south-1.amazonaws.com/dev/search?query=`;


// search_suggestion_feature_implement
export const productSearch = async (searchQueries) => {
    const queryURL = `${SEARCH_URL}${searchQueries}`;
    const token = getToken();

    try {
        const response = await axios.get(queryURL, {
            headers: {
                Token: `${token}`, // Include the token here
                'Content-Type': 'application/json', // Optional, in case your API requires it
            },
        });
        return response.data; 
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Optionally throw the error for further handling
    }
};
