import * as actionTypes from './actionTypes';

const initialState = {
    allData: [],
    descending: true,
    page: 1,
    rowsPerPage: 10,
    sortBy: 'id',
    total: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER_ORDER_REDUCER:
            state = {
                ...state,
                allData: action.payload.data,
                descending: action.payload.pagination.descending,
                page: action.payload.pagination.page,
                rowsPerPage: action.payload.pagination.rowsPerPage,
                sortBy: action.payload.pagination.sortBy,
                total: action.payload.pagination.total,
                status: action.payload.status
            }
            break;
        default:
            state = { ...state };
    }
    return state;
}

export default reducer;
