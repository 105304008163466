import { Box, Typography, Stack } from "../../../imports/muiImport";
import { React, useSelector, useEffect } from "../../../imports/generalImport";
import MobileProductCard from './MobileProductCard';
import { useState } from "react";
import { categoryUrl } from "../../../shared/extra";
import { CircularProgress } from "@mui/material";

function MobileProductSection(props) {
    const { title, products } = props;
    const [productLoading, setProductionLoading] = useState(false);

    useEffect(() => {
        setProductionLoading(true);
        if (products?.length > 0) {
            setProductionLoading(false);
        }
    }, [products?.length]);


    if (productLoading) {
        return (
            <Box sx={{
                minHeight: "30vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <CircularProgress />
            </Box>
        );
    }



    return (
        <Box
            sx={{
                pt: 1,
                mb: 2,
                px: 1,
                maxWidth: "100vw",
                overflowY: "auto"
            }}
        >

            {title && (
                <Stack sx={{
                    backgroundColor: "primaryDark.main",
                    borderRadius: 3,
                    p: 1,
                    mb: 3,
                }}>
                    <Typography variant='h5'
                        sx={{
                            color: "white.main",
                            fontWeight: 600,
                            textIndent: 10,
                            textTransform: "capitalize"
                        }}>
                        {title}
                    </Typography>
                </Stack>
            )}

            <Box sx={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
                rowGap: 4,
                // columnGap: 2,
                mb: 1,
            }}>

                {products?.map((product, index) => (
                    <MobileProductCard key={`product-card-${index * 1}`} product={product} />
                ))}

            </Box>

        </Box>
    )
}

export default MobileProductSection