import React from 'react';
import { Box, TextField, FormControl, Button, Backdrop, IconButton } from "../../../imports/muiImport";
import { securityOtp, useLocation, useNavigate, useState } from "../../../imports/generalImport";
import { validatePhone } from '../../../shared/validation';
import { userForgotPassword } from './userAccoountHook';
import { actionNotifier } from '../../../components/tostify';



function MobilePhoneForm(props) {
    const { setOTPForm, setUserCredentials } = props;
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState('');
    const [mobileError, setMobileError] = useState({
        status: '',
        message: ''
    });

    return (
        <FormControl
            component="form"
            method='POST'
            onSubmit={(e) => handleFormSubmit(e, mobile)}
            fullWidth
        >
            <TextField
                variant="outlined"
                placeholder="Enter your Mobile Number"
                fullWidth
                autoComplete="false"
                value={mobile}
                onChange={(e) => setMobile(e?.target?.value.replace(/\D/g, ''))}
                inputProps={{
                    maxLength: 10,
                    pattern: "\\d{10}",
                }}
                sx={{
                    marginBottom: 4,
                    backgroundColor: 'white.main',
                    borderRadius: '10px',
                    borderColor: 'white.main',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '10px',
                        borderColor: 'white.main',
                    },
                }}
                error={mobileError.status}
                helperText={mobileError.message}
                focused={mobileError.status}
                color="error"
            />

            <Button
                type='submit'
                variant="contained"
                sx={{
                    backgroundColor: 'secondary.main',
                    color: 'white.main',
                    borderRadius: '10px',
                    py: 2,
                    textTransform: "capitalize",
                    '&:hover': {
                        backgroundColor: 'secondary.main',
                    },
                }}
            >
                Submit
            </Button>


        </FormControl>
    );



    // validate User Phone No.
    function handleFormSubmit(event, mobile) {
        event.preventDefault();
        setLoading(true);
        let mobileError = {
            status: false,
            message: ''
        };
        let isValid = true;
        const isValidMobile = validatePhone(mobile);

        if (!isValidMobile.status) {
            mobileError.status = true;
            mobileError.message = isValidMobile.message;
            isValid = false;
        }

        setMobileError(mobileError);
        if (isValid) {
            handleForgot(mobile);
        }
    }

    // Send otp and Verify User's Phone no

    async function handleForgot(mobile) {
        const response = await userForgotPassword(mobile);
        if (response?.status) {
            actionNotifier.success('Otp Sent Successfully.', { position: "top-center", autoClose: 1500 });
            setOTPForm(true);
            setUserCredentials({
                mobile: mobile,
                token: null,
                status: true,
            });
        } else {
            actionNotifier.error(response?.message, { position: "top-center", autoClose: 2000 });
        }
        setMobile('');
    }

}

export default MobilePhoneForm