import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onFetchTopFiveProducts } from './action';

export default function Index() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.FeaturedProducts.allData)

  useEffect(() => {
    if (!products.length > 0) {
      dispatch(onFetchTopFiveProducts());
    }
  }, [dispatch, products.length]);
}