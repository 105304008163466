import React, { useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Backdrop,
    CircularProgress,
    RadioGroup,
    Radio,
    Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { actionNotifier } from '../../../components/tostify';
import { newUserInputValidate } from './userAccoountHook';
import { signUp } from '../../../apiCalls/urls/urlFunction';

export default function MobileSignUpForm({ setOTPForm, setUserCredentials }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        referral: '',
        gender: '',
        city: '',
        termsAccepted: false
    });
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({
        nameError: '',
        emailError: '',
        mobileError: '',
        referralError: '',
        genderError: '',
        cityError: '',
        passwordError: '',
        termsAccepteError: '',
    });
    const [signUpLoading, setSignUpLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    // New function to handle mobile number input and sanitize it
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/\D/g, ''); // Removes all non-numeric characters

        // Check for invalid input (non-numeric characters)
        if (value !== sanitizedValue) {
            // Show error for the specific field if there are any non-numeric characters
            setErrors((prevErrors) => ({
                ...prevErrors,
                [`${name}Error`]: 'Only numeric characters are allowed.',
            }));
        } else {
            // Clear the error if input is valid
            setErrors((prevErrors) => ({ ...prevErrors, [`${name}Error`]: '' }));
        }

        // Call handleChange with sanitized input for the correct field
        handleChange({
            target: {
                name: name, // Dynamic based on input field name
                value: sanitizedValue,
                type: 'text', // Assuming the type remains text after sanitization
                checked: undefined, // Not relevant for text input
            },
        });
    };

    return (
        <Box >
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.nameError}
                    helperText={errors.nameError}
                    margin="normal"
                    required
                    sx={{
                        backgroundColor: "white.main",
                        border: 0,
                    }}
                />

                <TextField
                    fullWidth
                    label="Mobile Number"
                    variant="outlined"
                    name="mobile"
                    type="tel"
                    required
                    value={formData.mobile}
                    onChange={handleInputChange}
                    error={!!errors.mobileError}
                    helperText={errors.mobileError}
                    margin="normal"
                    InputProps={{
                        inputProps: { maxLength: 10, autoComplete: 'tel' },
                    }}
                    sx={{
                        backgroundColor: "white.main",
                        border: 0,
                    }}
                />

                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.emailError}
                    helperText={errors.emailError}
                    margin="normal"
                    required
                    InputProps={{
                        inputProps: { autoComplete: 'email' },
                    }}
                    sx={{
                        backgroundColor: "white.main",
                        border: 0,
                    }}
                />


                <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    name="password"
                    required
                    type={showPassword ? 'text' : 'password'}
                    value={formData.password}
                    onChange={handleChange}
                    error={!!errors.passwordError}
                    helperText={errors.passwordError}
                    margin="normal"
                    autoComplete='off'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        backgroundColor: "white.main",
                        border: 0,
                    }}
                />


                <TextField
                    fullWidth
                    label="Referral Code(Optional)"
                    variant="outlined"
                    name="referral"
                    type="tel"
                    value={formData.referral}
                    onChange={handleInputChange}
                    error={!!errors.referralError}
                    helperText={errors.referralError}
                    margin="normal"
                    autoComplete='off'
                    InputProps={{
                        inputProps: { maxLength: 10 },
                    }}
                    sx={{
                        backgroundColor: "white.main",
                        border: 0,
                    }}
                />

                <Typography variant='subtitle1' sx={{
                    color: "primary.main",
                    fontWeight: 500,
                    fontSize: "15.5px",
                    marginTop: 2
                }}>
                    Select Gender :
                </Typography>
                <RadioGroup
                    row
                    aria-label="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                    error={!!errors.genderError}
                    helperText={errors.genderError}
                >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>

                <TextField
                    fullWidth
                    label="City Name"
                    variant="outlined"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    error={!!errors.cityError}
                    helperText={errors.cityError}
                    required
                    margin="normal"
                    sx={{
                        backgroundColor: "white.main",
                        border: 0,
                        marginTop: 2
                    }}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            name="termsAccepted"
                            checked={formData.termsAccepted}
                            onChange={handleChange}
                            color="primary"
                            required
                        />
                    }
                    label="I accept the terms and conditions"
                    sx={{ marginTop: 2 }}
                />

                {errors.termsAccepteError && (
                    <FormHelperText error sx={{ mt: "4px", textAlign: 'left' }}>
                        {errors.termsAccepteError}
                    </FormHelperText>
                )}

                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                        mt: 2,
                        py: "12px",
                        color: "white.main",
                        backgroundColor: "secondary.main",
                        textTransform: "capitalize",
                        textAlign: "center",
                        fontSize: "13.5px",
                        boxShadow: 4,
                        "&:hover": {
                            backgroundColor: "secondary.main",
                        }
                    }}
                >
                    Sign Up
                </Button>
            </form>

            <Backdrop
                sx={{ color: '#fff', zIndex: 999 }}
                open={signUpLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );

    function handleSubmit(e) {
        e.preventDefault();
        const accountValidation = newUserInputValidate(formData);
        if (accountValidation?.isAnyError) {
            if (
                accountValidation?.nameError.status ||
                accountValidation?.emailError.status ||
                accountValidation?.mobileError.status ||
                accountValidation?.referralError.status ||
                accountValidation?.genderError.status ||
                accountValidation?.cityError.status ||
                accountValidation?.passwordError.status
            ) {
                setErrors({
                    nameError: accountValidation?.nameError.message,
                    emailError: accountValidation?.emailError.message,
                    mobileError: accountValidation?.mobileError.message,
                    referralError: accountValidation?.referralError.message,
                    genderError: accountValidation?.genderError.message,
                    cityError: accountValidation?.cityError.message,
                    passwordError: accountValidation?.passwordError.message,
                    termsAccepteError: ""
                });
            }
        }
        else {
            handleUserRegister(accountValidation);
        }
    }


    async function handleUserRegister(userDetails) {
        const payload = {
            name: userDetails?.nameError?.title,
            email: userDetails?.emailError?.title,
            mobile: parseInt(userDetails?.mobileError?.title),
            gender: userDetails?.genderError?.title,
            password: userDetails?.passwordError?.title,
            parent_id: parseInt(userDetails?.referralError?.title),
            city: userDetails?.cityError?.title,
        };

        const signupResponse = await signUp(payload);

        try {
            if (signupResponse?.data?.status) {
                actionNotifier.success("Otp Sent Successful", { position: "top-left", autoClose: 3000 });
                setOTPForm(true);
                // setLoginLoading(false);
                setUserCredentials({
                    mobile: payload?.mobile,
                    token: null,
                    status: signupResponse?.data?.status,
                    isLoginForm: false
                });
            } else {
                actionNotifier.error(signupResponse?.message, { position: "top-left", autoClose: 3000 });
            }
        } catch (error) {
            actionNotifier.error("Something Internal Error! Please Try Later ", { position: "top-left", autoClose: 7000 });
        }

        // cosnt newUserSignup();


    }



}
