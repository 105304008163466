import { Box, CircularProgress, Container, Typography } from '@mui/material';

import logoImg from "./assets/img/logo.png";
import { christmasLOGO, christmasLoadingLogo } from './imports/generalImport';

const PageLoading = () => {
    return (
        <Container maxWidth="false">
            <Box sx={{
                py: 3,
                minHeight: {
                    xs: "50vh"
                },
                minWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "white.main",
                borderRadius: 2

            }}>

                <Box component="img"
                    src={logoImg}
                    sx={{
                        width: {
                            xs: "200px",
                            md: "300px"
                        },
                        height: {
                            xs: "auto",
                            md:'auto'
                        },
                        objectFit: "cover",
                    }}
                />

                <CircularProgress />
                {/* <Typography sx={{
                    color: "primary.main",
                    mt: 2,
                    fontSize: {
                        xs: "1rem",
                        sm: "1.1rem",
                        lg: "1.2rem"
                    }
                }}>
                    Please Wait....
                </Typography> */}
            </Box>
        </Container>
    )
}

export default PageLoading