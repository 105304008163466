import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import "./styles/navbarmenu.css";
import "slick-carousel/slick/slick.css";

import { useSelector } from "react-redux";
import {
    Box, Divider, List, ListItem, Typography, Grid, ListItemButton, Container,
    Button,
    useMediaQuery,
} from "@mui/material";
import Slider from "react-slick";
import { NavLink, useNavigate } from "react-router-dom";
import { titleToUrl } from "../../shared/extra";

const NavbarMenu = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [categoryMenu, setCategoryMenu] = useState([]);
    const [categoryMenuBrands, setCategoryMenuBrands] = useState();
    const [currentCategory, setCurrentCategory] = useState("");
    const [navbarCss, setNavbarCss] = useState({})
    const navigate = useNavigate();
    const allProduct = useSelector((state) => state.AllProducts?.allData);

    const smDevice = useMediaQuery("(max-width:900px)");
    const mdDevice = useMediaQuery("(max-width:1200px)");
    const lgDevice = useMediaQuery("(max-width:1535px)");

    const showNavMenu = () => {
        if (smDevice) {
            return 3;
        } else if (mdDevice) {
            return 5;
        } else if (lgDevice) {
            return 9;
        } else {
            return 13;
        }
    }

    const filterCategoryMenuData = (allProducts) => {
        const finalResult = {};

        allProducts.forEach((product) => {
            const categoryTitle = product?.category?.title;
            const productTitle = product?.title;
            const productId = product?.id;
            if (categoryTitle) {
                if (finalResult?.hasOwnProperty(categoryTitle)) {
                    finalResult[categoryTitle].push([productTitle, productId]);
                } else {
                    finalResult[categoryTitle] = [[productTitle, productId]];
                }
            }
        });
        const categoryMenu = Object.entries(finalResult).map(([category, products]) => ({
            category, products,
        }));
        setCategoryMenu(categoryMenu);
    };

    useEffect(() => {
        const navbar = {
            navbarBackground: "dark.main",
            navbarTextColor: "white.main",
            homeIconColor: "white.main",
            homeIconBackgroundColor: "secondary.main",
            allGiftCardTextColor: "white.main",
            allGiftCardBackgroundColor: "#00b8ee"
        }
        setNavbarCss(navbar);

        if (allProduct) {
            filterCategoryMenuData(allProduct);
        }
    }, [allProduct]);

    const settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: showNavMenu(),
        slidesToScroll: 1,
        variableWidth: true
    };

    const handleNavbarMenu = (currentProduct, isHovering) => {
        setShowMenu(isHovering);
        setCurrentCategory(currentProduct);
        if (categoryMenu) {
            const menuData = categoryMenu.filter((item) => item.category === currentProduct);
            setCategoryMenuBrands(menuData[0]?.products);
        }
    };

    const handleMouseLeave = () => {
        setShowMenu(false);
    };

    return (
        <Container
            maxWidth="false"
            onMouseLeave={handleMouseLeave}
            sx={{
                px: { sm: 0 },
                maxHeight: "43px",
                backgroundColor: navbarCss?.navbarBackground,
                borderColor: "primary.main",
            }}
        >
            <Grid
                sx={{ display: "flex", alignItems: "center", position: "relative" }}
            >
                {/* Home Icon */}
                <List sx={{ py: 0, position: "relative", display: "inline-block" }}>
                    <ListItemButton onClick={() => navigate('/', { replace: true })}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "content",
                            width: "43px",
                            height: "40px",
                            // backgroundColor: "#9ccddb",
                            backgroundColor: navbarCss?.homeIconBackgroundColor,
                            p: 0,
                            color: navbarCss?.homeIconColor,
                            "&:hover": {
                                backgroundColor: navbarCss?.homeIconBackgroundColor,
                            }
                        }}>
                        <HomeIcon
                            style={{
                                width: "43px",
                                display: "block",
                                fontSize: "18px",
                                textAlign: "center",
                                lineHeight: 1,
                                p: "15px 14px",
                                textDecoration: "none",
                            }}
                        />
                    </ListItemButton>
                </List>


                {/* Category Names */}
                <List
                    onMouseLeave={handleMouseLeave}
                    className="slider-container navbarMenuSliderIcons"
                    sx={{
                        p: 0,
                        m: 0,
                        listStyle: "none",
                        fontSize: 0,
                        textAlign: "left",
                        position: "relative",
                        whiteSpace: "nowrap",
                        width: "calc(100% - 170px)",
                        height: "40px",
                        overflow: "hidden",
                        padding: "0 30px"
                    }}
                >
                    <Slider {...settings} >
                        {categoryMenu?.map((item, index) => {
                            return (
                                <React.Fragment key={`${item?.category}-${index}`}>
                                    <ListItemButton
                                        onMouseEnter={() => handleNavbarMenu(item?.category, true)}
                                        sx={{
                                            height: "40px",
                                            position: "relative",
                                            display: "inline-flex",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            verticalAlign: "top",
                                            borderBottom: "2px solid transparent",
                                            backgroundColor: currentCategory === item?.category && showMenu ? "secondary.main" : '',
                                            "&: hover": {
                                                backgroundColor: "secondary.main"
                                            }
                                        }}
                                    >
                                        <Typography variant="subtitle1" color={navbarCss?.navbarTextColor}
                                            sx={{
                                                fontSize: {
                                                    sm: "11px",
                                                    lg: "12.5px"
                                                },
                                            }}>
                                            {item?.category}
                                        </Typography>
                                    </ListItemButton>
                                </React.Fragment>
                            );
                        })}
                    </Slider>
                </List>
                <Divider orientation="vertical" flexItem />
                <List sx={{
                    py: 0,
                    position: "relative",
                    display: "block",
                    width: "100%",
                    maxWidth: "130px",
                    backgroundColor: "transparent",
                }}>

                    <ListItem
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "100%",
                            height: "40px",
                            backgroundColor: navbarCss?.allGiftCardBackgroundColor,
                            px: 0,
                        }}
                    >
                        <NavLink to={'/card-list'} >
                            <Button
                                variant="text"
                                sx={{
                                    color: navbarCss?.allGiftCardTextColor,
                                    fontSize: "11.5px !important",
                                    fontWeight: 400,
                                    "&:hover": {
                                        textDecoration: "underline"
                                    }
                                }}>
                                All E-Gift Cards
                            </Button>
                        </NavLink>

                    </ListItem>

                </List>
            </Grid >

            <Box sx={{
                position: "relative",
            }}>

                {/* All Products show According Menu */}
                <InnerMenu
                    showMenu={showMenu}
                    currentCategory={currentCategory}
                    categoryMenuBrands={categoryMenuBrands}
                    handleMouseLeave={handleMouseLeave}
                    setShowMenu={setShowMenu}
                />

            </Box>
        </Container >
    );
};

NavbarMenu.propTypes = {};

export default NavbarMenu;

function InnerMenu({ showMenu, currentCategory, categoryMenuBrands, handleMouseLeave, setShowMenu }) {
    return (
        <Box
            onMouseLeave={handleMouseLeave}
            onMouseEnter={() => setShowMenu(true)}
            sx={{
                position: "absolute",
                left: {
                    sm: "initial",
                    lg: "43px",
                },
                maxHeight: "431px",
                width: "100%",
                maxWidth: {
                    sm: "100%",
                    lg: "calc(100% - 192px) !important"
                },
                zIndex: 11,
                background: "#fff",
                display: showMenu ? "block" : "none",
                borderRadius: { xs: "0 0 24px 24px" },
                boxShadow: { sm: 4 }
            }}
        >
            <List>
                <ListItem>
                    <Typography
                        variant="body2"
                        color="dark.main"
                        sx={{
                            fontSize: { sm: "14.5px", md: "14.5px" },
                            fontWeight: 500, pb: ".05rem",
                        }}
                    >
                        {currentCategory}
                    </Typography>
                </ListItem>
                <Divider />
            </List>
            <List
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    maxHeight: "380px",
                    zIndex: 11,
                    overflow: "auto",
                    scrollbarWidth: "thin",
                }}
            >
                {categoryMenuBrands?.map((productItem) => {
                    return (
                        <NavLink key={productItem[1]}
                            to={`card-details/${productItem[1]}/${titleToUrl(productItem[0])}`}
                            onClick={() => setShowMenu(false)}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                textDecoration: "none",
                            }}>
                            <ListItem
                                sx={{
                                    p: { sm: "8px 12px", md: "12px 15px" },
                                    m: "0 0 2px 0",
                                    "&:hover": {
                                        backgroundColor: "#e9ecef", cursor: "pointer",
                                    }, "&:active": {
                                        color: "primary.main", cursor: "pointer",
                                    },
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: { sm: "11.5px", md: "13.5px" },
                                        lineHeight: 1,
                                        fontWeight: 400,
                                        color: "dark.main",
                                    }}
                                >
                                    {productItem[0]}
                                </Typography>
                            </ListItem>
                        </NavLink>
                    );
                })}
            </List>
        </Box>
    );
}
