// import { getProductDetails } from "../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";


// export function onFetchProductDetails(productId) {
//     return async (dispatch) => {
//         const productApiResponse = await getProductDetails(productId);
//         try {
//             if (productApiResponse['status'] && productApiResponse['statusCode']) {
//                 dispatch(setProductDetails(productApiResponse?.data));
//             }
//         } catch (error) {
//             console.error(productApiResponse?.message);
//         }
//     };
// }


export const setProductDetails = (payload) => {
    return {
        type: actionTypes.SET_CURRENT_PRODUCT_DETAIL,
        payload
    };
}

export const wipeOutProductDetails = () => {
    return {
        type: actionTypes.REMOVE_CURRENT_PRODUCT_DETAIL,
    };
}