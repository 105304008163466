
import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSelector } from 'react-redux';
import { categoryUrl } from '../../../shared/extra';
import { useLocation, useNavigate } from 'react-router-dom';

function MobileProductCategoryTabs({ currentProductCategory }) {
    const [value, setValue] = useState(0);
    const coreCategoryData = useSelector((state) => state.Category.allData);
    const categoryData = coreCategoryData?.filter(item => item?.products?.length > 0);
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const categoryParam = searchParams.get('category');

    useEffect(() => {
        if (categoryParam) {
            const selectedCategoryIndex = categoryData.findIndex(
                (item) => categoryUrl(item.title) === categoryParam
            );
            if (selectedCategoryIndex !== -1 && selectedCategoryIndex !== value) {
                setValue(selectedCategoryIndex);
            }
        }
    }, [categoryParam, categoryData, value]);

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
        const selectedCategory = categoryData[newValue]?.title;
        const categorySlug = categoryUrl(selectedCategory);

        if (categorySlug !== categoryParam) {
            navigate(`/card-list?category=${categorySlug}`);
        }
    }, [categoryData, categoryParam, navigate]);

    return (
        <Box
            sx={{
                flexGrow: 1,
                maxWidth: "false",
                bgcolor: 'background.paper',
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="visible arrows tabs example"
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                {categoryData?.map((item) => {
                    if (item?.products?.length > 0) {
                        return (
                            <Tab
                                key={`${item?.title}-category`}
                                label={`${item?.title}`}
                                sx={{
                                    color: "primary.main",
                                    textTransform: "capitalize",
                                    fontSize: "13.5px",
                                    fontWeight: 400,
                                }}
                            />
                        );
                    } else {
                        return null
                    }
                })}
            </Tabs>
        </Box>
    );
}

export default MobileProductCategoryTabs;