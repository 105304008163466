import { getSliderImages } from "../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";

export function onFetchSliders() {
  return async (dispatch) => {
    const sliderResponse = await getSliderImages();

    if (sliderResponse?.status) {
      dispatch(updateAllProductsReducer(sliderResponse?.data));
    } else {
      console.error(sliderResponse?.error);
    }
  };
}

export const updateAllProductsReducer = (payload) => {
  return {
    type: actionTypes.UPDATE_SLIDER_PRODUCTS,
    payload
  };
};
