import React, { useEffect } from "react";
import Logo from "../../assets/img/profile.png";
import {
    Box,
    Avatar,
    Menu,
    Typography,
    Divider,
    Stack,
    Tooltip,
    Skeleton,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, useNavigate } from "react-router-dom";
import { userLogout } from "../../apiCalls/functions";
import { useAuth } from "../../hooks/contexts/AuthContext";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../store/userProfile/action";
import { nameShortener } from "../../shared/extra";

const adminData = [
    {
        title: 'Statement',
        path: '/profile/statement',
    },
    {
        title: 'Orders',
        path: '/profile/order',
    },
    {
        title: 'Analytics',
        path: '/profile/analytics',
    },
    {
        title: 'User Profile',
        path: '/profile/user-profile',
    },
    {
        title: 'Notifications',
        path: '/profile/notifications',
    }
];

const ProfileNavbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.UserProfile.allData);

    const {
        setAuthUser,
        setIsLoggedIn
    } = useAuth();

    useEffect(() => {
        if (!userProfile?.status) {
            dispatch(fetchUserProfile());
        }
    }, [dispatch, userProfile])

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleUserLogout = (e) => {
        e.preventDefault();
        userLogout();
        setAuthUser(null);
        setIsLoggedIn(false);
        navigate("/", { replace: true });
    };


    return (
        <Box>
            <Stack sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <Tooltip title={userProfile?.data?.name}>
                    <Avatar alt="Profile Logo" src={Logo} sx={{
                        width: 32,
                        height: 32,
                    }} />
                </Tooltip>

                <Tooltip title={userProfile?.data?.name}>
                    <Stack sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "&:hover": {
                            fontWeight: 400,
                            textDecoration: "underline",
                            cursor: "pointer",
                        }
                    }}
                        onClick={handleOpenUserMenu}
                    >
                        <Typography
                            sx={{
                                color: "primary.main",
                                display: {
                                    sm: "none",
                                    lg: "block"
                                },
                                ml: 1,
                                minWidth: "fit-content",
                                "&:hover": {
                                    fontWeight: 400,
                                    textDecoration: "inherit",
                                    cursor: "pointer",
                                }
                            }}>
                            {userProfile?.data?.name ? nameShortener(`${userProfile?.data?.name}`) : <Skeleton sx={{ minWidth: "60px" }} />}
                        </Typography>
                        <ArrowDropDownIcon color="primary" />
                    </Stack>
                </Tooltip>
            </Stack>


            <Menu
                sx={{
                    mt: "30px",
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {adminData?.map((item, idx) => (
                    <NavLink key={`header-admin-menu-${idx * 1}`} to={item?.path} style={{ textDecoration: "none" }}>
                        <MenuItem onClick={handleCloseUserMenu}>
                            <Typography sx={{
                                fontSize: {
                                    sm: "12px",
                                    md: "13px",
                                    lg: "13px"
                                },
                                textAlign: "center",
                                color: "primary.main",
                                fontWeight: 400,
                                "&:hover": {
                                    textDecoration: "underline"
                                }
                            }}>
                                {item?.title}
                            </Typography>
                        </MenuItem>
                    </NavLink>
                ))}

                <Divider />
                <MenuItem onClick={handleUserLogout} sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <Typography sx={{
                        fontSize: "10px",
                        color: "textPrimary.main",
                        fontWeight: 600,
                        textAlign: "center",
                        "&:hover": {
                            textDecoration: "underline"
                        }
                    }}>
                        Logout
                    </Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default ProfileNavbar;
