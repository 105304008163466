import { getAllProducts } from "../../apiCalls/urls/urlFunction";
import * as actionTypes from "./actionTypes";

export function onFetchEntertainmentProducts() {
  return async (dispatch) => {
    const entertainmentResponse = await getAllProducts({
      search: '',
      filterBy: "title",
      selectedCategories: [6],
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 20,
        rowsNumber: 0,
      },
    });

    if (entertainmentResponse?.status) {
      dispatch(updateAllProductsReducer(entertainmentResponse?.data));
    } else {
      console.error(entertainmentResponse?.error);
    }
  };
}

export const updateAllProductsReducer = (payload) => {
  return {
    type: actionTypes.UPDATE_ENTERTAINMENT_PRODUCTS,
    payload
  };
};
