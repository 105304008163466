import React, { useEffect } from 'react'
import Layout from '../../layouts';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   if (location.pathname === '/login' || location.pathname === '/signup') {
  //     window.reload();
  //   }
  // })

  return (
    <Container maxWidth="false" sx={{
      backgroundColor: "backgroundPrimary.main"
    }}>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: {
          xs: "180px",
          sm: "200px",
          lg: "300px",
        }
      }}>
        <Typography sx={{
          fontSize: {
            xs: "1.9rem",
            sm: "2.5rem",
            lg: "5.5rem"
          },
          color: {
            lg: "primary.main"
          }
        }}>404</Typography>

        <Typography sx={{
          fontSize: {
            xs: "1.9rem",
            sm: "2.5rem",
            lg: "3.5rem",
          },
          color: {
            lg: "textPrimary.main"
          }
        }}>
          Page Not Found
        </Typography>


        <Button variant="contained" onClick={() => navigate('/')}>Go Home</Button>

      </Box>
    </Container>
  )
}

export default PageNotFound;
