import React from 'react';
import { Outlet, useState, useEffect, useLocation } from "../../imports/generalImport";
import { Box, Container } from "../../imports/muiImport";
import MobileFooterNavigation from '../mobileComponents/footerNavigation/MobileFooterNavigation';
import MobileHeaderDrawer from '../mobileComponents/headerNavigation/MobileHeaderDrawer';
import ScrollToTop from '../../components/other/ScrollToTop';
import { Helmet } from 'react-helmet-async';
const constantUserNames = ['/', '/contactus', '/card-list', '/profile/order', '/profile'];

function MobileDesignLayout({ children }) {
  const [isPageTitleUpdate, setIsPageTitleUpdate] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();
  const canonicalUrl = `https://www.99gift.in${location.pathname}${location?.search && location?.search}`;

  useEffect(() => {
    if (constantUserNames.includes(location?.pathname) === false) {
      updatePageTitle(location?.pathname);
    } else {
      setIsPageTitleUpdate(false);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <Container
        maxWidth="false"
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          overflowX: "hidden",
        }}
      >
        <ScrollToTop />

        <Box sx={{
          height: 56,
          mt: 4,
        }}>
          <MobileHeaderDrawer isPageTitleUpdate={isPageTitleUpdate} pageTitle={pageTitle} />
        </Box>

        <Box sx={{
          overflow: "auto",
          flex: 1,
          mb: 1,
        }}>
          <Outlet>
            {children}
          </Outlet>
        </Box>


        {!isPageTitleUpdate &&
          (
            <Box sx={{
              height: 56,
              opacity: 99999,
            }}>
              <MobileFooterNavigation />
            </Box>
          )
        }
      </Container>
    </>
  );


  function updatePageTitle(pageTitle) {
    const pages = pageTitle.split('/').pop();
    const modified = pages.split('-').join(' ');
    const titleName = modified.charAt(0).toUpperCase() + modified.slice(1);
    setPageTitle(titleName);
    setIsPageTitleUpdate(true);
  }

}

export default MobileDesignLayout;