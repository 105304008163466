import { getBlogs } from "../../apiCalls/urls/urlFunction";
import { UPDATE_BLOGS } from "./actionTypes";

export function onFetchBlogs() {
  return async (dispatch) => {
    const blogs = await getBlogs();
    if (blogs?.status) {
      dispatch(updateBlogsReducer(blogs?.data));
    } else {
      console.error(blogs?.error);
    }
  };
}

export const updateBlogsReducer = (payload) => {
  return {
    type: UPDATE_BLOGS,
    payload
  };
};
