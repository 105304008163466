import React from 'react';
import { Box, Button } from "../../../imports/muiImport";
import { useState, useNavigate } from "../../../imports/generalImport";
import { userLogout } from '../../../apiCalls/functions';
import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../../hooks/contexts/AuthContext';

function MobileLogoutButton({ py = 2.5 }) {
    const [logoutLoading, setLogoutLoading] = useState(false);
    const { isLoggedIn, setAuthUser, setIsLoggedIn } = useAuth();
    const navigate = useNavigate();

    const handleUserLogout = () => {
        setLogoutLoading(true);
        userLogout(300); // Custom logout function to clear session
        setAuthUser(null); // Clear the auth user state
        setIsLoggedIn(false); // Update login status
        setLogoutLoading(false);
        navigate("/", { replace: true });
    };


    if (isLoggedIn) {
        return (
            <Box sx={{ width: "100%", border: "1px dashed #d22018", borderRadius: 2 }}>
                <Button
                    type='submit'
                    variant='contained'
                    fullWidth
                    onClick={handleUserLogout}
                    size="large"
                    startIcon={<LogoutIcon />}
                    sx={{
                        py: py,
                        borderRadius: 2,
                        color: "#fb0000cf",
                        fontWeight: 600,
                        fontSize: "3.4vw",
                        backgroundColor: "#ff000045",
                        textTransform: "capitalize",
                        "&:hover": {
                            color: "#fb0000cf",
                            backgroundColor: "#ff000045",
                        }
                    }}
                >
                    {logoutLoading ?
                        (<CircularProgress
                            sx={{ color: "#fb0000cf" }}
                            size={22}
                        />)
                        :
                        ("Logout")
                    }
                </Button>
            </Box>
        )
    }

    return null;

}

export default MobileLogoutButton