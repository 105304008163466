import React, { useState } from 'react';
import { Box, TextField, Button, Typography, IconButton, InputAdornment, FormControl, Backdrop } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockResetIcon from '@mui/icons-material/LockReset';
import { forgotUserPassword } from '../../../apiCalls/urls/urlFunction';
import { actionNotifier } from '../../../components/tostify';
import CircularProgress from '@mui/material/CircularProgress';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function MobileNewPassword({ forgotToken }) {
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleMouseDownPassword = (event) => event.preventDefault();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: '#e5f5ff',
                padding: 2,
            }}
        >
            {/* Reset Password Title */}
            <Typography variant="h5" fontWeight="bold" gutterBottom>
                Reset Password
            </Typography>

            {/* Subtitle */}
            <Typography variant="body2" color="textSecondary" gutterBottom textAlign={'center'}>
                Your new password must be different from previous used passwords.
            </Typography>

            {/* Icon */}
            <LockResetIcon sx={{ fontSize: 100, color: '#00b0ff', my: 2 }} />

            <FormControl
                component={'form'}
                method='POST'
                onSubmit={handleSubmit}
                fullWidth
            >
                {/* New Password Input */}
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    label="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                    sx={{
                        marginBottom: 2,
                        backgroundColor: 'white',
                        borderRadius: '10px',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {/* Confirm Password Input */}
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    sx={{
                        marginBottom: 3,
                        backgroundColor: 'white',
                        borderRadius: '10px',
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {/* Submit Button */}
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                        paddingY: 2,
                        backgroundColor: '#00b0ff',
                        color: 'white',
                        textTransform: 'capitalize',
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: '#009edb',
                        },
                    }}
                >
                    Update Password
                </Button>
            </FormControl>


            <Backdrop
                sx={{ color: '#fff', zIndex: 999 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    );

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);

        // Validate inputs
        let validationErrors = {};

        if (newPassword.length < 1) {
            validationErrors.newPassword = 'New Password is Required field.';
        } else {
            if (newPassword.length < 6 || newPassword.length > 15) {
                validationErrors.newPassword = 'Password must be between 6 and 15 characters.';
            }
        }

        if (confirmPassword.length < 1) {
            validationErrors.confirmPassword = 'Confirm Password is Required field.';
        } else {
            if (confirmPassword.length < 6 || confirmPassword.length > 15) {
                validationErrors.confirmPassword = 'Confirm Password must be between 6 and 15 characters.';
            } else {
                if (newPassword !== confirmPassword) {
                    validationErrors.confirmPassword = 'Passwords do not match.';
                }
            }
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setLoading(false);
            return;
        }

        // Clear errors if validation passed
        setErrors({});

        const payload = {
            password: newPassword,
            confirmPassword: confirmPassword,
            forgotToken: forgotToken?.token
        };

        updateUserPassword(payload);
    }

    async function updateUserPassword(payload) {
        if (!loading) setLoading(true)
        const res = await forgotUserPassword(payload);

        try {

            if (res.status) {
                // Show success message or redirect
                actionNotifier.success('Password update successfully!', { position: "top-left", autoClose: 1000 });
                setNewPassword('');
                setConfirmPassword('');
                navigate('/login', { replace: true });
                setLoading(false);
            } else {
                // Show error message
                actionNotifier.error('Something went wrong. Please try again later.', { position: "top-left", autoClose: 1000 });
                setNewPassword('');
                setConfirmPassword('');
                setLoading(false);
            }
        } catch (error) {
            actionNotifier.error('Something went wrong. Please try again later.', { position: "top-left", autoClose: 1000 });
            setNewPassword('');
            setConfirmPassword('');
            setLoading(false);
        }
    }
}

export default MobileNewPassword;